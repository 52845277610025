import { Device, TEMPLATE_TYPE } from "@models";
import {
  hasElectrical,
  hasFirmware,
  hasHardware,
  hasMechanical,
  hasSoftware,
} from "@utils";
import { ROADMAP_CARD_ID } from ".";
import { DataKeyWithValue } from "../../types";

export type RoadmapTaskId =
  | TEMPLATE_TYPE
  | "intended-use"
  | "basic-characteristics-technical-file-filled"
  | "sop-software-problem-resolution"
  | "sop-integrated-software-development"
  | "software-processes-technical-file-filled"
  | "mdr-device-classification"
  | "device-classification-technical-file-filled"
  | "list-of-hazard-related-use-scenarios"
  | "establish-risk-management-system-technical-file-filled"
  | "user-needs-list"
  | "user-needs-technical-file-filled"
  | "software-system-test-plan"
  | "software-requirements-list"
  | "sop-deployment"
  | "software-development-and-maintenance-plan"
  | "software-design-and-development-technical-file-filled"
  | "quality-manual-policy-objectives"
  | "sop-document-record-control"
  | "sop-human-resources-administration"
  | "establish-quality-management-system-technical-file-filled"
  | "sop-purchasing"
  | "sop-sales"
  | "purchasing-sales-and-suppliers-technical-file-filled"
  | "software-architecture-checklist"
  | "software-architecture"
  | "software-requirements-review-checklist"
  | "supplier-checklist"
  | "soup-list"
  | "usability-test-plan"
  | "usability-testing-protocol"
  | "perform-usability-test"
  | "usability-testing-report"
  | "usability-and-human-factors-engineering-technical-file-filled"
  | "clinical-investigations-consult-with-formlyai"
  | "algorithm-validation-report"
  | "bug-fixes-documentation-list"
  | "software-verification-and-validation-technical-file-filled"
  | "sop-feedback-management"
  | "sop-vigilance"
  | "sop-capa"
  | "incident-assessment-form"
  | "field-safety-notice"
  | "list-capa"
  | "complaints-and-feedback-technical-file-filled"
  | "list-of-training-documentation"
  | "training-record"
  | "risk-management-report"
  | "list-of-known-anomalies"
  | "checklist-software-release"
  | "change-evaluation-list"
  | "instructions-for-use"
  | "sop-post-market-surveillance"
  | "post-market-surveillance-plan"
  | "post-market-clinical-follow-up-plan"
  | "periodic-safety-update-report"
  | "post-market-clinical-follow-up-report"
  | "post-market-surveillance-technical-file-filled"
  | "sop-product-registration-and-certification"
  | "udi-creation"
  | "eudamed-registration"
  | "eudamed-registration-technical-file-filled"
  | "technical-file-generated"
  | "checklist-mdr"
  | "general-safety-and-performance-requirements-technical-file-filled"
  | "sop-clinical-evaluation"
  | "clinical-evaluation-plan"
  | "clinical-evaluation-report"
  | "checklist-literature-evaluation"
  | "clinical-evaluation-technical-file-filled"
  | "sop-management-review"
  | "list-of-regulatory-requirements"
  | "sop-update-of-regulations"
  | "sop-internal-audit"
  | "audit-plan"
  | "audit-program"
  | "management-review-report"
  | "list-of-medical-devices"
  | "sop-change-management";

export type RoadmapTaskConfig = {
  title: string;
  link?: string;
  id: RoadmapTaskId;
  card: ROADMAP_CARD_ID;
  enabled?: boolean;
  visibilityCondition?: {
    isVisible: (
      availableDataKeys: DataKeyWithValue[],
      device: Device
    ) => boolean;
  };
};

export const ROADMAP_TASKS: RoadmapTaskConfig[] = [
  {
    title: "Complete the SOP Management Review.",
    id: TEMPLATE_TYPE.SOP_MANAGEMENT_REVIEW,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Complete the Quality Manual.",
    id: TEMPLATE_TYPE.QUALITY_MANUAL_POLICY_OBJECTIVES,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Complete the SOP Document and Record Control.",
    id: TEMPLATE_TYPE.SOP_DOCUMENT_RECORD_CONTROL,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Complete the SOP Design Control.",
    id: TEMPLATE_TYPE.SOP_DESIGN_CONTROL,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasHardware(device);
      },
    },
  },
  {
    title: "Review the SOP Human Resources and Training.",
    id: TEMPLATE_TYPE.SOP_HUMAN_RESOURCES_ADMINISTRATION,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Complete the Intended Use.",
    id: TEMPLATE_TYPE.INTENDED_USE,
    card: ROADMAP_CARD_ID.DEVICE_BASIC_CHARACTERISTICS,
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "basic-characteristics-technical-file-filled",
  //   card: "device-basic-characteristics",
  //   link: ROUTES.DEVICE_DESCRIPTION_ONE,
  // },
  {
    title: "Complete the SOP Software Problem Resolution.",
    id: TEMPLATE_TYPE.SOP_SOFTWARE_PROBLEM_RESOLUTION,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasSoftware(device);
      },
    },
  },
  {
    title: "Complete the SOP Software Development.",
    id: TEMPLATE_TYPE.SOP_INTEGRATED_SOFTWARE_DEVELOPMENT,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasSoftware(device);
      },
    },
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "software-processes-technical-file-filled",
  //   card: "software-processes",
  //   link: ROUTES.SOFTWARE,
  // },
  {
    title: "Complete the MDR Device Classification.",
    id: "mdr-device-classification",
    card: ROADMAP_CARD_ID.DEVICE_BASIC_CHARACTERISTICS,
  },
  // {
  //   title:
  //     "Complete the corresponding sections in the technical file and justification based on criteria in the guide",
  //   id: "device-classification-technical-file-filled",
  //   card: "device-classification",
  //   link: ROUTES.DEVICE_DESCRIPTION_TWO,
  // },
  {
    title: "Complete the Risk Management Plan.",
    id: TEMPLATE_TYPE.RISK_MANAGEMENT_PLAN,
    card: ROADMAP_CARD_ID.RISK_MANAGEMENT,
  },
  {
    title: "Complete the Risk Assessment.",
    id: TEMPLATE_TYPE.RISK_ASSESSMENT,
    card: ROADMAP_CARD_ID.RISK_MANAGEMENT,
  },
  {
    title: "Complete the SOP Risk Management.",
    id: TEMPLATE_TYPE.SOP_RISK_MANAGEMENT,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Review the SOP Nonconformance.",
    id: TEMPLATE_TYPE.SOP_NONCONFORMANCE,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Review the SOP Usability Engineering.",
    id: TEMPLATE_TYPE.SOP_USABILITY_ENGINEERING,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "establish-risk-management-system-technical-file-filled",
  //   card: "establish-risk-management-system",
  //   link: ROUTES.RISK_MANAGEMENT,
  // },
  {
    title: "Complete the User Needs List.",
    id: TEMPLATE_TYPE.USER_NEEDS_LIST,
    card: ROADMAP_CARD_ID.USER_NEEDS,
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "user-needs-technical-file-filled",
  //   card: "user-needs",
  // },
  {
    title: "Complete the System Requirements List.",
    id: TEMPLATE_TYPE.SYSTEM_REQUIREMENTS_LIST,
    card: ROADMAP_CARD_ID.PRODUCT_REQUIREMENTS,
  },
  {
    title: "Complete the Subsystem Requirements List.",
    id: TEMPLATE_TYPE.SUBSYSTEM_REQUIREMENTS_LIST,
    card: ROADMAP_CARD_ID.PRODUCT_REQUIREMENTS,
  },
  {
    title: "Complete the Software System Test Plan.",
    id: "software-system-test-plan",
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_PLANNING,
  },
  {
    id: "software-architecture",
    title: "Complete the Software Architecture.",
    card: ROADMAP_CARD_ID.DESIGN,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasSoftware(device);
      },
    },
  },
  {
    title: "Complete the SOP Deployment.",
    id: TEMPLATE_TYPE.SOP_DEPLOYMENT,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Complete the Deployment Evaluation Checklist.",
    id: TEMPLATE_TYPE.DEPLOYMENT_EVALUATION_CHECKLIST,
    card: ROADMAP_CARD_ID.DEPLOYMENT,
  },
  {
    title: "Complete the SOP Computer System Validation.",
    id: TEMPLATE_TYPE.SOP_SOFTWARE_VALIDATION,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Complete the List of Validated Software.",
    id: TEMPLATE_TYPE.LIST_OF_VALIDATED_SOFTWARE,
    card: ROADMAP_CARD_ID.COMPUTER_SYSTEM_VALIDATION,
  },
  {
    title:
      "Use the Software Validation Form to check if you need to validate any additional software, if yes complete the document and copy it to your document storage. If not, you can check the task. Use a copy of the final document as template for any additional software that needs validation according to SOP Computer System Validation.",
    id: TEMPLATE_TYPE.SOFTWARE_VALIDATION_FORM,
    card: ROADMAP_CARD_ID.COMPUTER_SYSTEM_VALIDATION,
  },
  {
    title: "Complete the SOP Cybersecurity.",
    id: TEMPLATE_TYPE.SOP_CYBERSECURITY,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Complete the SOP Statistical Methods.",
    id: TEMPLATE_TYPE.SOP_STATISTICAL_METHODS,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Complete the SOP Manufacturing.",
    id: TEMPLATE_TYPE.SOP_MANUFACTURING,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasHardware(device);
      },
    },
  },
  {
    title: "Complete the Software Development and Maintenance Plan.",
    id: "software-development-and-maintenance-plan",
    card: ROADMAP_CARD_ID.DESIGN_AND_DEVELOPMENT_PLANS,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasSoftware(device);
      },
    },
  },
  {
    title: "Complete the Hardware Design and Development Plan.",
    id: TEMPLATE_TYPE.HARDWARE_DESIGN_AND_DEVELOPMENT_PLAN,
    enabled: false,
    card: ROADMAP_CARD_ID.DESIGN_AND_DEVELOPMENT_PLANS,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasHardware(device);
      },
    },
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "software-design-and-development-technical-file-filled",
  //   card: "software-design-and-development",
  //   link: ROUTES.SOFTWARE,
  // },
  // {
  //   title: "Add a simple QMS summary to Technical File",
  //   id: "establish-quality-management-system-technical-file-filled",
  //   card: "establish-quality-management-system",
  //   link: ROUTES.DEVICE_DESCRIPTION_ONE,
  // },
  {
    title: "Complete the SOP Purchasing.",
    id: "sop-purchasing",
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Complete the SOP Sales.",
    id: "sop-sales",
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Complete the Approved Supplier List with all of your suppliers.",
    id: TEMPLATE_TYPE.LIST_OF_QUALIFIED_SUPPLIERS,
    card: ROADMAP_CARD_ID.PURCHASING_SALES_AND_SUPPLIERS,
  },
  {
    title:
      "Complete the Supplier Checklist template for your current suppliers and create a new version each time a new supplier is onboarded.",
    id: "supplier-checklist",
    card: ROADMAP_CARD_ID.PURCHASING_SALES_AND_SUPPLIERS,
  },
  // {
  //   title: "Fill out any subcontractors or suppliers in the technical file",
  //   id: "purchasing-sales-and-suppliers-technical-file-filled",
  //   card: "purchasing-sales-and-suppliers",
  //   link: ROUTES.SUBCONTRACTORS,
  // },
  {
    title: "Complete the Software Architecture Checklist.",
    id: "software-architecture-checklist",
    card: ROADMAP_CARD_ID.DESIGN_STAGE_CHECKLISTS,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasSoftware(device);
      },
    },
  },
  {
    title: "Complete the Software Requirements Review Checklist.",
    id: TEMPLATE_TYPE.SOFTWARE_REQUIREMENTS_REVIEW_CHECKLIST,
    card: ROADMAP_CARD_ID.DESIGN_STAGE_CHECKLISTS,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasSoftware(device);
      },
    },
  },
  {
    title: "Complete the SOUP List.",
    id: TEMPLATE_TYPE.SOUP_LIST,
    card: ROADMAP_CARD_ID.DESIGN,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasSoftware(device);
      },
    },
  },
  {
    title: "Complete the User Needs Checklist.",
    id: TEMPLATE_TYPE.USER_NEEDS_CHECKLIST,
    card: ROADMAP_CARD_ID.DESIGN_STAGE_CHECKLISTS,
  },
  {
    title: "Complete the Usability Evaluation Plan.",
    id: TEMPLATE_TYPE.USABILITY_EVALUATION_PLAN,
    card: ROADMAP_CARD_ID.USABILITY_AND_HUMAN_FACTORS_ENGINEERING,
  },
  {
    title: "Complete the Usability Evaluation Protocol.",
    id: TEMPLATE_TYPE.USABILITY_EVALUATION_PROTOCOL,
    card: ROADMAP_CARD_ID.USABILITY_AND_HUMAN_FACTORS_ENGINEERING,
  },
  // {
  //   title: "Perform your usability test according to your protocol",
  //   id: "perform-usability-test",
  //   card: "usability-and-human-factors-engineering",
  // },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "usability-and-human-factors-engineering-technical-file-filled",
  //   card: "usability-and-human-factors-engineering",
  //   link: ROUTES.USABILITY,
  // },
  {
    title:
      "Consult with FormlyAI as to whether a clinical investigation is necessary for your device",
    id: "clinical-investigations-consult-with-formlyai",
    card: ROADMAP_CARD_ID.CLINICAL_INVESTIGATIONS,
  },
  {
    title: "Complete the Bug Fixes Report for any bugs you encounter.",
    id: TEMPLATE_TYPE.BUG_FIX_REPORT,
    card: ROADMAP_CARD_ID.PRODUCT_CHANGES,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasSoftware(device);
      },
    },
  },
  {
    title: "Complete the SOP Change Management.",
    id: TEMPLATE_TYPE.SOP_CHANGE_MANAGEMENT,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title:
      "Complete the Change Request for any changes you need to make to the released product.",
    id: TEMPLATE_TYPE.CHANGE_REQUEST,
    card: ROADMAP_CARD_ID.PRODUCT_CHANGES,
  },

  {
    title:
      "Complete the Change Evaluation Report. Create one for any changes you need to make to the released product.",
    id: TEMPLATE_TYPE.CHANGE_EVALUATION_REPORT,
    card: ROADMAP_CARD_ID.PRODUCT_CHANGES,
  },

  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "software-verification-and-validation-technical-file-filled",
  //   card: "software-verification-and-validation",
  //   link: ROUTES.SOFTWARE_THREE,
  // },
  {
    title: "Complete the SOP Feedback and Complaint Management.",
    id: TEMPLATE_TYPE.SOP_FEEDBACK_MANAGEMENT,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Complete the SOP Vigilance.",
    id: TEMPLATE_TYPE.SOP_VIGILANCE,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Complete the SOP Corrective and Preventive Action (CAPA).",
    id: TEMPLATE_TYPE.SOP_CAPA,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title:
      "Complete the Incident Assessment Form once you need to report any incidents.",
    id: TEMPLATE_TYPE.INCIDENT_ASSESSMENT_FORM,
    card: ROADMAP_CARD_ID.CAPAS_AND_VIGILANCE,
  },
  {
    title:
      "Complete the Field Safety Notice Form once you need to report a field safety notice.",
    id: TEMPLATE_TYPE.FIELD_SAFETY_NOTICE,
    card: ROADMAP_CARD_ID.CAPAS_AND_VIGILANCE,
  },
  {
    title: "Complete the CAPA Plan.",
    id: TEMPLATE_TYPE.CAPA_PLAN,
    card: ROADMAP_CARD_ID.CAPAS_AND_VIGILANCE,
  },
  {
    title: "Complete the CAPA Report.",
    id: TEMPLATE_TYPE.CAPA_REPORT,
    card: ROADMAP_CARD_ID.CAPAS_AND_VIGILANCE,
  },
  {
    title:
      "Complete the List of CAPAs and update with any CAPAs you encounter.",
    id: TEMPLATE_TYPE.LIST_CAPA,
    card: ROADMAP_CARD_ID.CAPAS_AND_VIGILANCE,
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "complaints-and-feedback-technical-file-filled",
  //   card: "complaints-and-feedback",
  //   link: ROUTES.MARKET_HISTORY,
  // },
  {
    title: "Complete the Complaint Log.",
    id: TEMPLATE_TYPE.COMPLAINT_LOG,
    card: ROADMAP_CARD_ID.COMPLAINTS,
  },
  {
    title:
      "Complete the List of Employee Training and update it with any training you do.",
    id: TEMPLATE_TYPE.LIST_OF_EMPLOYEE_TRAINING,
    card: ROADMAP_CARD_ID.TRAINING,
  },
  // {
  //   title:
  //     "Complete the Group Training Record and update it with any training you do.",
  //   id: TEMPLATE_TYPE.GROUP_TRAINING_RECORD,
  //   card: ROADMAP_CARD_ID.TRAINING,
  // },
  {
    title: "Complete the Required Documentation Training by Functional Group.",
    id: TEMPLATE_TYPE.REQUIRED_DOCUMENTATION_TRAINING_BY_FUNCTIONAL_GROUP,
    card: ROADMAP_CARD_ID.TRAINING,
  },
  {
    title: "Complete the Usability Evaluation Report.",
    id: TEMPLATE_TYPE.USABILITY_EVALUATION_REPORT,
    card: ROADMAP_CARD_ID.USABILITY_AND_HUMAN_FACTORS_ENGINEERING,
  },
  {
    title: "Complete the Software System Test Report.",
    id: TEMPLATE_TYPE.SOFTWARE_SYSTEM_TEST_REPORT,
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_REPORTS,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasSoftware(device);
      },
    },
  },
  {
    title: "Complete the Risk Management Report.",
    id: TEMPLATE_TYPE.RISK_MANAGEMENT_REPORT,
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_STAGE_CHECKLISTS,
  },
  {
    title: "Complete the List of Known Anomalies.",
    id: TEMPLATE_TYPE.LIST_OF_KNOWN_ANOMALIES,
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_STAGE_CHECKLISTS,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasSoftware(device);
      },
    },
  },
  {
    title: "Complete the Software Release Checklist.",
    id: TEMPLATE_TYPE.CHECKLIST_SOFTWARE_RELEASE,
    card: ROADMAP_CARD_ID.PRODUCT_RELEASE,
  },
  {
    title: "Complete the Release Notes.",
    id: TEMPLATE_TYPE.RELEASE_NOTES,
    card: ROADMAP_CARD_ID.PRODUCT_RELEASE,
  },
  {
    title:
      "Complete the Device Label and make it accessible for your user somewhere within your software.",
    id: TEMPLATE_TYPE.DEVICE_LABEL,
    card: ROADMAP_CARD_ID.PRODUCT_RELEASE,
  },
  {
    title: "Complete the Instructions for Use.",
    id: TEMPLATE_TYPE.INSTRUCTIONS_FOR_USE,
    card: ROADMAP_CARD_ID.LABELING_INSTRUCTIONS_FOR_USE_AND_MANUALS,
  },
  {
    title:
      "Upload the Instructions for Use translated into the language of the country you will first market your device.",
    id: TEMPLATE_TYPE.PRIMARY_LANGUAGE_TRANSLATED_IFU,
    card: ROADMAP_CARD_ID.LABELING_INSTRUCTIONS_FOR_USE_AND_MANUALS,
  },
  {
    title:
      "Upload the Instructions for Use translated into all other languages as one composite. You only need to translate the document for the countries languages that you plan to sell your device in.",
    id: TEMPLATE_TYPE.OTHER_LANGUAGES_TRANSLATED_IFUS,
    card: ROADMAP_CARD_ID.LABELING_INSTRUCTIONS_FOR_USE_AND_MANUALS,
  },
  {
    title: "Complete the Instructions for Use.",
    id: TEMPLATE_TYPE.INSTRUCTIONS_FOR_USE,
    card: ROADMAP_CARD_ID.LABELING_INSTRUCTIONS_FOR_USE_AND_MANUALS,
  },
  {
    title: "Complete the SOP Post-Market Surveillance.",
    id: TEMPLATE_TYPE.SOP_POST_MARKET_SURVEILLANCE,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Complete the Post-Market Surveillance Plan.",
    id: TEMPLATE_TYPE.POST_MARKET_SURVEILLANCE_PLAN,
    card: ROADMAP_CARD_ID.POST_MARKET_SURVEILLANCE,
  },
  {
    title:
      "Two years after certification, complete the Post-Market Surveillance Report.",
    id: TEMPLATE_TYPE.POST_MARKET_SURVEILLANCE_REPORT,
    card: ROADMAP_CARD_ID.SURVEILLANCE_REPORTS,

    // Only required for class I
    visibilityCondition: {
      isVisible: (availableDataKeys) => {
        return (
          availableDataKeys.find((key) => key.id === "device-classification")
            ?.value === "Class I"
        );
      },
    },
  },
  {
    title: "Complete the Post-Market Clinical Follow-Up Plan.",
    id: TEMPLATE_TYPE.POST_MARKET_CLINICAL_FOLLOW_UP_PLAN,
    card: ROADMAP_CARD_ID.POST_MARKET_SURVEILLANCE,
  },
  {
    title:
      "One year after certification, complete the Periodic Safety Update Report.",
    id: TEMPLATE_TYPE.PERIODIC_SAFETY_UPDATE_REPORT,
    card: ROADMAP_CARD_ID.SURVEILLANCE_REPORTS,

    // Only required for class IIa, IIb, III
    visibilityCondition: {
      isVisible: (availableDataKeys) => {
        const deviceClassification =
          availableDataKeys.find((key) => key.id === "device-classification")
            ?.value || "";

        return ["Class IIa", "Class IIb", "Class III"].includes(
          deviceClassification
        );
      },
    },
  },
  {
    title:
      "One year after certification, complete the Post-Market Clinical Follow-up Report.",
    id: TEMPLATE_TYPE.POST_MARKET_CLINICAL_FOLLOW_UP_REPORT,
    card: ROADMAP_CARD_ID.SURVEILLANCE_REPORTS,
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "post-market-surveillance-technical-file-filled",
  //   card: "post-market-surveillance",
  //   link: ROUTES.POST_MARKET,
  // },

  {
    title: "Complete the SOP Clinical Evaluation.",
    id: TEMPLATE_TYPE.SOP_CLINICAL_EVALUATION,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Complete the Clinical Evaluation Plan.",
    id: TEMPLATE_TYPE.CLINICAL_EVALUATION_PLAN,
    card: ROADMAP_CARD_ID.CLINICAL_EVALUATION,
  },
  {
    title: "Complete the Clinical Evaluation Report.",
    id: TEMPLATE_TYPE.CLINICAL_EVALUATION_REPORT,
    card: ROADMAP_CARD_ID.CLINICAL_EVALUATION,
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",

  //   id: "clinical-evaluation-technical-file-filled",
  //   card: "clinical-evaluation",
  //   link: ROUTES.CLINICAL_EVALUATION,
  // },

  {
    title:
      "Complete the General Safety and Performance Requirements Checklist.",
    id: TEMPLATE_TYPE.GSPR_CHECKLIST,
    card: ROADMAP_CARD_ID.GENERAL_SAFETY_AND_PERFORMANCE_REQUIREMENTS_CHECKLIST,
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "general-safety-and-performance-requirements-technical-file-filled",
  //   card: "general-safety-and-performance-requirements-checklist",

  //   link: ROUTES.GENERAL_SAFETY_AND_PERFORMANCE,
  // },

  {
    title: "Complete the List of Regulatory Requirements.",
    id: TEMPLATE_TYPE.LIST_OF_REGULATORY_REQUIREMENTS,
    card: ROADMAP_CARD_ID.AUDITS_AND_REVIEW,
  },
  {
    title: "Complete the SOP Regulatory Strategy.",
    id: TEMPLATE_TYPE.SOP_UPDATE_OF_REGULATIONS,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Complete the SOP Internal Audit.",
    id: TEMPLATE_TYPE.SOP_INTERNAL_AUDIT,
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Complete the Audit Plan.",
    id: TEMPLATE_TYPE.AUDIT_PLAN,
    card: ROADMAP_CARD_ID.AUDITS_AND_REVIEW,
  },
  {
    title: "Complete the Audit Program.",
    id: TEMPLATE_TYPE.AUDIT_PROGRAM,
    card: ROADMAP_CARD_ID.AUDITS_AND_REVIEW,
  },
  {
    title: "Complete the Audit Report.",
    id: TEMPLATE_TYPE.AUDIT_REPORT,
    card: ROADMAP_CARD_ID.AUDITS_AND_REVIEW,
  },
  {
    title: "Complete the Management Review Report.",
    id: TEMPLATE_TYPE.MANAGEMENT_REVIEW_REPORT,
    card: ROADMAP_CARD_ID.AUDITS_AND_REVIEW,
  },
  {
    title: "Complete the List of Medical Devices.",
    id: TEMPLATE_TYPE.LIST_OF_MEDICAL_DEVICES,
    card: ROADMAP_CARD_ID.PRODUCT_RELEASE,
  },

  {
    title: "Complete the EU Declaration of Conformity.",
    id: TEMPLATE_TYPE.DECLARATION_OF_CONFORMITY,
    card: ROADMAP_CARD_ID.DECLARATION_OF_CONFORMITY,
  },
  {
    title: "Complete the SOP Product Registration and Certification.",
    id: "sop-product-registration-and-certification",
    card: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  },
  {
    title: "Follow the steps in the UDI Creation guide.",
    id: TEMPLATE_TYPE.UDI_CREATION,
    card: ROADMAP_CARD_ID.UDI_CREATION_AND_EUDAMED_REGISTRATION,
  },
  // {
  //   title:
  //     "Register your product with EUDAMED at their website <a href='https://webgate.ec.europa.eu/eudamed/landing-page#/' target='_blank'>here</a>",
  //   id: "eudamed-registration",
  //   card: "udi-creation-and-eudamed-registration",
  // },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "eudamed-registration-technical-file-filled",
  //   card: "udi-creation-and-eudamed-registration",
  //   link: ROUTES.DEVICE_DESCRIPTION_ONE,
  // },
  {
    title: "Complete the Technical File.",
    id: TEMPLATE_TYPE.TECHNICAL_FILE,
    card: ROADMAP_CARD_ID.GENERATE_TECHNICAL_FILE,
  },
  {
    title: "Upload your Physician's Handbook.",
    id: TEMPLATE_TYPE.PHYSICIANS_HANDBOOK,
    card: ROADMAP_CARD_ID.LABELING_INSTRUCTIONS_FOR_USE_AND_MANUALS,
  },
  {
    title: "Upload your Additional Software Test Plans.",
    id: TEMPLATE_TYPE.ADDITIONAL_SOFTWARE_TEST_PLANS,
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_PLANNING,
  },
  {
    title: "Upload your Additional Software Test Reports.",
    id: TEMPLATE_TYPE.ADDITIONAL_SOFTWARE_TEST_REPORTS,
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_REPORTS,
  },
  {
    title: "Upload your Marketing Content.",
    id: TEMPLATE_TYPE.MARKETING_CONTENT,
    card: ROADMAP_CARD_ID.LABELING_INSTRUCTIONS_FOR_USE_AND_MANUALS,
  },
  {
    title: "Upload your User Manual.",
    id: TEMPLATE_TYPE.USER_MANUAL,
    card: ROADMAP_CARD_ID.LABELING_INSTRUCTIONS_FOR_USE_AND_MANUALS,
  },
  {
    title: "Complete the Interface Design.",
    id: TEMPLATE_TYPE.INTERFACE_DESIGN,
    card: ROADMAP_CARD_ID.DESIGN,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasHardware(device);
      },
    },
  },
  {
    title: "Complete the Mechanical Design.",
    id: TEMPLATE_TYPE.MECHANICAL_DESIGN,
    card: ROADMAP_CARD_ID.DESIGN,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasMechanical(device);
      },
    },
  },
  {
    title: "Complete the Electrical Design.",
    id: TEMPLATE_TYPE.ELECTRICAL_DESIGN,
    card: ROADMAP_CARD_ID.DESIGN,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasElectrical(device);
      },
    },
  },
  {
    title: "Complete the Firmware Design.",
    id: TEMPLATE_TYPE.FIRMWARE_DESIGN,
    card: ROADMAP_CARD_ID.DESIGN,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasFirmware(device);
      },
    },
  },
  {
    title: "Complete the Packaging and Shelf-life Design.",
    id: TEMPLATE_TYPE.PACKAGING_AND_SHELF_LIFE_DESIGN,
    card: ROADMAP_CARD_ID.DESIGN,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasHardware(device);
      },
    },
    enabled: false,
  },
  {
    title: "Complete the Manufacturing Validation Plan and Protocol.",
    id: TEMPLATE_TYPE.MANUFACTURING_VALIDATION_PLAN_AND_PROTOCOL,
    card: ROADMAP_CARD_ID.MANUFACTURING,
    enabled: false,
  },
  {
    title: "Complete the Manufacturing Validation Report.",
    id: TEMPLATE_TYPE.MANUFACTURING_VALIDATION_REPORT,
    card: ROADMAP_CARD_ID.MANUFACTURING,
    enabled: false,
  },
  {
    title: "Complete the Design Transfer Plan.",
    id: TEMPLATE_TYPE.DESIGN_TRANSFER_PLAN,
    card: ROADMAP_CARD_ID.MANUFACTURING,
    enabled: false,
  },
  {
    title: "Complete the Design Transfer Report.",
    id: TEMPLATE_TYPE.DESIGN_TRANSFER_REPORT,
    card: ROADMAP_CARD_ID.MANUFACTURING,
    enabled: false,
  },
  // card verification and validation
  //   Verification and Validation Plan (ready)
  // Biocompatibility Evaluation Plan (Drafted)
  // Electrical Verification Protocol (Drafted)
  // Packaging and Shelf-Life Verification Protocol (Drafted)
  // Performance and Bench Verification Protocol (Not started)
  // Performance and Bench Validation Protocol (Not started)
  // Software System Test Plan (already exists)
  // Additional Software Test Plans (already exists)
  {
    title: "Complete the Verification and Validation Plan.",
    id: TEMPLATE_TYPE.VERIFICATION_AND_VALIDATION_PLAN,
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_PLANNING,
    enabled: false,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasHardware(device);
      },
    },
  },
  {
    title: "Complete the Biocompatibility Evaluation Plan.",
    id: TEMPLATE_TYPE.BIOCOMPATIBILITY_EVALUATION_PLAN,
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_PLANNING,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasHardware(device);
      },
    },
    enabled: false,
  },
  {
    title: "Complete the Electrical Verification Protocol.",
    id: TEMPLATE_TYPE.ELECTRICAL_VERIFICATION_PROTOCOL,
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_PLANNING,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasElectrical(device);
      },
    },
    enabled: false,
  },
  {
    title: "Complete the Packaging and Shelf-Life Verification Protocol.",
    id: TEMPLATE_TYPE.PACKAGING_AND_SHELF_LIFE_VERIFICATION_PROTOCOL,
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_PLANNING,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasHardware(device);
      },
    },
    enabled: false,
  },
  {
    title: "Complete the Performance and Bench Verification Protocol.",
    id: TEMPLATE_TYPE.PERFORMANCE_AND_BENCH_VERIFICATION_PROTOCOL,
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_PLANNING,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasHardware(device);
      },
    },
    enabled: false,
  },
  {
    title: "Complete the Performance and Bench Validation Protocol.",
    id: TEMPLATE_TYPE.PERFORMANCE_AND_BENCH_VALIDATION_PROTOCOL,
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_PLANNING,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasHardware(device);
      },
    },
    enabled: false,
  },
  {
    title: "Complete the Biocompatibility Evaluation Report.",
    id: TEMPLATE_TYPE.BIOCOMPATIBILITY_EVALUATION_REPORT,
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_REPORTS,
    enabled: false,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasHardware(device);
      },
    },
  },
  {
    title: "Complete the Electrical Verification Report.",
    id: TEMPLATE_TYPE.ELECTRICAL_VERIFICATION_REPORT,
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_REPORTS,
    enabled: false,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasElectrical(device);
      },
    },
  },
  {
    title: "Complete the Packaging and Shelf-Life Verification Report.",
    id: TEMPLATE_TYPE.PACKAGING_AND_SHELF_LIFE_VERIFICATION_REPORT,
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_REPORTS,
    enabled: false,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasHardware(device);
      },
    },
  },
  {
    title:
      "Complete the Performance and Bench Verification and Validation Report.",
    id: TEMPLATE_TYPE.PERFORMANCE_AND_BENCH_VERIFICATION_AND_VALIDATION_REPORT,
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_REPORTS,
    enabled: false,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasHardware(device);
      },
    },
  },
  // card verification and validation stage checklists
  // Verification and Validation Stage Checklist (Not started)
  // Traceability Matrix (Not started)
  {
    title: "Complete the Traceability Matrix.",
    id: TEMPLATE_TYPE.TRACEABILITY_MATRIX,
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_STAGE_CHECKLISTS,
  },
  {
    title: "Complete the Verification and Validation Report",
    id: TEMPLATE_TYPE.VERIFICATION_AND_VALIDATION_REPORT,
    card: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_STAGE_CHECKLISTS,
    enabled: false,
    visibilityCondition: {
      isVisible: (_, device) => {
        return hasHardware(device);
      },
    },
  },
];
