import {
  useGetAssistantProcesses,
  useGetDevice,
  useGetDocuments,
  useUpdateAssistantProcessMutation,
} from "@hooks";
import { AssistantProcess, AssistantProcessStatus } from "@models";
import { Cancel } from "@mui/icons-material";
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  Link,
  MenuItem,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Crisp } from "crisp-sdk-web";
import { Flower } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NavigationButtons } from "src/components/Assistant/NavigationButtons";
import {
  changeTypeToTemplates,
  getDataKeysToUpdate,
} from "src/components/Assistant/utils";
import { ROUTE_SLUGS, ROUTES } from "src/config/navigation/routes";
import { useCrisp } from "src/hooks/Crisp";
import { useDebounce } from "src/hooks/useDebounce";
import { fillRouteWithSlugs } from "src/utils/navigation";
import { TextField } from "../components/Form/Elements/TextField";
import { SelectElement, StepValue, TextFieldElement } from "../types";

export const optionsToChangeType: Record<
  string,
  keyof typeof changeTypeToTemplates
> = {
  "I am fixing a bug or error in the software to restore the function of the product to it's original intended state":
    "bugFix",
  "I am making minor adjustments to existing features or visual improvements":
    "patch",
  "I am adding small features that don't change the intended use of the product":
    "minor",
  "I am adding new features or visual improvements that change the intended use of the product":
    "major",
};

const selectElement: SelectElement = {
  type: "select",
  id: "document-update-type",
  options: {
    label: "",
    multiple: true,
    options: Object.keys(optionsToChangeType),
  },
};

const textElement: TextFieldElement = {
  type: "textField",
  id: "document-update-description",
  options: {
    label: "Describe the changes you are making to the product",
    rows: 4,
  },
};

export const DocumentUpdate = () => {
  const { orgId = "", deviceId = "" } = useParams();
  const navigate = useNavigate();
  const [selectValue, setSelectValue] = useState<string[]>([]);
  const [textValue, setTextValue] = useState<StepValue>({
    answer: "",
  });
  const { setSupportOpen } = useCrisp();
  const { data: documents } = useGetDocuments(orgId, deviceId);
  const { data: assistantProcesses } = useGetAssistantProcesses({
    orgId,
    deviceId,
    status: AssistantProcessStatus.IN_PROGRESS,
  });
  const { data: device } = useGetDevice({ orgId, deviceId });
  const { mutateAsync: updateAssistantProcess, isPending: isPendingUpdate } =
    useUpdateAssistantProcessMutation(orgId, deviceId);

  const [assistantProcess, setAssistantProcess] =
    useState<AssistantProcess | null>(null);

  useEffect(() => {
    const process = assistantProcesses?.[0];
    if (!assistantProcess && process) {
      setAssistantProcess(process);
    }
  }, [assistantProcesses]);

  const updateProcessState = async (
    updates: Partial<AssistantProcess["state"]>
  ) => {
    if (!assistantProcess?.id) return;

    return updateAssistantProcess({
      processId: assistantProcess.id,
      body: {
        state: {
          ...assistantProcess.state,
          ...updates,
        },
      },
    });
  };

  const debouncedSave = useDebounce(
    1000,
    ({ description }: { description?: string }) => {
      if (!assistantProcess?.id) return;

      const hasDescriptionChanged = description !== textValue.answer;
      if (!hasDescriptionChanged) return;

      return updateProcessState({
        description: description || assistantProcess?.state.description,
        checkboxes: selectValue,
      });
    }
  );

  const saveDescription = async (description: string) => {
    return updateProcessState({
      description,
      checkboxes: selectValue,
    });
  };

  const handleChange = async (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const val = typeof value === "string" ? value.split(",") : value;
    setSelectValue(val);

    if (assistantProcess) {
      return updateProcessState({
        checkboxes: val,
      });
    }
  };

  useEffect(() => {
    const shouldSetInitialCheckboxState =
      assistantProcess?.state.checkboxes &&
      JSON.stringify(assistantProcess.state.checkboxes) !==
        JSON.stringify(selectValue) &&
      selectValue.length === 0;

    if (shouldSetInitialCheckboxState) {
      setSelectValue(
        assistantProcess.state.checkboxes.filter((x): x is string => !!x)
      );
    }

    const shouldSetInitialDescriptionState =
      assistantProcess?.state.description &&
      assistantProcess.state.description !== textValue.answer &&
      textValue.answer === "";

    if (shouldSetInitialDescriptionState) {
      setTextValue({
        answer: assistantProcess.state.description,
      });
    }
  }, [isPendingUpdate, assistantProcess, selectValue, textValue]);

  const handleSupportClick = () => {
    Crisp.message.setMessageText(
      "I have made major changes to my device and I need help to update the correct documents."
    );
    setSupportOpen(true);
  };

  const majorChanges = selectElement.options.options?.[3] || "";
  const hasUserSelectedMajorChanges = selectValue.includes(majorChanges);
  const isFormValid =
    selectValue.length > 0 && !hasUserSelectedMajorChanges && textValue.answer;

  if (!assistantProcess || !documents || !device) {
    // TODO: Add loading state
    return;
  }

  return (
    <div className="flex flex-1 flex-col gap-y-6">
      <div className="flex flex-col gap-y-3">
        <Typography variant="h1" className="flex items-center gap-2">
          Assistant <Flower className="text-purple-500" /> - Document Update
        </Typography>
        <Typography variant="body1">
          Tell us about your change and we will help you update the correct
          documents.
        </Typography>
      </div>
      <div className="flex flex-col gap-y-4">
        <div>
          <Typography>
            Choose which description best describes your changes
          </Typography>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={selectValue}
            className="w-full"
            onChange={handleChange}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  gap: 1,
                }}
              >
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    color={value === majorChanges ? "error" : "default"}
                    variant={value === majorChanges ? "outlined" : "filled"}
                    deleteIcon={
                      <Cancel
                        onMouseDown={(event: React.MouseEvent<SVGSVGElement>) =>
                          event.stopPropagation()
                        }
                      />
                    }
                    onDelete={async () => {
                      const newSelected = selected.filter((v) => v !== value);
                      setSelectValue(newSelected);
                      return updateProcessState({
                        checkboxes: newSelected,
                        description:
                          textValue.answer ||
                          assistantProcess?.state.description,
                      });
                    }}
                  />
                ))}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                width: "100%",
                style: {
                  width: "fit-content",
                },
              },
            }}
          >
            {selectElement.options.options?.map((name) => (
              <MenuItem key={name} value={name}>
                {selectElement.options.multiple && (
                  <Checkbox checked={selectValue.indexOf(name) > -1} />
                )}
                {name}
              </MenuItem>
            ))}
          </Select>
        </div>
        {hasUserSelectedMajorChanges && (
          <Alert severity="error">
            You have selected a major change, this process does not support
            those changes yet. Please{" "}
            <Link className="cursor-pointer" onClick={handleSupportClick}>
              click here
            </Link>{" "}
            to contact one of our experts for assistance.
          </Alert>
        )}
        <div className="flex flex-col gap-y-2">
          <Typography>{textElement.options.label}</Typography>
          <TextField
            data-testid="document-update-description"
            disabled={false}
            onChange={(_, value) => {
              setTextValue(value);
              const newDescription = value.answer || "";

              // If this is the first input (empty -> non-empty), save immediately
              if (textValue.answer === "" && newDescription !== "") {
                saveDescription(newDescription);
              } else {
                // Otherwise use debounced save for subsequent changes
                debouncedSave({
                  description: newDescription,
                });
              }
            }}
            element={textElement}
            value={textValue.answer || ""}
          />
        </div>
      </div>

      <NavigationButtons
        leftButton={{
          hasConfirmationModal: true,
        }}
        rightButton={{
          disabled: !isFormValid || !assistantProcess,
          onClick: async () => {
            const dataKeysToUpdate = getDataKeysToUpdate(
              selectValue,
              documents,
              device
            );

            await updateProcessState({
              currentPath: ROUTES.ASSISTANT_DOCUMENT_UPDATE_LIST,
              checkboxes: selectValue,
              description: textValue.answer || "",
              dataKeysToUpdate: dataKeysToUpdate,
            });

            navigate(
              fillRouteWithSlugs(ROUTES.ASSISTANT_DOCUMENT_UPDATE_LIST, {
                [ROUTE_SLUGS.ORG_ID]: orgId,
                [ROUTE_SLUGS.DEVICE_ID]: deviceId,
              })
            );
          },
        }}
      />
    </div>
  );
};
