import { Typography } from "@mui/material";
import Lottie from "react-lottie";
import animationData from "../../assets/search-doc.json";
import styles from "./LoadingDocList.module.css";

const defaultOptions = {
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const LoadingDocList = () => (
  <div
    data-testid="loading-doc-list"
    className="flex flex-col items-center justify-center h-full"
  >
    <Lottie options={defaultOptions} width={300} height={200} />
    <Typography variant="h2" className={styles.loading}>
      Analyzing documents that require updates
    </Typography>
  </div>
);
