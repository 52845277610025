import { Typography } from "@mui/material";
import { Suggestion, WorkflowState } from "@types";

export const SuggestionLoadingIndicator = ({
  suggestion,
  workflowState,
}: {
  suggestion: Suggestion;
  workflowState: WorkflowState;
}) => {
  return (
    ((!suggestion.value && suggestion.loading) ||
      (workflowState?.isHiddenOutputNode && workflowState?.isLoading)) && (
      <div className="absolute left-3 top-1/2 -translate-y-1/2 z-10 flex items-center justify-center gap-2 bg-purple-200 border border-purple-500 border-solid rounded-md px-2 py-1">
        <Typography variant="body2" className="text-purple-500 animate-pulse">
          {workflowState?.currentNode || "Loading"}{" "}
          <span className="loading-dots">
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </span>
        </Typography>
      </div>
    )
  );
};
