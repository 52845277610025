import { ROUTES } from "@config";
import { useGetAssistantProcesses } from "@hooks";
import { Button, Typography } from "@mui/material";
import { fillRouteWithSlugs } from "@utils";
import Lottie from "react-lottie";
import { Link, useParams } from "react-router-dom";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import { useUpdateAssistantProcessMutation } from "src/hooks/mutations";
import { AssistantProcessStatus } from "src/stores/models/assistant-process";
import animationData from "../../assets/highFive.json";

export const NoDocsToUpdate = () => {
  const { orgId = "", deviceId = "" } = useParams();
  const { data: assistantProcesses } = useGetAssistantProcesses({
    orgId,
    deviceId,
    status: AssistantProcessStatus.IN_PROGRESS,
  });
  const { mutate: updateAssistantProcess } = useUpdateAssistantProcessMutation(
    orgId,
    deviceId
  );

  return (
    <div
      data-testid="no-docs-to-update"
      className="flex justify-center h-full flex-col text-center items-center"
    >
      <Lottie
        options={{
          loop: false,
          autoplay: true,
          animationData: animationData,
        }}
        height={300}
        width={300}
        speed={2}
      />
      <div className="flex flex-col items-center gap-3 pt-6">
        <Typography variant="h2">
          Congrats! You have no documents to update.
        </Typography>
        <Button
          component={Link}
          to={fillRouteWithSlugs(ROUTES.DEVICE_OVERVIEW, {
            [ROUTE_SLUGS.ORG_ID]: orgId,
            [ROUTE_SLUGS.DEVICE_ID]: deviceId,
          })}
          variant="contained"
          onClick={() => {
            if (!assistantProcesses?.[0]?.id) return;
            updateAssistantProcess({
              processId: assistantProcesses?.[0]?.id,
              body: {
                status: AssistantProcessStatus.CANCELLED,
              },
            });
          }}
        >
          Finish
        </Button>
      </div>
    </div>
  );
};
