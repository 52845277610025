import { useAuth0 } from "@auth0/auth0-react";
import { User } from "@models";
import { Chat, KeyboardArrowDown, Router } from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { fillRouteWithSlugs } from "@utils";
import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { UserMenu } from "src/components/NavBar/userMenu/UserMenu";
import {
  getFirst2Initials,
  stringToColorHash,
} from "src/components/NavBar/utils";
import OnboardingTooltip from "src/components/OnboardingTooltip/OnboardingTooltip";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import { useCrisp } from "src/hooks/Crisp";
import { getOrgFromUser } from "src/utils/user";
import { ROUTES, theme } from "../../config";
import { useCurrentPath, useGetUser } from "../../hooks";
import DeviceNavbar from "../DeviceNavbar/DeviceNavbar";
import { LeftDrawerSkeleton } from "./LeftDrawerSkeleton";

export const LeftDrawer = ({ hasBanner }: { hasBanner: boolean }) => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [anchorUserMenu, setAnchorUserMenu] = useState<HTMLElement | null>(
    null
  );

  const [anchorOrgMenu, setAnchorOrgMenu] = useState<Element | null>(null);
  const [isOrgMenuOpen, setIsOrgMenuOpen] = useState(false);

  const currentPath = useCurrentPath();
  const { isAuthenticated } = useAuth0();

  const { data: user } = useGetUser(isAuthenticated);

  const { pathname } = useLocation();
  const { supportOpen, setSupportOpen } = useCrisp();

  const { deviceId = "", orgId = "" } = useParams();
  const org = getOrgFromUser(user, orgId);

  const clickUserMenuIcon = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorUserMenu(event.currentTarget);
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const closeUserMenu = () => {
    setAnchorUserMenu(null);
    setIsUserMenuOpen(false);
  };

  const clickOrgMenuIcon = (event: React.MouseEvent<Element, MouseEvent>) => {
    setAnchorOrgMenu(event.currentTarget);
    setIsOrgMenuOpen(!isOrgMenuOpen);
  };

  const closeOrgMenu = () => {
    setAnchorOrgMenu(null);
    setIsOrgMenuOpen(false);
  };

  const getFullRoute = (route: ROUTES, user: User, orgId: string) => {
    return fillRouteWithSlugs(route, {
      ...(user && { [ROUTE_SLUGS.ORG_ID]: orgId }),
    });
  };

  if (!user || !org) {
    return <LeftDrawerSkeleton />;
  }

  return (
    <div
      className={`${hasBanner ? "top-[var(--banner-height)] h-screen-minus-nav" : "h-screen top-0"} flex flex-col justify-between sticky z-10 top-0 border-r border-t-0 border-b-0 border-l-0 border-solid border-slate-200`}
    >
      <div>
        <List>
          <ListItemButton
            onClick={(e: React.MouseEvent<HTMLDivElement>) =>
              clickUserMenuIcon(e)
            }
          >
            <ListItem className="text-none flex items-center gap-2 justify-start text-black p-0">
              <Box
                className="w-6 h-6 rounded flex items-center justify-center text-xs font-medium"
                sx={{
                  bgcolor:
                    (org.id && stringToColorHash(org.id)) ||
                    theme.palette.grey[200],
                }}
              >
                {org.name ? getFirst2Initials(org.name) : "?"}
              </Box>
              <ListItemText primary={org.name || "Unknown Org"} />
              <KeyboardArrowDown />
            </ListItem>
          </ListItemButton>
          <UserMenu
            isOpen={isUserMenuOpen}
            close={closeUserMenu}
            anchorUserMenu={anchorUserMenu}
            user={user}
            org={org}
            anchorOrgMenu={anchorOrgMenu}
            isOrgMenuOpen={isOrgMenuOpen}
            closeOrgMenu={closeOrgMenu}
            clickOrgMenuIcon={clickOrgMenuIcon}
          />
        </List>
        <List
          key="platform-list"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Platform
            </ListSubheader>
          }
        >
          <ListItemButton
            selected={
              getFullRoute(ROUTES.DEVICE_MANAGEMENT, user, orgId) ===
              currentPath
            }
            component={Link}
            to={getFullRoute(ROUTES.DEVICE_MANAGEMENT, user, orgId)}
            state={{ previous: pathname }}
            className="gap-4"
            sx={{
              "&:hover": {
                bgcolor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            <ListItemIcon className="min-w-fit">
              <Router />
            </ListItemIcon>
            <ListItemText primary={"Device Library"} />
          </ListItemButton>
        </List>
        {deviceId && <DeviceNavbar />}
      </div>
      <List>
        <OnboardingTooltip
          databaseId="supportTooltipSeen"
          placement="right-start"
          title="Got a question?"
          subtitle="We're here to answer any sales or technical questions you have."
          buttonText="Got it!"
        >
          <ListItemButton
            onClick={() => setSupportOpen(!supportOpen)}
            className="gap-4"
          >
            <ListItemIcon className="min-w-fit">
              <Chat style={{ color: theme.palette.grey[500] }} />
            </ListItemIcon>
            <ListItemText primary={"Support Chat"} />
          </ListItemButton>
        </OnboardingTooltip>
      </List>
    </div>
  );
};
