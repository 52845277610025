/**
 * Async generator that wraps a stream of chunks and yields only the text within <answer>...</answer> tags.
 * - Removes any whitespace immediately after `<answer>` (even if it spans multiple chunks).
 * - Removes any whitespace immediately before `</answer>`.
 * - Handles cases where `<answer>` or `</answer>` are split across chunk boundaries.
 *
 * @param stream An async iterable of chunks (either strings or Buffers).
 */
export async function* extractAnswerStream(
  stream: AsyncIterable<string>
): AsyncGenerator<string> {
  const START_TAG = "<answer>";
  const END_TAG = "</answer>";

  // State flags.
  let inAnswer = false;
  let foundOpeningTag = false;
  let buffer = "";

  /**
   * Helper function that returns the length of a suffix of `buf` that
   * matches the beginning of `tag`. For example, if tag is "<answer>" and
   * buf ends with "<ans", it returns 4.
   *
   * @param buf The current buffer.
   * @param tag The tag to check for a partial match.
   */
  function partialTagSuffixLen(buf: string, tag: string): number {
    const maxLen = Math.min(buf.length, tag.length);
    for (let i = maxLen; i > 0; i--) {
      if (buf.slice(-i) === tag.slice(0, i)) {
        return i;
      }
    }
    return 0;
  }

  // Process each chunk from the input stream.
  for await (const chunk of stream) {
    // Append the new data to the buffer.
    buffer += chunk;

    // Process the accumulated buffer.
    while (true) {
      if (!inAnswer) {
        if (!foundOpeningTag) {
          // (1a) Look for the opening <answer> tag.
          const startIdx = buffer.indexOf(START_TAG);
          if (startIdx === -1) {
            // No complete opening tag found.
            const partialLen = partialTagSuffixLen(buffer, START_TAG);
            if (partialLen > 0) {
              // Keep the possible partial tag at the end.
              buffer = buffer.slice(-partialLen);
            } else {
              // Discard the buffer if nothing matches.
              buffer = "";
            }
            break; // Wait for more data.
          } else {
            // Found the full opening tag.
            buffer = buffer.slice(startIdx + START_TAG.length);
            foundOpeningTag = true;
            // Continue the loop to trim whitespace after <answer>.
            continue;
          }
        }

        if (foundOpeningTag) {
          // (1b) Remove any leading whitespace after <answer>.
          buffer = buffer.replace(/^\s+/, "");
          if (buffer.length === 0) {
            // All data was whitespace; wait for more.
            break;
          } else {
            // Found non-whitespace content; switch to "inAnswer" state.
            inAnswer = true;
            foundOpeningTag = false;
            continue;
          }
        }
      } else {
        // (2) We are inside <answer>, so look for the closing </answer> tag.
        const endIdx = buffer.indexOf(END_TAG);
        if (endIdx === -1) {
          // Closing tag not found.
          const partialLen = partialTagSuffixLen(buffer, END_TAG);
          if (partialLen > 0) {
            // Yield everything up to the partial tag.
            const content = buffer.slice(0, buffer.length - partialLen);
            if (content) {
              yield content;
            }
            // Keep the partial closing tag.
            buffer = buffer.slice(-partialLen);
          } else {
            // No partial match; yield the entire buffer.
            if (buffer) {
              yield buffer;
            }
            buffer = "";
          }
          break; // Wait for more data.
        } else {
          // Found the complete closing tag.
          let contentToYield = buffer.slice(0, endIdx);
          // Trim trailing whitespace.
          contentToYield = contentToYield.replace(/\s+$/, "");
          if (contentToYield) {
            yield contentToYield;
          }
          // Remove the closing tag from the buffer.
          buffer = buffer.slice(endIdx + END_TAG.length);
          // Reset state to look for the next <answer>.
          inAnswer = false;
          // Continue in case there's another <answer> in the buffer.
          continue;
        }
      }
    }
  }
}

/**
 * Extracts and concatenates text within `<answer>...</answer>` tags from a complete string.
 * - Removes any whitespace immediately after `<answer>`.
 * - Removes any whitespace immediately before `</answer>`.
 * - Supports multiple answer blocks in the input.
 * - In case an answer block is incomplete (i.e. no closing tag), returns the available content.
 *
 * @param input The complete string that may contain one or more <answer> blocks.
 * @returns A concatenated string of all extracted answer texts.
 */
export function extractAnswerFromString(input: string): string {
  const START_TAG = "<answer>";
  const END_TAG = "</answer>";
  let result = "";
  let currentIndex = 0;

  while (currentIndex < input.length) {
    // Look for the opening <answer> tag.
    const startTagIndex = input.indexOf(START_TAG, currentIndex);
    if (startTagIndex === -1) {
      // No more answer blocks.
      break;
    }

    // Compute the index just after the opening tag.
    let contentStart = startTagIndex + START_TAG.length;

    // Remove any whitespace immediately after `<answer>`.
    while (contentStart < input.length && /\s/.test(input[contentStart])) {
      contentStart++;
    }

    // Look for the closing </answer> tag.
    const endTagIndex = input.indexOf(END_TAG, contentStart);
    let answerContent: string;

    if (endTagIndex === -1) {
      // No closing tag found; extract until the end of the input.
      answerContent = input.substring(contentStart);
      // Remove any trailing whitespace.
      answerContent = answerContent.replace(/\s+$/, "");
      result += answerContent;
      // Since we reached the end of input, break out.
      break;
    } else {
      // Extract the answer text.
      answerContent = input.substring(contentStart, endTagIndex);
      // Remove any whitespace immediately before `</answer>`.
      answerContent = answerContent.replace(/\s+$/, "");
      result += answerContent;
      // Move past the closing tag to search for the next answer.
      currentIndex = endTagIndex + END_TAG.length;
    }
  }

  return result;
}
