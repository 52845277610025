import { Device } from "@models";
import { hasHardware } from "@utils";
import { ROUTES } from "..";
import { CardContentType, DataKeyWithValue } from "../../types";
import { ArticleId } from "../helpcenter/articles";
import { ROADMAP_STAGE_ID } from "./stages";

export enum ROADMAP_CARD_ID {
  AUDITS_AND_REVIEW = "audits-and-review",
  CAPAS_AND_VIGILANCE = "capas-and-vigilance",
  CLINICAL_EVALUATION = "clinical-evaluation",
  CLINICAL_INVESTIGATIONS = "clinical-investigations",
  COMPLAINTS = "complaints",
  COMPUTER_SYSTEM_VALIDATION = "computer-system-validation",
  DECLARATION_OF_CONFORMITY = "declaration-of-conformity",
  DEPLOYMENT = "deployment",
  DESIGN = "design",
  DESIGN_AND_DEVELOPMENT_PLANS = "design-and-development-plans",
  DESIGN_OUTPUTS = "design-outputs",
  DESIGN_STAGE_CHECKLISTS = "design-stage-checklists",
  DEVICE_BASIC_CHARACTERISTICS = "device-basic-characteristics",
  ESTABLISH_QUALITY_MANAGEMENT_SYSTEM = "establish-quality-management-system",
  GENERAL_SAFETY_AND_PERFORMANCE_REQUIREMENTS_CHECKLIST = "general-safety-and-performance-requirements-checklist",
  GENERATE_TECHNICAL_FILE = "generate-technical-file",
  LABELING_INSTRUCTIONS_FOR_USE_AND_MANUALS = "labeling-instructions-for-use-and-manuals",
  MANUFACTURING = "manufacturing",
  POST_MARKET_SURVEILLANCE = "post-market-surveillance",
  PRODUCT_CHANGES = "product-changes",
  PRODUCT_RELEASE = "product-release",
  PRODUCT_REQUIREMENTS = "product-requirements",
  PURCHASING_SALES_AND_SUPPLIERS = "purchasing-sales-and-suppliers",
  RISK_MANAGEMENT = "risk-management",
  SURVEILLANCE_REPORTS = "surveillance-reports",
  TRAINING = "training",
  UDI_CREATION_AND_EUDAMED_REGISTRATION = "udi-creation-and-eudamed-registration",
  USABILITY_AND_HUMAN_FACTORS_ENGINEERING = "usability-and-human-factors-engineering",
  USER_NEEDS = "user-needs",
  VERIFICATION_AND_VALIDATION_PLANNING = "verification-and-validation-planning",
  VERIFICATION_AND_VALIDATION_REPORTS = "verification-and-validation-reports",
  VERIFICATION_AND_VALIDATION_STAGE_CHECKLISTS = "verification-and-validation-stage-checklists",
}
export type RoadmapCardConfig = {
  title: string;
  id: ROADMAP_CARD_ID;
  enabled?: boolean;
  order?: number;
  stage: ROADMAP_STAGE_ID;
  link?: ROUTES;
  helpCenterArticleId?: ArticleId;
  visibilityCondition?: (
    availableDataKeys: DataKeyWithValue[],
    device: Device
  ) => boolean;
  tasksDescription: string;
  customContent?: CardContentType;
  techfileDescription?: string;
  afterTasksDescription?: string;
};

export const ROADMAP_CARDS: RoadmapCardConfig[] = [
  {
    title: "Establish Quality Management System",
    id: ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
    stage: ROADMAP_STAGE_ID.QMS,
    order: 1,
    helpCenterArticleId: ArticleId.QualityManagementSystem,
    tasksDescription:
      "Every medical device company must have a quality management system (QMS). It defines how your company runs and dictates the processes you will have in place while you develop your medical device. The international standard used for creating a quality management system is ISO 13485:2016.\nSet up the basic SOPs and Quality Manual using the templates above. These won’t go into the technical file but are essential to have stored in your document storage system for auditorsMany of the other documents during this process come out of ISO 13485 like the post-market surveillance, design controls, and corrective and preventative actions. We will cover these later. For more information, refer to our guide.",
  },
  {
    title: "Device Characteristics and Classification",
    id: ROADMAP_CARD_ID.DEVICE_BASIC_CHARACTERISTICS,
    stage: ROADMAP_STAGE_ID.PLANNING,
    helpCenterArticleId: ArticleId.DeviceBasicCharacteristics,
    tasksDescription:
      "Basic device characteristics should be determined in this step. What does your device do, the intended purpose, the intended patients, principles of operation, and conditions for use of the device. This will lay the groundwork for your device’s class and required regulatory documentation for certification.",
  },
  {
    id: ROADMAP_CARD_ID.DESIGN_AND_DEVELOPMENT_PLANS,
    title: "Design and Development Plans",
    stage: ROADMAP_STAGE_ID.PLANNING,
    tasksDescription:
      "The design and development plans are the plans for how you will develop your device. These plans will be used to guide the development of your device and will be used to create the software requirements.",
  },
  {
    id: ROADMAP_CARD_ID.COMPUTER_SYSTEM_VALIDATION,
    title: "Computer System Validation",
    stage: ROADMAP_STAGE_ID.QMS,
    tasksDescription:
      "The computer system validation is the validation of your computer system. This is where you will validate your computer system and ensure that it is working as intended.",
  },
  {
    title: "Risk Management",
    id: ROADMAP_CARD_ID.RISK_MANAGEMENT,
    stage: ROADMAP_STAGE_ID.DESIGN_AND_DEVELOPMENT,
    order: 2,
    helpCenterArticleId: ArticleId.RiskManagementSystem,
    tasksDescription:
      "You need to define what risks you expect the patients using your device will encounter and minimize those risks as far as possible. Risk management is governed by an international standard in a similar manner as the QMS called ISO 14971. The documentation you create will identify these risks and you can implement a plan to reduce them while you develop your product.",
  },
  {
    title: "User Needs",
    id: ROADMAP_CARD_ID.USER_NEEDS,
    stage: ROADMAP_STAGE_ID.DESIGN_AND_DEVELOPMENT,
    order: 1,
    tasksDescription:
      "For this step we are creating user needs. These are essentially high level functions that your product should perform on/for a user. They will be used as a framework for how your software is built and serve as the backbone of what you are trying to build. It is ok if this list isn’t perfect, we can update it down the road.",
  },
  {
    title: "Product Requirements",
    id: ROADMAP_CARD_ID.PRODUCT_REQUIREMENTS,
    stage: ROADMAP_STAGE_ID.DESIGN_AND_DEVELOPMENT,
    order: 3,
    tasksDescription:
      "Product requirements are the high level requirements that your product must meet. They are used to guide the development of your product and are used to create the software requirements.",
  },
  {
    title: "Design",
    id: ROADMAP_CARD_ID.DESIGN,
    stage: ROADMAP_STAGE_ID.DESIGN_AND_DEVELOPMENT,
    order: 4,
    tasksDescription:
      "The design stage is where you will design your device. This includes the design of the device, the software, and the hardware.",
  },
  // {
  //   title: "Software Design and Development",
  //   id: ROADMAP_CARD_ID.SOFTWARE_DESIGN_AND_DEVELOPMENT,
  //   stage: ROADMAP_STAGE_ID.DESIGN_AND_DEVELOPMENT,
  //   enabled: true,
  //   visibilityCondition: (_, device) => hasSoftware(device),
  //   tasksDescription:
  //     "Now we will go through the process spelling out our software requirements and how we plan to develop and test our software product. We will outline our software architecture and designs which we will run through testing and validation in the later stages.",
  // },
  {
    title: "Purchasing, Sales and Suppliers",
    id: ROADMAP_CARD_ID.PURCHASING_SALES_AND_SUPPLIERS,
    stage: ROADMAP_STAGE_ID.QMS,
    order: 4,
    tasksDescription:
      "Purchasing from suppliers and sale to customers are regulated to ensure that the customer is safe from misinformation and is receiving a product that lives up to the performance standards required of a medical device. These processes essentially vet your suppliers so you get a quality product and make sure you adhere to medical device law in your marketing and sales practices.",
  },
  {
    title: "Design Stage Checklists",
    id: ROADMAP_CARD_ID.DESIGN_STAGE_CHECKLISTS,
    order: 5,
    stage: ROADMAP_STAGE_ID.DESIGN_AND_DEVELOPMENT,
    tasksDescription:
      "You have made it through the design and development stage! We are well on our way to developing our software so that it is compliant. When it comes to regulatory compliance, documentation is everything. In this last stage we will fill out checklists to ensure that everything we have done so far is in accordance with our Standard Operating Procedures (SOP) for quality and product. Once we complete our checklists we can move on to the next stage.",
  },
  {
    title: "Usability and Human Factors Engineering",
    id: ROADMAP_CARD_ID.USABILITY_AND_HUMAN_FACTORS_ENGINEERING,
    order: 1,
    stage: ROADMAP_STAGE_ID.VERIFICATION_AND_VALIDATION,
    enabled: true,
    tasksDescription:
      "Usability and Human Factors Engineering makes sure that your users can use your device. Sounds simple right? You would be surprised at what your users do when they get ahold of your medical device.\n\nYou will need to test your product interface with some of your ideal users and watch them perform the tasks they need to perform. You often don’t need to test this in more than 15 users and you need to make sure that all critical tasks are performed (if there are any). Critical tasks are tasks that could result in serious harm.\n\nUsability testing is not the same as a clinical trial as you can perform it doing “simulated use” which means the device isn’t active but the users can still experience and interact with your device.",
  },
  {
    id: ROADMAP_CARD_ID.DESIGN_OUTPUTS,
    title: "Design Outputs",
    stage: ROADMAP_STAGE_ID.VERIFICATION_AND_VALIDATION,
    order: 2,
    tasksDescription:
      "The design outputs are the outputs of the design and development plans. These outputs will be used to create the software requirements.",
    visibilityCondition: (_, device) => hasHardware(device),
  },
  {
    id: ROADMAP_CARD_ID.MANUFACTURING,
    title: "Manufacturing",
    stage: ROADMAP_STAGE_ID.VERIFICATION_AND_VALIDATION,
    order: 3,
    tasksDescription:
      "The manufacturing stage is where you will create the manufacturing documentation for your device. This includes the manufacturing instructions, the manufacturing records, and the manufacturing process.",
    visibilityCondition: (_, device) => hasHardware(device),
  },
  {
    title: "Verification and Validation Planning",
    id: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_PLANNING,
    stage: ROADMAP_STAGE_ID.VERIFICATION_AND_VALIDATION,
    order: 4,
    tasksDescription:
      "The verification and validation planning is the planning for how you will verify and validate your device. This includes the verification and validation plans, the verification and validation reports, and the verification and validation stage checklists.",
  },
  {
    id: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_REPORTS,
    title: "Verification and Validation Reports",
    stage: ROADMAP_STAGE_ID.VERIFICATION_AND_VALIDATION,
    order: 5,
    tasksDescription:
      "The verification and validation reports are the reports of the verification and validation of your device. These reports will be used to create the verification and validation reports.",
  },
  {
    id: ROADMAP_CARD_ID.VERIFICATION_AND_VALIDATION_STAGE_CHECKLISTS,
    title: "Verification and Validation Stage Checklists",
    stage: ROADMAP_STAGE_ID.VERIFICATION_AND_VALIDATION,
    order: 6,
    tasksDescription:
      "The verification and validation stage checklists are the checklists of the verification and validation of your device. These checklists will be used to create the verification and validation stage checklists.",
  },
  {
    title: "Training",
    id: ROADMAP_CARD_ID.TRAINING,
    stage: ROADMAP_STAGE_ID.QMS,
    order: 2,
    enabled: true,
    tasksDescription:
      "As part of your quality management system, everyone at the company needs to be up to date on new SOPs and new information that will impact how they do their job and develop your product. It is your job to maintain records of training your employees according to document and record control and your SOP Human Resources and Training. These files will be a list of training provided to each of your employees.",
  },
  {
    title: "Labeling, Instructions for Use, and Manuals",
    id: ROADMAP_CARD_ID.LABELING_INSTRUCTIONS_FOR_USE_AND_MANUALS,
    stage: ROADMAP_STAGE_ID.REGULATORY_PREPARATION,
    tasksDescription:
      "Labeling, believe it or not, also has its own ISO Standards. There are required symbols that need to be included in your product labels in order to properly be marketed in the EU. Refer to ISO 15223-1 for the symbols to be used. For software or products delivered electronically, there is an e-labeling regulation 2021/2226 you need to adhere to.\n\nYour instructions for use need to include relevant information like your intended purpose, patient population, contraindications and warnings and need to have translations for all countries in which you plan to market your device. Follow the template we provided and you should be good to go! ",
  },
  {
    title: "Post-Market Surveillance",
    id: ROADMAP_CARD_ID.POST_MARKET_SURVEILLANCE,
    stage: ROADMAP_STAGE_ID.REGULATORY_PREPARATION,
    tasksDescription:
      "Post-market surveillance is a way to track the safety and performance of your device after it has hit the market. You need to have a process in place to assess your devices evolving risks and patient benefits. Post-market surveillance reports should be created every year. The Periodic Safety Update Report is required every 2 years for Class IIA products and above. If you don’t have any clinical studies being performed while your device is on the market, you don’t need to fill out a Post-Market Clinical Follow-up Report.",
  },
  {
    title: "Clinical Evaluation",
    id: ROADMAP_CARD_ID.CLINICAL_EVALUATION,
    stage: ROADMAP_STAGE_ID.REGULATORY_PREPARATION,
    enabled: true,
    tasksDescription:
      "The clinical evaluation demonstrates to the regulators that your product performs as intended when used clinically and is safe for use in humans. If you have not performed a clinical trial, the clinical evaluation is where you demonstrate that your device is equivalent to another device already on the market and therefore no clinical trial is required. You will need to do a review of the scientific literature and online vigilance databases to support your device’s safety and performance.",
  },
  {
    title: "General Safety and Performance Requirements Checklist",
    id: ROADMAP_CARD_ID.GENERAL_SAFETY_AND_PERFORMANCE_REQUIREMENTS_CHECKLIST,
    stage: ROADMAP_STAGE_ID.REGULATORY_PREPARATION,
    tasksDescription:
      "The General Safety and Performance Requirements come from the European MDR 2017/745 regulations and they spell out each specific requirement that your medical device needs to have depending on what the product itself does. The checklist serves as a way for auditors to find all of this information quickly and easily as they will need to verify all of the documentation to ensure that your product can go onto the market.",
  },
  {
    title: "Deployment",
    id: ROADMAP_CARD_ID.DEPLOYMENT,
    stage: ROADMAP_STAGE_ID.REGULATORY_PREPARATION,
    tasksDescription:
      "The deployment stage is where you will deploy your device to the market. This is where you will generate your technical file and submit it to your Notified Body for review.",
  },
  {
    title: "Audits and Review",
    id: ROADMAP_CARD_ID.AUDITS_AND_REVIEW,
    enabled: true,
    stage: ROADMAP_STAGE_ID.QMS,
    tasksDescription:
      "Internal auditing and Management Review is a requirement for a compliant QMS. Now that your product has been documented as going through the compliant development and release process, we will audit your documentation to make sure nothing is missing.\n\nThe internal audit should be performed according to the SOP and will ensure that all of your processes will be followed. It will take half a day to do your internal audit most likely.\n\nNext, your management review is when the high level management reviews your processes and key performance indicators to make sure the QMS is functioning well. Once the internal audit and management review is complete, you are ready for the submission stage!",
  },
  {
    title: "Declaration of Conformity",
    id: ROADMAP_CARD_ID.DECLARATION_OF_CONFORMITY,
    enabled: true,
    stage: ROADMAP_STAGE_ID.SUBMISSION,
    tasksDescription:
      "Your declaration of conformity is a legally binding document that states that to your best knowledge you satisfy and conform to all the requirements for the CE mark.\n\nOnce you have completed all sections and have created your declaration of conformity, you can adhere your CE mark to your product and sell it on the market! Congratulations!",
  },
  {
    title: "UDI Creation and EUDAMED Registration",
    id: ROADMAP_CARD_ID.UDI_CREATION_AND_EUDAMED_REGISTRATION,
    stage: ROADMAP_STAGE_ID.SUBMISSION,
    enabled: true,
    tasksDescription:
      "You must create a unique device identifier for your device and register it with the EUDAMED system which is an online registry of all medical devices CE marked in Europe.\n\nIf your device is Class I, you do not need to create a UDI until May of 2025 due to a grace period.",
  },
  {
    title: "Technical File",
    id: ROADMAP_CARD_ID.GENERATE_TECHNICAL_FILE,
    enabled: true,
    stage: ROADMAP_STAGE_ID.SUBMISSION,
    tasksDescription:
      "Once your technical file is generated, you can download it and submit it to your Notified Body.",
    // customContent: CardContentType.GENERATE_TECHFILE,
  },
  {
    title: "Product Release",
    id: ROADMAP_CARD_ID.PRODUCT_RELEASE,
    enabled: true,
    stage: ROADMAP_STAGE_ID.SUBMISSION,
    tasksDescription:
      "Before you can put your product on the market, you need to ensure that it goes through the proper product release processes first. This will be used to make sure our teams (and auditors) know that we checked to make sure we completed every prerequisite before releasing our new product.",
  },
  {
    title: "Product Changes",
    id: ROADMAP_CARD_ID.PRODUCT_CHANGES,
    stage: ROADMAP_STAGE_ID.POST_MARKET,
    tasksDescription:
      "Product changes are inevitable as your product evolves. You need to have a process in place to document any changes to your product and ensure that you are compliant with the regulations.",
  },
  {
    title: "CAPAs and Vigilance",
    id: ROADMAP_CARD_ID.CAPAS_AND_VIGILANCE,
    stage: ROADMAP_STAGE_ID.POST_MARKET,
    tasksDescription:
      "Corrective and preventative actions (CAPAs) are a requirement for a compliant QMS. Now that your product has been documented as going through the compliant development and release process, we will audit your documentation to make sure nothing is missing.",
  },
  {
    title: "Surveillance Reports",
    id: ROADMAP_CARD_ID.SURVEILLANCE_REPORTS,
    stage: ROADMAP_STAGE_ID.POST_MARKET,
    tasksDescription:
      "Surveillance reports are a requirement for a compliant QMS. Now that your product has been documented as going through the compliant development and release process, we will audit your documentation to make sure nothing is missing.",
  },
  {
    id: ROADMAP_CARD_ID.COMPLAINTS,
    title: "Complaints",
    stage: ROADMAP_STAGE_ID.POST_MARKET,
    tasksDescription:
      "Complaints are a requirement for a compliant QMS. Now that your product has been documented as going through the compliant development and release process, we will audit your documentation to make sure nothing is missing.",
  },
];
