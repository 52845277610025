import {
  CloudUploadOutlined,
  ContentCopy,
  RedoRounded,
  ReplayRounded,
  UndoRounded,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useRef, useState } from "react";
import ConfirmationModal from "../Form/ConfirmationModal";

type Props = {
  hasInlineSuggestion: boolean;
  hasTransformer: boolean;
  hasCSVUpload: boolean;
  onRegenerateSuggestion?: () => void;
  onReRunTransformer?: () => void;
  onCSVUpload?: (file: File) => void;
  onRedo?: () => void;
  onUndo?: () => void;
  undoDisabled?: boolean;
  redoDisabled?: boolean;
  onCopyTable?: () => void;
};

export const StepInputControls = ({
  hasInlineSuggestion,
  hasTransformer,
  hasCSVUpload,
  onRegenerateSuggestion,
  onReRunTransformer,
  onCSVUpload,
  onRedo,
  onUndo,
  onCopyTable,
}: Props) => {
  const [
    regenerateSuggestionConfirmationModalOpen,
    setRegenerateSuggestionConfirmationModalOpen,
  ] = useState(false);
  const [
    reRunTransformerConfirmationModalOpen,
    setReRunTransformerConfirmationModalOpen,
  ] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type === "text/csv" && onCSVUpload) {
      onCSVUpload(file);
    } else {
      alert("Please upload a valid CSV file.");
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <div className="flex items-start justify-start gap-x-2">
        <div className="flex items-center justify-start">
          {onUndo && (
            <Tooltip title="Undo" placement="bottom">
              <IconButton size="small" onClick={onUndo}>
                <UndoRounded />
              </IconButton>
            </Tooltip>
          )}
          {onRedo && (
            <Tooltip title="Redo" placement="bottom">
              <IconButton size="small" onClick={onRedo}>
                <RedoRounded />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {hasInlineSuggestion ? (
          <Tooltip title="Regenerate suggestion" placement="bottom">
            <IconButton
              size="small"
              onClick={() => setRegenerateSuggestionConfirmationModalOpen(true)}
            >
              <ReplayRounded />
            </IconButton>
          </Tooltip>
        ) : hasTransformer ? (
          <Tooltip title="Regenerate" placement="bottom">
            <IconButton
              size="small"
              onClick={() => setReRunTransformerConfirmationModalOpen(true)}
            >
              <ReplayRounded />
            </IconButton>
          </Tooltip>
        ) : null}
        {hasCSVUpload && onCSVUpload && (
          <Tooltip title="Upload CSV" placement="bottom">
            <IconButton
              size="small"
              onClick={() => fileInputRef.current?.click()}
            >
              <CloudUploadOutlined />
            </IconButton>
          </Tooltip>
        )}
        {onCopyTable && (
          <Tooltip title="Copy Table" placement="bottom">
            <IconButton size="small" onClick={onCopyTable}>
              <ContentCopy />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept=".csv"
        onChange={handleFileUpload}
      />
      <ConfirmationModal
        open={regenerateSuggestionConfirmationModalOpen}
        onClose={() => setRegenerateSuggestionConfirmationModalOpen(false)}
        onConfirm={() => {
          setRegenerateSuggestionConfirmationModalOpen(false);
          onRegenerateSuggestion?.();
        }}
        title="Regenerate Suggestion"
        content="Are you sure you want to regenerate the suggestion? Your answers will be lost."
      />
      <ConfirmationModal
        open={reRunTransformerConfirmationModalOpen}
        onClose={() => setReRunTransformerConfirmationModalOpen(false)}
        onConfirm={() => {
          setReRunTransformerConfirmationModalOpen(false);
          onReRunTransformer?.();
        }}
        title="Regenerate Answer"
        content="Are you sure you want to regenerate the answer? All the changes made by you will be lost."
      />
    </>
  );
};
