import { queryClient } from "@utils";
import { useCallback, useRef } from "react";

type OptimisticUpdateConfig<TData, TVariables> = {
  queryKey: string[];
  updateFn: (
    oldData: TData | undefined,
    variables: TVariables
  ) => TData | undefined;
};

interface DebounceQueryMutationOptions<TData, TVariables> {
  delay?: number;
  optimisticUpdate?: OptimisticUpdateConfig<TData, TVariables>;
}

export const useDebounceQueryMutation = <TVariables, TData, TResult>(
  mutationFn: (variables: TVariables) => Promise<TResult>,
  options: DebounceQueryMutationOptions<TData, TVariables>
) => {
  const { delay = 1000, optimisticUpdate } = options;
  const timerRef = useRef<NodeJS.Timeout>();

  const debouncedMutate = useCallback(
    (variables: TVariables) => {
      // Handle optimistic updates if configured
      if (optimisticUpdate) {
        queryClient.setQueryData(
          optimisticUpdate.queryKey,
          (oldData: TData | undefined) =>
            optimisticUpdate.updateFn(oldData, variables)
        );
      }

      // Debounce the actual API call
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        mutationFn(variables);
      }, delay);
    },
    [mutationFn, delay, optimisticUpdate]
  );

  return { mutate: debouncedMutate };
};
