export const ProgressBubbleLine = ({
  totalItemCount,
  currentItemIndex,
  className,
}: {
  totalItemCount: number;
  currentItemIndex: number;
  className: string;
}) => {
  const completeColor = "bg-[green]";
  const incompleteColor = "bg-gray-300";

  const currentItem1Indexed = currentItemIndex + 1;
  // Safety check to prevent division by 0 or going out of bounds
  const completedItemPercentage =
    totalItemCount === 0
      ? 0
      : currentItem1Indexed > totalItemCount
        ? 100
        : Math.floor((currentItem1Indexed / totalItemCount) * 100);

  return (
    <div className={`py-2 relative ${className}`}>
      <div
        className={`absolute h-[10px] w-full rounded-xl ${incompleteColor}`}
      ></div>
      <div
        className={`relative h-[10px] rounded-xl ${completeColor}`}
        style={{ width: `${completedItemPercentage}%` }}
      ></div>
    </div>
  );
};
