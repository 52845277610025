import {
  useDocEngine,
  useGetDevice,
  useGetDocuments,
  useGetUser,
  usePatchDocumentVersionMutation,
} from "@hooks";
import { Device, Document, TEMPLATE_TYPE, User } from "@models";
import { Alert, TextField } from "@mui/material";
import MarkdownPreview from "@uiw/react-markdown-preview";
import {
  checkIfDocumentIsBeingEdited,
  getDocTemplate,
  isUserEntitledToDoc,
} from "@utils";
import { orderBy } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getOrgFromUser } from "src/utils/user";
import { DocumentViewerSkeleton } from "../..";
import { DocLockedOverlay } from "./DocLockedOverlay";
import NoDocAnimation from "./NoDocAnimation";
import DocInfoDrawer from "./docInfoDrawer/DocInfoDrawer";
import DocViewerTopBar from "./docViewerTopBar/DocViewerTopBar";
import { useGenerateDocument } from "./hooks/useGenerateDocument";
import useOutstandingAction from "./hooks/useOutstandingAction";
import useSelectDocumentVersion from "./hooks/useSelectDocument";

export const GeneratedDocViewer = ({
  deviceId,
  templateId,
}: {
  deviceId: string;
  templateId: TEMPLATE_TYPE;
}) => {
  // If the template id changes reset the selected document version otherwise the old selected document version will be used for the new template
  useEffect(() => {
    setSelectedDocVersionId(undefined);
    setGeneratedDoc(undefined);
    outstandingAction.reset();
  }, [templateId]);
  const { orgId = "" } = useParams<{ orgId: string }>();

  const docEngine = useDocEngine(deviceId);
  const patchDocumentMutation = usePatchDocumentVersionMutation();

  const [docInfoDrawerOpen, setDocInfoDrawerOpen] = useState(false);
  const [isCreatingNewInstance, setIsCreatingNewInstance] = useState(false);

  const rawEditRef = useRef<HTMLInputElement | null>(null);

  const { data: user } = useGetUser();
  const { data: device } = useGetDevice({ orgId, deviceId });
  const { data: documents } = useGetDocuments(orgId, deviceId);
  const org = getOrgFromUser(user, orgId);

  const docInstances = useMemo(
    () =>
      orderBy(
        documents?.filter((doc) => doc.name === templateId),
        "createdAt",
        "desc"
      ),
    [documents, templateId]
  );

  const {
    setSelectedDocVersionId,
    selectedDocVersionId,
    selectedDocVersion,
    selectedDoc,
    handleCreateNewVersion,
    selectLoading,
    selectDocumentVersion,
    selectDocument,
  } = useSelectDocumentVersion({
    documents: docInstances,
    templateId,
    deviceId,
  });

  const docIsUnlocked = org ? isUserEntitledToDoc(org, templateId) : false;

  const {
    generatedDoc,
    setGeneratedDoc,
    isGeneratingDoc,
    signaturesAndHistory,
  } = useGenerateDocument({
    document: selectedDoc,
    user,
    selectedDocVersion,
    templateId,
    documents,
    deviceId,
    device,
    selectedDoc,
  });

  const outstandingAction = useOutstandingAction({
    document: selectedDoc,
    selectedDocVersionId: selectedDocVersionId,
    user,
    setDocInfoDrawerOpen: setDocInfoDrawerOpen,
    selectedDocVersion: selectedDocVersion,
  });

  const docText = selectedDocVersion?.snapshot || generatedDoc?.data;

  const [isEditingRawDocument, setIsEditingRawDocument] = useState(false);

  const onDrawerToggle = () => setDocInfoDrawerOpen((prev) => !prev);

  const isDocumentIsBeingEdited = (device: Device, documents: Document[]) =>
    checkIfDocumentIsBeingEdited({
      selectedDoc,
      selectedDocVersion,
      generatedDoc,
      device,
      documents,
    });

  useEffect(() => {
    setIsEditingRawDocument(false);
  }, [selectedDocVersion?.id, selectedDoc?.id, templateId]);

  const handleSaveRawDocument = async (
    deviceId: string,
    selectedDocId: string,
    selectedDocVersionId: string
  ) => {
    const text = rawEditRef.current?.value;

    if (!isEditingRawDocument || !text) return;

    patchDocumentMutation.mutate({
      orgId,
      docId: selectedDocId,
      docVersionId: selectedDocVersionId,
      deviceId,
      data: {
        snapshot: text,
      },
    });

    setIsEditingRawDocument(false);
  };

  const handleCreateNewInstance = async (
    device: Device,
    documents: Document[],
    user: User
  ) => {
    setIsCreatingNewInstance(true);
    await docEngine.navigateToWizardOrQMS({
      templateId,
      createNewMultiInstanceDoc: true,
      device,
      documents,
      user,
    });

    setIsCreatingNewInstance(false);
  };

  const docTemplate = getDocTemplate(templateId);

  if (
    !selectedDocVersion ||
    !selectedDoc ||
    !docInstances ||
    !user ||
    isGeneratingDoc ||
    !documents ||
    !device ||
    !docTemplate ||
    !signaturesAndHistory
  ) {
    return <DocumentViewerSkeleton />;
  }

  const docWithSignatureAndHistory = (docText || "")
    .concat("\n")
    .concat(signaturesAndHistory);

  const isDeprecated = docTemplate.deprecationConfig?.isDeprecated;

  return (
    <div className=" flex w-full flex-1 flex-col gap-y-2">
      <DocViewerTopBar
        docProps={{
          docs: docInstances,
          isCreatingNewInstance: isCreatingNewInstance,
          isEditingRawDocument: isEditingRawDocument,
          loadingNewDocVersion: selectLoading,
          onCreateDocInstance: () =>
            handleCreateNewInstance(device, documents, user),
          onDocInfoButtonClick: onDrawerToggle,
          onEditRawDocument: () => setIsEditingRawDocument(true),
          onCancelEditRawDocument: () => setIsEditingRawDocument(false),
          onSaveRawDocument: () =>
            handleSaveRawDocument(
              deviceId,
              selectedDoc.id,
              selectedDocVersion.id
            ),
          onSelectDocInstance: (doc) => {
            selectDocument(doc);
            setGeneratedDoc(undefined);
          },
          onSelectDocVersion: selectDocumentVersion,
          selectedDoc: selectedDoc,
          selectedVersion: selectedDocVersion,
          hasOutstandingAction: outstandingAction.shouldShow,
          generatedDoc: docWithSignatureAndHistory,
          isDocEnabled: docIsUnlocked,
          isDocumentBeingEdited: isDocumentIsBeingEdited(device, documents),
          onEditClick: (docVersion) => {
            handleCreateNewVersion(docVersion, device, documents, user);
            setGeneratedDoc(undefined);
            if (docInfoDrawerOpen) {
              outstandingAction.reset();
            }
          },
        }}
      />

      {docText && (
        <>
          <div className="rounded-md border border-solid border-slate-300 p-4 relative">
            {isDeprecated && (
              <Alert severity="warning" className="-mt-4 -mx-4">
                {docTemplate.deprecationConfig?.deprecationMessage}
                {docTemplate.deprecationConfig?.linkToNewTemplate && (
                  <>
                    {" "}
                    <Link
                      to={docTemplate.deprecationConfig?.linkToNewTemplate(
                        orgId,
                        deviceId
                      )}
                    >
                      {docTemplate.deprecationConfig?.linkText}
                    </Link>
                  </>
                )}
              </Alert>
            )}
            {isEditingRawDocument ? (
              <TextField
                multiline
                inputRef={rawEditRef}
                defaultValue={docText}
                sx={{
                  ".MuiInputBase-root": {
                    padding: 0,
                    backgroundColor: "transparent",
                    borderWidth: "0px !important",
                  },
                }}
                className="w-full"
              />
            ) : (
              <MarkdownPreview
                source={docWithSignatureAndHistory}
                wrapperElement={{ "data-color-mode": "light" }}
                className="text-base"
                // Disable links in headings
                rehypeRewrite={(node, _, parent) => {
                  if (
                    // @ts-ignore
                    node.tagName === "a" &&
                    parent &&
                    // @ts-ignore
                    /^h(1|2|3|4|5|6)/.test(parent.tagName)
                  ) {
                    parent.children = parent.children.slice(1);
                  }
                }}
              />
            )}

            {!docIsUnlocked && <DocLockedOverlay />}
          </div>
          <DocInfoDrawer
            selectedDoc={selectedDoc}
            isOpen={docInfoDrawerOpen}
            onDrawerClose={onDrawerToggle}
            onDrawerOpen={onDrawerToggle}
            selectedDocVersion={selectedDocVersion}
            deviceId={deviceId}
            generatedDoc={docText}
            templateId={templateId}
          />
        </>
      )}

      {isDocumentIsBeingEdited(device, documents) && (
        <div className="flex flex-1 justify-center">
          <NoDocAnimation
            type="EditableDoc"
            buttonOnClick={async () => {
              await docEngine.navigateToWizardOrQMS({
                templateId,
                documentId: selectedDoc.id,
                device,
                documents,
                user,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};
