import { ASSISTANT_CONFIG, config as TemplateAssistantConfig } from "@config";
import {
  getDocumentsAsync,
  useCreateDocumentMutation,
  useCreateDocumentVersionMutation,
  useGetAssistantProcesses,
  useGetDevice,
  useGetDocuments,
  useGetUser,
  usePatchDocumentVersionMutation,
  useSaveAnswerMutation,
  useSaveSuggestion,
  useUpdateAssistantProcessMutation,
} from "@hooks";
import {
  AssistantMultiStepFormAnswer,
  AssistantProcess,
  AssistantProcessStatus,
  Document,
  DocumentVersion,
} from "@models";
import { NoteAddOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import {
  DeviceDocumentChangeAnswer,
  DocumentDataKey,
  DocumentStatus,
  TemplateAssistant,
} from "@types";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FinishProcess } from "src/components/Assistant/FinishProcess";
import { ListCard } from "src/components/Assistant/ListCard";
import { LoadingNewDocs } from "src/components/Assistant/LoadingNewDocs";
import { NavigationButtons } from "src/components/Assistant/NavigationButtons";
import { TEMPLATE_TYPE } from "src/stores/models/template-document";
import { getDocTypeStatus } from "src/utils/documents";

export const DocumentUpdatedList = () => {
  const { data: user } = useGetUser();
  const { orgId = "", deviceId = "" } = useParams();
  const { data: documents } = useGetDocuments(orgId, deviceId);
  const { data: device } = useGetDevice({ orgId, deviceId });
  const { data: assistantProcesses } = useGetAssistantProcesses({
    orgId,
    deviceId,
    status: AssistantProcessStatus.IN_PROGRESS,
  });
  const { mutateAsync: updateAssistantProcess } =
    useUpdateAssistantProcessMutation(orgId, deviceId);
  const { mutateAsync: createDocument } = useCreateDocumentMutation();

  const [assistantProcess, setAssistantProcess] =
    useState<AssistantProcess | null>(null);

  useEffect(() => {
    const process = assistantProcesses?.[0];
    if (!assistantProcess && process) {
      setAssistantProcess(process);
    }
  }, [assistantProcesses]);

  const createDocumentVersionMutation = useCreateDocumentVersionMutation(
    orgId,
    deviceId
  );
  const patchDocumentMutation = usePatchDocumentVersionMutation();
  const saveAnswerMutation = useSaveAnswerMutation();
  const saveSuggestion = useSaveSuggestion();

  const [isGenerating, setIsGenerating] = useState<
    "loading" | "success" | "error" | "idle"
  >("idle");

  if (!user || !documents || !device || !assistantProcess) return null;

  const saveSuggestions = async ({
    changes,
    templateConfig,
    document,
    documentVersion,
  }: {
    changes: DeviceDocumentChangeAnswer[];
    templateConfig: TemplateAssistant;
    document: Document;
    documentVersion: DocumentVersion;
  }) => {
    changes.map(async (change) => {
      const answerElementConfig = templateConfig.elements.find(
        (element) => element.id === change.dataKeyId
      );

      const suggestion = change.suggestion?.value;

      if (suggestion && answerElementConfig) {
        return saveSuggestion.mutateAsync({
          orgId,
          suggestion,
          documentVersion,
          device,
          docId: document.id,
          step: answerElementConfig,
        });
      }
    });
  };

  const createDocumentInstance = (
    templateId: TEMPLATE_TYPE
  ): Promise<Document> => {
    return createDocument({
      orgId,
      templateId: templateId,
      deviceId,
    });
  };

  const createDocumentVersion = async (
    templateId: TEMPLATE_TYPE,
    documents: Document[]
  ) => {
    const document = orderBy(
      documents.filter((doc) => doc.name === templateId),
      "createdAt",
      "desc"
    )[0];

    if (document) {
      return await createDocumentVersionMutation.mutateAsync({
        docId: document.id,
      });
    }
  };

  const generateDocument = async (
    document: Document,
    documentVersion: DocumentVersion
  ) => {
    //   Update the document version status to completed
    return await patchDocumentMutation.mutateAsync({
      orgId,
      docId: document.id,
      deviceId: deviceId,
      docVersionId: documentVersion.id,
      data: { readyForApproval: true },
    });
  };

  const saveAnswers = async (
    changes: DeviceDocumentChangeAnswer[],
    templateConfig: TemplateAssistant,
    document: Document,
    documentVersion: DocumentVersion
  ) => {
    return await Promise.all(
      changes.map(async (change) => {
        const answerElementConfig = templateConfig.elements.find(
          (element) => element.id === change.dataKeyId
        );

        if (
          answerElementConfig &&
          answerElementConfig.element.type === "fileUpload" &&
          change.acceptedAnswer?.answerFileId
        ) {
          return saveAnswerMutation.mutateAsync({
            orgId,
            step: answerElementConfig,
            documentVersion,
            deviceId,
            docId: document.id,
            answerFileId: change.acceptedAnswer.answerFileId,
          });
        }

        if (
          answerElementConfig &&
          answerElementConfig.element.type === "answerItemsElement" &&
          change.acceptedAnswer?.answerItems
        ) {
          // TODO handle answer items by creating them calling /items and only save new ones
          // return await handleSaveAnswer({
          //   newStepValue: {
          //     answerItems: change.acceptedAnswer.answerItems,
          //   },
          //   step: templateAnswer,
          //   documentVersion: docVersion,
          //   docId: document.id,
          // });
        }

        if (
          answerElementConfig &&
          answerElementConfig.element.type !== "fileUpload" &&
          answerElementConfig.element.type !== "answerItemsElement" &&
          change.acceptedAnswer?.answer
        ) {
          console.log("Saving answer", {
            answer: change.acceptedAnswer.answer,
            answerElementConfig,
            documentVersion,
            document,
          });
          return saveAnswerMutation.mutateAsync({
            orgId,
            step: answerElementConfig,
            documentVersion,
            deviceId,
            docId: document.id,
            answer: change.acceptedAnswer.answer,
          });
        }
      })
    );
  };

  const handleGenerateDocs = async () => {
    setIsGenerating("loading");

    const templateIds = assistantProcess.state.changes.map(
      (change) => change.templateId
    ) as TEMPLATE_TYPE[];

    const uniqueTemplateIds = [...new Set(templateIds)];

    //separate the answers by templateIds
    const answersByTemplateId = assistantProcess.state.changes.reduce<
      Record<TEMPLATE_TYPE, AssistantMultiStepFormAnswer[]>
    >(
      (acc, change) => {
        const templateId = change.templateId as TEMPLATE_TYPE;
        if (!acc[templateId]) {
          acc[templateId] = [];
        }
        acc[templateId].push(change);
        return acc;
      },
      {} as Record<TEMPLATE_TYPE, AssistantMultiStepFormAnswer[]>
    );

    console.log("answersByTemplateId", answersByTemplateId);

    // We do it this weird way cause this causes cancellation errors https://github.com/TanStack/query/issues/8060 otherwise and the suggestions in the GH issue do not work
    // First we create the document instances if  needed
    await Promise.all(
      uniqueTemplateIds.map(async (templateId) => {
        if (TemplateAssistantConfig[templateId].docType === "RCD") {
          await createDocumentInstance(templateId);
        }
      })
    );

    // refetch the documents
    const refreshedDocuments = await getDocumentsAsync(orgId, deviceId);

    // now we create the new document versions
    const templateIdsWithDocumentVersions = await Promise.all(
      uniqueTemplateIds.map(async (templateId) => {
        return {
          templateId,
          newDocumentVersion: await createDocumentVersion(
            templateId,
            refreshedDocuments
          ),
        };
      })
    );

    // Then for each version we save the suggestions and answers
    await Promise.all(
      templateIdsWithDocumentVersions.map(
        async ({ templateId, newDocumentVersion }) => {
          if (!newDocumentVersion) {
            throw new Error("Document version not returned.");
          }

          const templateElement = TemplateAssistantConfig[templateId];

          const document = orderBy(
            refreshedDocuments?.filter((doc) => doc.name === templateId),
            "createdAt",
            "desc"
          )[0];

          await saveSuggestions({
            changes: answersByTemplateId[templateId].map((answer) => ({
              ...answer,
              dataKeyId: answer.dataKeyId as DocumentDataKey,
              templateId: answer.templateId as TEMPLATE_TYPE,
            })),
            templateConfig: templateElement,
            document,
            documentVersion: newDocumentVersion,
          });

          await saveAnswers(
            answersByTemplateId[templateId].map((answer) => ({
              ...answer,
              dataKeyId: answer.dataKeyId as DocumentDataKey,
              templateId: answer.templateId as TEMPLATE_TYPE,
            })),
            templateElement,
            document,
            newDocumentVersion
          );

          await generateDocument(document, newDocumentVersion);
        }
      )
    );

    setIsGenerating("success");

    updateAssistantProcess({
      processId: assistantProcess.id,
      body: {
        status: AssistantProcessStatus.COMPLETED,
      },
    });
  };

  const templateIds = assistantProcess.state.changes.map(
    (change) => change.templateId
  );

  const uniqueTemplateIds = [...new Set(templateIds)];

  if (isGenerating === "loading") {
    return <LoadingNewDocs />;
  }

  if (isGenerating === "success") {
    return <FinishProcess />;
  }

  return (
    <div className="flex flex-1 flex-col gap-y-4">
      <Typography variant="body1">Document Update Managment</Typography>
      <div className="flex flex-col">
        <Typography variant="h1" className="flex items-center gap-2">
          Please have all the below documents approved to complete your change
          management.
        </Typography>
      </div>
      <div className="flex flex-col gap-y-3 my-4">
        {uniqueTemplateIds.map((templateId) => {
          const templateConfig = ASSISTANT_CONFIG[templateId as TEMPLATE_TYPE];

          const docStatus = getDocTypeStatus({
            documents,
            templateId: templateId as TEMPLATE_TYPE,
            user,
            ignoreEntitlement: true,
            orgId,
            device,
          });

          return (
            <ListCard
              key={templateConfig.docName}
              text={templateConfig.docName}
              StartIcon={NoteAddOutlined}
              fullWidth
              justifyContent="flex-start"
              cursor="default"
              disabled={docStatus === DocumentStatus.LOCKED}
            />
          );
        })}
      </div>
      <NavigationButtons
        rightButton={{
          text: "Generate Documents",
          onClick: handleGenerateDocs,
        }}
        leftButton={{
          hasConfirmationModal: true,
        }}
      />
    </div>
  );
};
