import { SvgIconComponent } from "@mui/icons-material";
import { Card } from "@mui/material";

export const ListCard = ({
  text,
  EndIcon,
  StartIcon,
  color = "rgba(0, 0, 0, 0.87)",
  onClick,
  fullWidth = false,
}: {
  text: string;
  EndIcon?: SvgIconComponent;
  StartIcon?: SvgIconComponent;
  color?: string;
  onClick?: () => void;
  fullWidth?: boolean;
}) => {
  return (
    <Card
      component="button"
      onClick={onClick}
      tabIndex={0}
      aria-label={text}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
        border: `1px solid ${color}`,
        boxShadow: "none",
        padding: "10px",
        backgroundColor: "transparent",
        cursor: "pointer",
        width: fullWidth ? "100%" : "fit-content",
        "& #end-icon": {
          transition: "all 0.3s ease-in-out",
          marginRight: "5px",
        },
        "&:hover #end-icon": {
          transform: "translateX(5px)",
        },
      }}
    >
      {StartIcon && <StartIcon color="inherit" id="start-icon" />}
      {text}
      {EndIcon && <EndIcon color="inherit" id="end-icon" />}
    </Card>
  );
};
