import { ASSISTANT_CONFIG, ROUTES } from "@config";
import {
  useDocEngine,
  useGetAssistantProcesses,
  useGetDevice,
  useGetDocuments,
  useGetUser,
  useUpdateAssistantProcessMutation,
} from "@hooks";
import { AssistantProcess, AssistantProcessStatus } from "@models";
import {
  EditOutlined,
  NoteAddOutlined,
  TrendingFlat,
} from "@mui/icons-material";
import { Alert, Typography } from "@mui/material";
import { DocumentStatus } from "@types";
import { fillRouteWithSlugs } from "@utils";
import { Flower } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ListCard } from "src/components/Assistant/ListCard";
import { LoadingDocList } from "src/components/Assistant/LoadingDocList";
import { NavigationButtons } from "src/components/Assistant/NavigationButtons";
import { NoDocsToUpdate } from "src/components/Assistant/NoDocsToUpdate";
import { config as templateAssistantConfig } from "src/config/assistant/doc-config";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import { TEMPLATE_TYPE } from "src/stores/models/template-document";
import {
  getDocTypeStatus,
  getIncompleteDependenciesRecursively,
} from "src/utils/documents";
export const DocumentUpdateList = () => {
  const { data: user } = useGetUser();
  const { orgId = "", deviceId = "" } = useParams();
  const { data: documents } = useGetDocuments(orgId, deviceId);
  const { data: device } = useGetDevice({ orgId, deviceId });
  const docEngine = useDocEngine(deviceId);
  const { data: assistantProcesses } = useGetAssistantProcesses({
    orgId,
    deviceId,
    status: AssistantProcessStatus.IN_PROGRESS,
  });
  const { mutate: updateAssistantProcess } = useUpdateAssistantProcessMutation(
    orgId,
    deviceId
  );

  const navigate = useNavigate();

  const [assistantProcess, setAssistantProcess] =
    useState<AssistantProcess | null>(null);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const process = assistantProcesses?.[0];
    if (!assistantProcess && process) {
      setAssistantProcess(process);
    }
  }, [assistantProcesses]);

  // Show the loading screen for at least3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (showLoading || !assistantProcess || !documents || !user || !device) {
    return <LoadingDocList />;
  }

  const dataKeysToUpdate = assistantProcess.state.dataKeysToUpdate;

  if (!dataKeysToUpdate || dataKeysToUpdate.length === 0) {
    return <NoDocsToUpdate />;
  }

  let templatesIdsList: TEMPLATE_TYPE[] = [];
  let incompleteDocsList: TEMPLATE_TYPE[] = [];

  dataKeysToUpdate.forEach((dataKeyToUpdate) => {
    const docStatus = getDocTypeStatus({
      documents,
      templateId: dataKeyToUpdate.templateId as TEMPLATE_TYPE,
      user,
      ignoreEntitlement: true,
      orgId,
      device,
    });

    const templateConfig =
      templateAssistantConfig[dataKeyToUpdate.templateId as TEMPLATE_TYPE];
    const isDraft = docStatus === DocumentStatus.DRAFT;
    const isRcd = templateConfig.docType === "RCD";

    const shouldAddToTemplatesList = true;

    if (shouldAddToTemplatesList) {
      templatesIdsList.push(dataKeyToUpdate.templateId as TEMPLATE_TYPE);
    }

    const shouldAddToIncompleteList = isDraft && !isRcd;
    if (shouldAddToIncompleteList) {
      incompleteDocsList.push(dataKeyToUpdate.templateId as TEMPLATE_TYPE);
    }

    const incompleteDocuments = getIncompleteDependenciesRecursively(
      dataKeyToUpdate.templateId as TEMPLATE_TYPE,
      documents,
      user,
      orgId,
      device
    );

    incompleteDocsList = [
      ...new Set([...incompleteDocsList, ...incompleteDocuments]),
    ];
  });

  const hasIncompleteDocuments = incompleteDocsList.length > 0;

  return (
    <div className="flex flex-1 flex-col gap-y-6">
      <div className="flex flex-col gap-y-3">
        <Typography variant="h1" className="flex items-center gap-2">
          Assistant <Flower className="text-purple-500" /> - Document Update
        </Typography>
        <Typography>
          {hasIncompleteDocuments
            ? "You need to complete these documents before you can start:"
            : "Based on your input those are the documents that need to be updated:"}
        </Typography>
      </div>
      <div className="flex flex-col gap-y-4">
        {hasIncompleteDocuments ? (
          <>
            <Alert severity="warning">
              Please complete or delete the drafts for the following documents
              before you can start:
            </Alert>
            <div className="flex flex-col gap-y-2">
              {incompleteDocsList.map((incompleteId) => {
                return (
                  <ListCard
                    key={incompleteId}
                    text={ASSISTANT_CONFIG[incompleteId].docName}
                    color="rgb(237, 108, 2)"
                    iconColor="warning"
                    StartIcon={EditOutlined}
                    EndIcon={TrendingFlat}
                    fullWidth
                    onClick={async () =>
                      await docEngine.navigateToWizardOrQMS({
                        templateId: incompleteId,
                        device,
                        documents,
                        user,
                      })
                    }
                  />
                );
              })}
            </div>
          </>
        ) : (
          <>
            <Alert severity="warning">
              By going through the process we will help you adjust necessary
              information and create new versions of the following documents.
            </Alert>
            <div className="flex flex-col gap-y-2">
              {[...new Set(templatesIdsList)].map((templateId) => {
                const templateConfig =
                  ASSISTANT_CONFIG[templateId as TEMPLATE_TYPE];

                const docStatus = getDocTypeStatus({
                  documents,
                  templateId: templateId as TEMPLATE_TYPE,
                  user,
                  ignoreEntitlement: true,
                  orgId,
                  device,
                });

                return (
                  <ListCard
                    key={templateConfig.docName}
                    text={templateConfig.docName}
                    StartIcon={NoteAddOutlined}
                    fullWidth
                    justifyContent="flex-start"
                    cursor="default"
                    disabled={docStatus === DocumentStatus.LOCKED}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
      <NavigationButtons
        rightButton={{
          disabled: hasIncompleteDocuments,
          onClick: () => {
            if (assistantProcess) {
              const updatedState: AssistantProcess["state"] = {
                ...assistantProcess.state,
                currentPath: ROUTES.ASSISTANT_DOCUMENT_CHANGE,
                changes: assistantProcess.state.changes.map((change) => ({
                  ...change,
                  dataKeyId: change.dataKeyId,
                })),
              };

              updateAssistantProcess({
                processId: assistantProcess.id,
                body: {
                  state: updatedState,
                },
              });
            }

            navigate(
              fillRouteWithSlugs(ROUTES.ASSISTANT_DOCUMENT_CHANGE, {
                [ROUTE_SLUGS.ORG_ID]: orgId,
                [ROUTE_SLUGS.DEVICE_ID]: deviceId,
              })
            );
          },
        }}
        leftButton={{
          hasConfirmationModal: true,
        }}
      />
    </div>
  );
};
