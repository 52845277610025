import { RoadmapTaskConfig } from "@config";
import { Device, Document } from "@models";
import { DataKeyWithValue } from "@types";

export const mapDocumentVersionsToDataKeys = (
  documents: Document[]
): DataKeyWithValue[] => {
  return documents
    .map((doc) => {
      if (!doc.versions[0]) return [];

      return doc.versions[0].answers.map((answer) => ({
        id: answer.element,
        value: answer.answer,
      }));
    })
    .flat();
};

export const isTaskVisible = (
  task: RoadmapTaskConfig,
  documents: Document[],
  device: Device
) => {
  // No visibility condition means the task is visible
  if (!task.visibilityCondition) {
    return true;
  }

  const availableDataKeys = mapDocumentVersionsToDataKeys(documents);

  return task.visibilityCondition.isVisible(availableDataKeys, device);
};
