import { ASSISTANT_CONFIG, DEVICE_CHARACTERISTICS_STATEMENTS } from "@config";
import {
  AssistantProcess,
  Device,
  Document,
  DocumentAnswer,
  DocumentAnswerType,
  DocumentVersion,
  SSEMessage,
  TEMPLATE_TYPE,
} from "@models";
import {
  DataKeyWithDefinedValue,
  DocumentDataKey,
  StepValue,
  TemplateElement,
} from "@types";
import {
  getAnswerType,
  getDocConfigByElementId,
  getElementConfigByDataKey,
  hasHardware,
  hasSoftware,
  isSoftwareOnly,
} from "@utils";
import { SSEStream } from "./sse";

export function gatherRelevantDataKeys({
  elementId,
  documents,
  documentVersion,
  process,
}: {
  elementId: DocumentDataKey;
  documents: Document[];
  documentVersion?: DocumentVersion;
  process?: AssistantProcess;
}): DataKeyWithDefinedValue[] {
  const suggestionType = process ? "change-suggestion" : "template-suggestion";

  const context: DataKeyWithDefinedValue[] = [];

  const contextFromDataKey = getContextForDataKey(
    elementId,
    documents,
    documentVersion
  );

  context.push(...contextFromDataKey);

  // if it is a change suggestion we also need to add the current answer for the element

  // if we don't have a previous answer our type is template suggestion otherwise it is change suggestion
  if (suggestionType === "change-suggestion") {
    if (!process) {
      throw new Error("Process is required for change suggestion");
    }

    const currentAnswer = process.state.changes.find(
      (c) => c.dataKeyId === elementId
    );

    if (!currentAnswer || !currentAnswer.previousAnswer) {
      throw new Error(
        "The current answer's previousAnswer is required for change suggestion"
      );
    }

    context.push({
      id: elementId,
      value: getAnswerFormattedForContext(
        elementId,
        currentAnswer.previousAnswer
      ),
    });

    // include all elements from relevant documents (BUG_FIX_REPORT, CHANGE_REQUEST)
    const relevantElements = ASSISTANT_CONFIG[
      TEMPLATE_TYPE.BUG_FIX_REPORT
    ].elements.concat(ASSISTANT_CONFIG[TEMPLATE_TYPE.CHANGE_REQUEST].elements);

    process.state.changes.forEach((change) => {
      const relevantElement = relevantElements.find(
        (e) => e.id === change.dataKeyId
      );
      if (relevantElement) {
        if (!change.acceptedAnswer || !change.previousAnswer) {
          throw new Error(
            "The current answer's acceptedAnswer and previousAnswer are required for change suggestion"
          );
        }
        context.push({
          id: relevantElement.id,
          value: getAnswerFormattedForContext(
            relevantElement.id,
            change.acceptedAnswer || change.previousAnswer
          ),
        });
      }
    });
  }

  return context;
}

export function getChangeInformation(process: AssistantProcess): string {
  return process.state.description + "\n" + process.state.checkboxes.join("\n");
}

export function generateSuggestion({
  elementId,
  device,
  documents,
  documentVersion,
  mock,
  process,
}: {
  elementId: DocumentDataKey;
  device: Device;
  documents: Document[];
  documentVersion?: DocumentVersion;
  mock?: boolean;
  process?: AssistantProcess;
}): AsyncGenerator<SSEMessage> {
  const element = getElementConfigByDataKey(elementId);
  const docConfig = getDocConfigByElementId(elementId);

  if (mock) {
    const elementType = element.element.type;
    return mockSuggestionGenerator(elementType);
  }

  if (!documentVersion && docConfig.docType !== "RCD") {
    throw new Error("A documentVersion is required for non RCD documents");
  }

  const suggestionType = process ? "change-suggestion" : "template-suggestion";

  const dataKeysContext = gatherRelevantDataKeys({
    elementId,
    documents,
    documentVersion,
    process,
  });

  const isChangeSuggestion = suggestionType === "change-suggestion";

  const endpoint = isChangeSuggestion
    ? "/change-suggestion"
    : "/template-suggestion/v2";

  if (isChangeSuggestion && !process) {
    throw new Error("Process is required for change suggestion");
  }

  return SSEStream(endpoint, {
    characteristics: deviceCharacteristics(device),
    device_name: device.name,
    device_description: device.description,
    additional_context: dataKeysContext,
    components: device.components,
    ...(isChangeSuggestion &&
      process && {
        change_information: getChangeInformation(process),
      }),
    context_files: [],
    step: {
      question: element.element.options.label,
      description: element.element.options.helperText || "",
      default_value: element.element.options.default || "",
      id: element.id,
    },
  });
}

function deviceCharacteristics(device: Device): string[] {
  const statements = [];
  if (hasHardware(device))
    statements.push(DEVICE_CHARACTERISTICS_STATEMENTS.hasHardware);
  if (hasSoftware(device) && !isSoftwareOnly(device))
    statements.push(DEVICE_CHARACTERISTICS_STATEMENTS.hasSoftware);
  if (isSoftwareOnly(device))
    statements.push(DEVICE_CHARACTERISTICS_STATEMENTS.isSoftwareOnly);
  return statements;
}

function getContextForDataKey(
  dataKey: DocumentDataKey,
  documents: Document[],
  documentVersion?: DocumentVersion
): DataKeyWithDefinedValue[] {
  const element = getElementConfigByDataKey(dataKey);
  const context: DataKeyWithDefinedValue[] = [];

  // Get all answers from the documentVersion and all other documents
  const answers: DocumentAnswer[] = [];

  answers.push(
    ...documents
      .filter((d) => d.versions.length > 0)
      .map((d) => d.versions[0])
      // Filter out the current document and documents without versions
      .filter((v) => v.id !== documentVersion?.id)
      .map((v) => v.answers)
      .flat()
  );

  if (documentVersion) {
    answers.push(...documentVersion.answers);
  }

  // If the context specifies elements that are not in the current document we search for them in the other documents
  answers.forEach((a) => {
    if (element.context?.includes(a.element)) {
      context.push({
        id: a.element,
        value: getAnswerFormattedForContext(a.element, a),
      });
    }
  });

  if (element.prePromptTransformerConfig) {
    const adjustedDataKeys = runPrePromptTransformer(element, answers);
    adjustedDataKeys.forEach(({ id, value }) => {
      context.push({
        id,
        value,
      });
    });
  }

  return context;
}

export const getAnswerFormattedForContext = (
  elementId: DocumentDataKey,
  answer: StepValue
): string => {
  const elementConfig = getElementConfigByDataKey(elementId);
  const { element } = elementConfig;

  const answerType = getAnswerType(elementId);
  let formattedAnswer = "";
  if (answerType === DocumentAnswerType.LIST) {
    // Answer list items always need a transformer since they are outputted as a table
    if (!("outputTransformer" in element.options)) {
      throw new Error(`Output transformer not found for element ${elementId}`);
    }

    if (element.type !== "answerItemsElement") {
      throw new Error(`Element ${elementId} is not an answerItemsElement`);
    }

    if (!Array.isArray(answer.answerItems) && elementConfig.required) {
      throw new Error(`Answer items are not an array for element ${elementId}`);
    }
    formattedAnswer = element.options.outputTransformer({
      answer: answer.answerItems || [],
      element: element,
    });
  } else if (answerType === DocumentAnswerType.FILE) {
    if (!answer.answerFileId && elementConfig.required) {
      throw new Error(`Answer file id is not present for element ${elementId}`);
    }
    formattedAnswer = answer.answerFileId || "";
  } else {
    if (!answer.answer && elementConfig.required) {
      throw new Error(`Answer is not present for element ${elementId}`);
    }
    formattedAnswer = answer.answer || "";
  }

  return formattedAnswer;
};

export const runPrePromptTransformer = (
  element: TemplateElement,
  answers: DocumentAnswer[]
): DataKeyWithDefinedValue[] => {
  if (!element.prePromptTransformerConfig) {
    throw new Error(
      `Pre prompt transformer config not found for element ${element.id}`
    );
  }
  // find the answer for the required input in all the document answers
  const inputs = answers
    .filter((a) =>
      element.prePromptTransformerConfig?.inputs.includes(a.element)
    )
    .map((a) => [a.element, a.answer]);

  if (
    !element.prePromptTransformerConfig.inputs.every((requiredInput) =>
      inputs.find(([dataKey]) => dataKey === requiredInput)
    )
  ) {
    throw new Error(
      "Could not find the saved answer for one of the required inputs of the prePromptTransformerConfig: " +
        JSON.stringify(inputs)
    );
  }
  // run the transformer
  const adjustedDataKeys = element.prePromptTransformerConfig.transformer(
    Object.fromEntries(inputs)
  );

  return adjustedDataKeys;
};

export async function* mockSuggestionGenerator(
  mock: TemplateElement["element"]["type"] = "textField"
): AsyncGenerator<SSEMessage> {
  try {
    if (mock === "textField") {
      // First 3 iterations with "mock"
      for (let i = 1; i <= 5; i++) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        yield {
          event: "message",
          id: "mock",
          data: {
            content: i.toString() + "\n",
            metadata: { langgraph_node: "__start__" },
          },
        };
      }

      // Next 4 iterations with "Reviewing changes"
      for (let i = 6; i <= 10; i++) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        yield {
          event: "message",
          id: "mock",
          data: {
            content: i.toString() + "\n",
            metadata: { langgraph_node: "Reviewing changes" },
          },
        };
      }

      // Next 4 iterations with "Applying suggestion"
      for (let i = 11; i <= 15; i++) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        yield {
          event: "message",
          id: "mock",
          data: {
            content: i.toString() + "\n",
            metadata: { langgraph_node: "Applying suggestion" },
          },
        };
      }

      for (let i = 16; i <= 20; i++) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        yield {
          event: "message",
          id: "mock",
          data: {
            content: i.toString() + "\n",
            metadata: { langgraph_node: "apply_changes" },
          },
        };
      }
    } else if (mock === "table") {
      // for (let i = 1; i <= 5; i++) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const tableReturn =
        "| Employee Name        | Start Date | Functional Group | Manager / Supervisor |\n| -------------------- | ---------- | ----------------- | --------------------- |\n|  John Doe             | 2024-01-01 | Quality           | Jane Doe              |\n|  Mary Smith           | 2024-02-15 | Engineering       | Bob Wilson            |\n|  Mary Smith  2          | 2024-02-15 | Engineering       | Bob Wilson            |";

      const tableLines = tableReturn.split("\n");
      for (const line of tableLines) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        yield {
          event: "message",
          id: "mock",
          data: {
            content: line + "\n",
            metadata: { langgraph_node: "apply_changes" },
          },
        };
      }
    } else if (mock === "parsedFileUploadElement") {
      // for (let i = 1; i <= 5; i++) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const parsedFileUploadElementReturn = `("software users" OR "application users" OR "digital health users" OR "health app users" OR "medical software users") AND ("software application" OR "digital health tool" OR "health management software" OR "medical app" OR "health tracking software") AND ("software usability" OR "software efficiency" OR "software effectiveness" OR "software reliability" OR "software safety") AND ("user satisfaction" OR "software performance" OR "software functionality" OR "software reliability" OR "software safety") AND ("humans"[MeSH Terms]) AND ("english"[Language]) AND ("2015/01/31"[PDAT] : "2025/01/31"[PDAT])`;
      yield {
        event: "message",
        id: "mock",
        data: {
          content: parsedFileUploadElementReturn + "\n",
          metadata: { langgraph_node: "apply_changes" },
        },
      };
      // }
    }
  } catch (error) {
    yield {
      event: "error",
      id: "mock",
      data: {
        content: JSON.stringify(error),
        metadata: {},
      },
    };
  }
}
