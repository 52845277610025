import { Crisp } from "crisp-sdk-web";
import { useEffect, useState } from "react";

export const useCrisp = () => {
  const [supportOpen, setSupportOpen] = useState(false);

  useEffect(() => {
    if (!supportOpen) {
      Crisp.chat.onChatClosed(() => {
        setSupportOpen(false);
        Crisp.chat.hide();
      });

      Crisp.chat.hide();
      Crisp.chat.close();
      // cleaup any prefilled messages
      Crisp.message.setMessageText("");
    } else {
      Crisp.chat.show();
      Crisp.chat.open();
    }
  }, [supportOpen, setSupportOpen]);

  return {
    supportOpen,
    setSupportOpen,
  };
};
