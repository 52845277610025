import type {} from "@mui/lab/themeAugmentation";
import { DocumentDataKey } from "./data-keys";
export type { DocumentDataKey };

import {
  //AssistantMultiStepFormAnswer,
  AssistantProcessStatus,
  Component,
  ComponentConfigurationType,
  ComponentType,
  Device,
  DocumentVersion,
  StatusMessageType,
  SubComponentConfigurationType,
  SubComponentType,
  TEMPLATE_TYPE,
} from "@models";
import { SvgIconComponent } from "@mui/icons-material";
import { GridColType } from "@mui/x-data-grid-premium";
import { ReactElement } from "react";

export type ConfigurationItem = {
  type: ComponentConfigurationType;
  // The reason we use a string we only store strings in the database even for boolean values
  value: string;
};

export type SubComponentConfigurationItem = {
  value: string;
  type: SubComponentConfigurationType;
};

// Base types for components and subcomponents
export type BaseComponent = {
  type: ComponentType;
  name: string;
  description: string;
  configuration: ConfigurationItem[];
};

export type BaseSubComponent = {
  type: SubComponentType;
  name: string;
  description: string;
  configuration: SubComponentConfigurationItem[];
};

// New component without UI state
export type NewComponent = BaseComponent & {
  subComponents: NewSubComponent[];
};

// New subcomponent without UI state
export type NewSubComponent = BaseSubComponent;

// Component with UI state
export type ComponentState = BaseComponent & {
  collapsed: boolean;
  id: number;
  subComponents: SubComponentState[];
};

// Subcomponent with UI state
export type SubComponentState = BaseSubComponent & {
  id: number;
};

// Device types
export type NewDevice = {
  name: string;
  description: string;
  isIVDR: boolean;
  isFDA: boolean;
  isMDR: boolean;
  components: NewComponent[];
};

export type NewDeviceUIState = {
  name: string;
  description: string;
  isIVDR: boolean;
  isFDA: boolean;
  isMDR: boolean;
  isEU: boolean;
  components: ComponentState[];
};

export type CreateDemoDeviceRequest = {
  deviceConfig: {
    name: string;
    description: string;
    isDemoDevice?: boolean;
    components: Array<{
      name: string;
      type: ComponentType;
      description: string;
      configuration?: Array<{
        type: ComponentConfigurationType;
        value: string;
      }>;
      subComponents?: Array<{
        name?: string;
        type: SubComponentType;
        description: string;
        configuration?: Array<{
          type: SubComponentConfigurationType;
          value: string;
        }>;
      }>;
    }>;
  };
  documentsConfig: Array<{
    name: string;
    version: {
      revisionSummary: string;
      answers: Array<{
        answer?: string;
        answerFileId?: string;
        element: string;
      }>;
      suggestions: Array<{
        suggestion: string;
        element: string;
      }>;
    };
  }>;
};

export type User = {
  id: string;
  onboarding: {
    callScheduled: boolean;
  };
  firstName: string;
  lastName: string;
  email: string;
  createdAt: Date;
  updatedAt: Date;
  organizationMemberships: Array<{
    organization: {
      id: string;
      name: string;
      subscription: {
        products: string[];
        status: string;
      } | null;
    };
  }>;
  userUiState: {
    id: string;
    welcomeSeen: boolean;
    supportTooltipSeen: boolean;
    nextTodoCardTooltipSeen: boolean;
    approvalTooltipSeen: boolean;
    demoCompleteModalSeen: boolean;
    createdAt: Date;
    updatedAt: Date;
    userId: string;
  };
};

export enum CardContentType {
  GENERATE_TECHFILE = "GENERATE_TECHFILE",
}

export type QueryableFormElement = TextFieldElement | RadioButtonElement;

export interface FilePreUpload extends File {
  isUploaded: boolean;
}

export type TemplateAssistant = {
  id: TEMPLATE_TYPE;
  hidden: boolean;
  functionalGroup: FunctionalGroup;
  docType: DocType;
  docName: string;
  userUpload?: boolean;
  dependencies?: Array<DocumentDataKey>;
  docReferences?: Array<TEMPLATE_TYPE>;
  elements: Array<TemplateElement>;
  deprecationConfig?: {
    isDeprecated: boolean;
    deprecationMessage: string;
    linkToNewTemplate: (orgId: string, deviceId: string) => string;
    linkText: string;
  };
};

export type DataKeyWithValue = {
  id: DocumentDataKey;
  value: string | undefined | null;
};

export type DataKeyWithDefinedValue = {
  id: DocumentDataKey;
  value: string;
};

export type DocType =
  | "RCD"
  | "SOP"
  | "TMP"
  | "LST"
  | "WIN"
  | "TCD"
  | "OTH"
  | "EXT";

export type TemplateAssistantConfig = {
  [key in TEMPLATE_TYPE]: TemplateAssistant;
};

export type TemplateFunctionalGroupMap = {
  [key in FunctionalGroup]?: TemplateAssistant[];
};

export type TemplateElement = {
  prompt?: boolean;
  visibilityCondition?: (
    availableDataKeys: DataKeyWithValue[],
    device: Device
  ) => boolean;
  required: boolean;
  id: DocumentDataKey;
  videoUrl?: string;
  dependencies?: DocumentDataKey[];
  element:
    | TextFieldElement
    | SelectElement
    | TableElement
    | ParsedFileUploadElement
    | FileUploadElement
    | AnswerItemsElement
    | DateElement;
  context?: DocumentDataKey[];
  prePromptTransformerConfig?: {
    inputs: DocumentDataKey[];
    transformer: (
      inputs: Record<DocumentDataKey, string>
    ) => Array<{ id: DocumentDataKey; value: string }>;
  };
};

export type DataReferenceElement = {
  entity: "Device" | "Form";
  path: string;
  id: DocumentDataKey;
};

export type DocumentMetadata = {
  createdAt: string;
  approvedAt: string;
  approvers: Array<{
    name: string;
    approvedAt: string;
    initials: string;
  }>;
  createdBy: string;
  initials: string;
  documentIdentifier: string;
  documentNumber: string;
  ["company-name"]: string;
  revision: number;
  revisions: Array<{
    createdAt: string;
    revision: number | null | undefined;
    notes: string;
  }>;
};
declare global {
  interface Window {
    _env_: Record<string, any>;
  }
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

export type Suggestion = {
  applied: boolean;
  value: string | null;
  completed: boolean;
  isStatusMessage: boolean;
  statusMessageType?: StatusMessageType;
  loading: boolean;
  error: boolean;
};

export type WorkflowState = {
  currentNode: string | undefined;
  isHiddenOutputNode: boolean;
  isLoading: boolean;
};

export type AnswerState = Partial<Record<DocumentDataKey, string | null>>;

export type EnabledDocStatus = {
  enabled: boolean;
  incompleteDocuments?: TEMPLATE_TYPE[];
};
export type DocProgress = Partial<EnabledDocStatus> & {
  docTemplate: TEMPLATE_TYPE;
  docId: string;
  functionalGroup: FunctionalGroup;
  complete: boolean;
  noInstance?: boolean;
  noVersion?: boolean;
  hidden?: boolean;
};
export type DocProgressMap = Partial<Record<TEMPLATE_TYPE, DocProgress>>;

export enum DocumentStatus {
  LOCKED = "LOCKED",
  AVAILABLE = "AVAILABLE",
  DRAFT = "DRAFT",
  UNAPPROVED = "UNAPPROVED",
  ARCHIVED = "ARCHIVED",
  LIVE = "LIVE",
}

export type DocStatus = (typeof DocumentStatus)[keyof typeof DocumentStatus];

export type DocVersionWithStatusAndRevision = {
  status: DocStatus;
  revision: number;
} & DocumentVersion;

export enum FunctionalGroup {
  TD = "TD",
  QA = "QA",
  UNGROUPED = "UNGROUPED",
  SW = "SW",
  CP = "CP",
  PD = "PD",
}

export type PatchDocumentVersionData = Partial<
  Pick<DocumentVersion, "readyForApproval" | "revisionSummary" | "snapshot">
>;

export enum ElementTypes {
  DropBox = "dropBox",
  BlueBox = "blueBox",
  TextField = "textField",
  RadioButton = "radioButton",
  TextElement = "text",
  SelectElement = "select",
  CustomElement = "custom",
  markdownTable = "markdownTable",
  table = "table",
  parsedFileUploadElement = "parsedFileUploadElement",
  fileUpload = "fileUpload",
  answerItemsElement = "answerItemsElement",
  dateElement = "dateInput",
}

export type ElementType = `${ElementTypes}`;

interface AbstractFormElement {
  // TODO remove string if we separated the form elements form the wizard elements
  id: DocumentDataKey | string;
  type: ElementType;
  assistantQuestion?: string;
  answerType?: "short" | "elaborate";
  options?: Record<string, any>;
  visibilityCondition?: {
    id: string;
    value: string | boolean | number;
    section?: string;
  };
  disabledCondition?: {
    id: string;
    value: string | boolean | number;
    section?: string;
  };
  transformerConfig?: {
    // default means that the transformer will only be called as long as the input is not empty. Always means that the transformer will always be called
    // TODO introduce a type that runs that allows making changes to the always type. Right now always means that that the transformer will always be called and thus overwriting any changes that the user has made
    type: "default"; // | "always";
    inputs: DocumentDataKey[];
    transformer: (
      inputs: Record<DocumentDataKey, string>,
      docReferences: Record<string, string>
    ) => string;
  };
}

export interface CustomElement extends AbstractFormElement {
  type: "custom";
  element: ReactElement;
}

export interface DropBoxElement extends AbstractFormElement {
  type: "dropBox";
  options: {
    fileTypes: string[];
    label: string;
    docOrImage?: "document" | "image" | "imageAndDocument";
  };
}

export interface BlueBoxElement extends AbstractFormElement {
  type: "blueBox";
  options: {
    text: string;
  };
}

export interface TextFieldElement extends AbstractFormElement {
  type: "textField";
  options: {
    type?: "text" | "number";
    label: string;
    rows?: number;
    helperText?: string;
    default?: string;
  };
}

export interface ParsedFileUploadElement extends AbstractFormElement {
  type: "parsedFileUploadElement";
  options: {
    label: string;
    rows?: number;
    helperText?: string;
    default?: string;
    allowedFileTypes?: string;
    onUpload: (
      file: File
    ) => Promise<{ type: "table" | "json"; result: string }>;
  };
}

export interface AnswerItemsElement extends AbstractFormElement {
  type: "answerItemsElement";
  options: {
    typeId: string;
    outputTransformer: ({
      answer,
      element,
    }: {
      answer: AnswerItem[];
      element: AnswerItemsElement;
    }) => string;
    label: string;
    helperText?: string;
    default?: string;
    fields: {
      required?: boolean;
      disabled?: boolean;
      readOnly?: boolean;
      transformerConfig?: {
        type: "default" | "always";
        inputs: string[];
        transformer: (inputs: Record<string, string>) => string | number;
      };
      fieldKey: string;
      label: string;
      element: ElementType;
      style?: React.CSSProperties;
      options?: Array<{
        value: string | boolean | number;
        label: string;
      }>;
      containerAlignment?: "row" | "column";
      rows?: number;
      default?: string;
      alwaysShown?: boolean;
      multiple?: boolean;
    }[];
  };
}

export interface FileUploadElement extends AbstractFormElement {
  type: "fileUpload";
  options: {
    label: string;
    helperText?: string;
    allowedFileTypes: string;
    default?: string;
  };
}

export interface TextElement extends AbstractFormElement {
  type: "text";
  options: {
    text: string;
  };
}

export interface DateElement extends AbstractFormElement {
  type: "dateInput";
  options: {
    label: string;
    helperText?: string;
    default?: string;
  };
}

export interface SelectElement extends AbstractFormElement {
  type: "select";
  options: {
    label: string;
    options?: string[];
    // Index of the default value in the options array
    default?: string;
    multiple?: boolean;
    helperText?: string;
  };
}

export interface MarkdownTableElement extends AbstractFormElement {
  type: "markdownTable";
  options: {
    default?: string;
    label: string;
    helperText?: string;
    // columns: string[];
    // rows: string[];
  };
}

export interface TableElement extends AbstractFormElement {
  type: "table";
  options: {
    columns: {
      name: string;

      options?: {
        flex?: number;
        type?: GridColType;
        maxWidth?: number;
        valueOptions?: string[];
        editable?: boolean;
      };
    }[];
    idColumn?: string;
    default?: string;
    label: string;
    helperText?: string;
    addRowEnabled?: boolean;
  };
}

export interface RadioButtonElement extends AbstractFormElement {
  type: "radioButton";
  options: {
    label: string;
    containerAlignment?: "row" | "column";
    options: Array<{
      value: string | boolean | number;
      label: string;
    }>;
    default?: string;
  };
}

export type Fields = { [key: string]: string };

export type AnswerItem = {
  type: string;
  id: string;
  organizationId: string;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
  archived: boolean;
  sequentialTypeNumber: number;
  fields: Fields;
};

export type StepValue = {
  answer?: string | null;
  answerFileId?: string | null;
  answerItems?: AnswerItem[] | null;
};

export type MultiStepFormInputElement =
  | TextFieldElement
  | SelectElement
  | MarkdownTableElement
  | TableElement
  | ParsedFileUploadElement
  | FileUploadElement
  | AnswerItemsElement
  | DateElement;

type BaseComponentConfigurationProps = {
  newDevice: NewDeviceUIState;
  removeProductComponent: (component: ComponentState) => void;
  addProductComponent: (type: ComponentType) => void;
  toggleProductComponentCollapse: (component: ComponentState) => void;
  updateProductComponent: (component: ComponentState) => void;
  updateComponentConfigurationValue: (
    component: ComponentState,
    configurationType: ComponentConfigurationType,
    value: string
  ) => void;
  getComponentConfigurationValue: (
    component: ComponentState,
    configurationType: ComponentConfigurationType
  ) => string | boolean | undefined;
};

export type SoftwareComponentConfigurationProps =
  BaseComponentConfigurationProps;

export type HardwareComponentConfigurationProps =
  BaseComponentConfigurationProps & {
    newDevice: NewDeviceUIState;
    addSubComponent: (
      type: SubComponentType,
      component: ComponentState,
      name: string
    ) => void;
    removeAllSubComponentsByType: (
      component: ComponentState,
      subComponentType: SubComponentType
    ) => void;
    updateSubComponent: (
      component: ComponentState,
      subComponent: SubComponentState
    ) => void;
    updateSubComponentConfigurationValue: (
      component: ComponentState,
      subComponent: SubComponentState,
      type: SubComponentConfigurationType,
      value: string
    ) => void;
    getSubComponentConfigurationValue: (
      subComponent: SubComponentState,
      configurationType: SubComponentConfigurationType
    ) => string | boolean | undefined;
  };

export type Leaf = {
  id: string;
  label: string;
  tooltipText: string;
  Icon: SvgIconComponent;
  color:
    | "disabled"
    | "inherit"
    | "action"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  link: string;
  highlightedText?: React.ReactNode;
  disabled?: boolean;
};

export type WizardTopBarLeftButton = {
  startIcon?: React.ReactNode;
  onClick: () => void;
  text: string;
};

export type DeviceDocumentChangeAnswer = {
  dataKeyId: DocumentDataKey;
  templateId: TEMPLATE_TYPE;
  previousAnswer: StepValue;
  acceptedAnswer?: StepValue;
  suggestion?: Suggestion;
};

export type ChangePlanItem = {
  dataKeyId: DocumentDataKey;
  templateId: TEMPLATE_TYPE;
  ignorePreviousAnswer?: boolean;
};

export type AssistantProcess = {
  id: string;
  type: string;
  state: {
    currentPath: string;
    changes: DeviceDocumentChangeAnswer[];
    [key: string]: any;
    dataKeysToUpdate: ChangePlanItem[];
  };
  organizationId: string;
  deviceId: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  status: AssistantProcessStatus;
};

export type SuggestionRequestBody = {
  characteristics: string[];
  device_name: string;
  device_description: string;
  additional_context: DataKeyWithValue[];
  options?: {
    batch_on?: string;
  };
  components: Component[];
  context_files: string[];
  step: {
    question: string;
    description: string;
    default_value: string | null;
    id: DocumentDataKey;
  };
};

/**
 * The state in the AssistantMultiStepForm is made up of an array of these.
 */
export type AssistantMultiStepFormAnswer = {
  dataKeyId: DocumentDataKey;
  templateId: TEMPLATE_TYPE;
  previousAnswer: StepValue;
  acceptedAnswer?: StepValue;
  suggestion?: Suggestion;
};

export type ChangeSuggestionRequestBody = {
  change_information: string;
} & SuggestionRequestBody;

export type AssistantMultiStepFormAnswerUpdate = Pick<
  AssistantMultiStepFormAnswer,
  "acceptedAnswer" | "suggestion"
>;

export type AssistantMultiStepFormStep = {
  dataKeyId: DocumentDataKey; // data key id of element to get answer from user
  templateId: TEMPLATE_TYPE; // template id of that element (potentially unnecessary as I can get on my own if need be)
  ignorePreviousAnswer?: boolean; // whether to ignore the previous answers for a doc (if there are any), and create a fresh answer (i.e for RCD docs are always fresh) (defaults to false)
};
