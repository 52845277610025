import { SvgIconComponent } from "@mui/icons-material";
import { Card } from "@mui/material";
import { CSSProperties } from "react";
import { theme } from "src/config/theme";

export const ListCard = ({
  text,
  EndIcon,
  StartIcon,
  color = "rgba(0, 0, 0, 0.87)",
  onClick,
  fullWidth = false,
  justifyContent = "space-between",
  cursor = "pointer",
  disabled = false,
  iconColor = "inherit",
}: {
  text: string;
  EndIcon?: SvgIconComponent;
  StartIcon?: SvgIconComponent;
  color?: string;
  onClick?: () => void;
  fullWidth?: boolean;
  justifyContent?: CSSProperties["justifyContent"];
  cursor?: CSSProperties["cursor"];
  disabled?: boolean;
  iconColor?:
    | "warning"
    | "success"
    | "primary"
    | "error"
    | "disabled"
    | "inherit";
}) => {
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: justifyContent,
        gap: "10px",
        border: `1px solid ${disabled ? theme.palette.grey[500] : color}`,
        boxShadow: "none",
        padding: "10px",
        backgroundColor: disabled ? theme.palette.grey[100] : "transparent",
        cursor: cursor,
        width: fullWidth ? "100%" : "fit-content",
        "& #end-icon": {
          transition: "all 0.3s ease-in-out",
          marginRight: "5px",
        },
        "&:hover #end-icon": {
          transform: "translateX(5px)",
        },
        color: disabled ? theme.palette.grey[600] : "inherit",
      }}
      onClick={onClick}
    >
      <div className="flex items-center gap-x-2">
        {StartIcon && <StartIcon color={iconColor} id="start-icon" />}
        {text}
      </div>
      {EndIcon && (
        <EndIcon color={disabled ? "disabled" : "inherit"} id="end-icon" />
      )}
    </Card>
  );
};
