import { InferType, object, ObjectSchema, string } from "yup";

export const SSEMessageSchema = object({
  event: string().required(),
  id: string(),
  data: object({
    metadata: object() as ObjectSchema<Record<string, any>>,
    content: string().nullable(),
  }).required(),
});

export type SSEMessage = InferType<typeof SSEMessageSchema>;

// Helper function to parse raw SSE text into structured message
export function parseSSEMessage(rawMessage: string): SSEMessage {
  const lines = rawMessage.split("\n");
  const message: Partial<SSEMessage> = {
    data: { metadata: {}, content: null },
  };

  for (const line of lines) {
    const [key, ...values] = line.split(":");
    const value = values.join(":").trim();

    if (key === "data") {
      try {
        message.data = JSON.parse(value);
      } catch {
        throw new Error("Invalid JSON in data field");
      }
    } else if (key === "event" || key === "id") {
      message[key] = value;
    }
  }

  return SSEMessageSchema.validateSync(message);
}
