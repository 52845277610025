import { ChevronRight } from "@mui/icons-material";
import { Button } from "@mui/material";
import { fillRouteWithSlugs } from "@utils";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmationModal } from "src/components/ConfirmationModal";
import { ROUTE_SLUGS, ROUTES } from "src/config/navigation/routes";
import { useUpdateAssistantProcessMutation } from "src/hooks/mutations";
import { useGetAssistantProcesses } from "src/hooks/queries";
import { AssistantProcessStatus } from "src/stores/models/assistant-process";

export const NavigationButtons = ({
  rightButton,
  leftButton,
}: {
  rightButton?: {
    text?: string;
    onClick?: () => void;
    disabled?: boolean;
    endIcon?: React.ReactNode;
  };
  leftButton?: {
    hasConfirmationModal?: boolean;
    confirmationText?: string;
    confirmationTitle?: string;
    text?: string;
    onClick?: () => void;
    disabled?: boolean;
    endIcon?: React.ReactNode;
  };
}) => {
  const { deviceId = "", orgId = "" } = useParams();
  const navigate = useNavigate();

  const { data: assistantProcesses } = useGetAssistantProcesses({
    orgId,
    deviceId,
    status: AssistantProcessStatus.IN_PROGRESS,
  });
  const { mutate: updateAssistantProcess } = useUpdateAssistantProcessMutation(
    orgId,
    deviceId
  );

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleConfirmationClick = () => {
    setConfirmationOpen(true);
  };

  const handleConfirmationCancel = () => {
    setConfirmationOpen(false);
  };

  const handleLeftButtonClick = () => {
    if (leftButton?.onClick) {
      leftButton.onClick();
    } else {
      if (!assistantProcesses?.[0]?.id) return;
      updateAssistantProcess({
        processId: assistantProcesses?.[0]?.id,
        body: {
          status: AssistantProcessStatus.CANCELLED,
        },
      });
      navigate(
        fillRouteWithSlugs(ROUTES.ASSISTANT, {
          [ROUTE_SLUGS.ORG_ID]: orgId,
          [ROUTE_SLUGS.DEVICE_ID]: deviceId,
        })
      );
    }
  };

  const handleRightButtonClick = () => {
    if (rightButton?.onClick) {
      rightButton.onClick();
    } else {
      navigate(
        fillRouteWithSlugs(ROUTES.ASSISTANT_DOCUMENT_UPDATE_LIST, {
          [ROUTE_SLUGS.ORG_ID]: orgId,
          [ROUTE_SLUGS.DEVICE_ID]: deviceId,
        })
      );
    }
  };

  return (
    <div className="self-end flex gap-x-2">
      <Button
        onClick={() => {
          if (!leftButton?.hasConfirmationModal) {
            handleLeftButtonClick();
          } else {
            handleConfirmationClick();
          }
        }}
        variant="outlined"
      >
        {leftButton?.text || "Cancel"}
      </Button>
      <div className="flex items-center gap-2">
        {leftButton?.hasConfirmationModal && confirmationOpen && (
          <ConfirmationModal
            key="confirm"
            title={leftButton?.confirmationTitle || "Are you sure?"}
            confirmText="Confirm"
            content={
              leftButton?.confirmationText ||
              "By confirming all your progress will be lost."
            }
            onAbort={handleConfirmationCancel}
            onConfirm={handleLeftButtonClick}
            open={confirmationOpen}
          />
        )}
      </div>

      <Button
        data-testid="navigation-button-right"
        onClick={handleRightButtonClick}
        disabled={rightButton?.disabled || false}
        variant="contained"
        endIcon={rightButton?.endIcon || <ChevronRight />}
      >
        {rightButton?.text || "Next"}
      </Button>
    </div>
  );
};
