import { ChevronRight } from "@mui/icons-material";
import { Alert, AlertTitle, Button, Tab, Tabs } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { ElementMapper } from "src/components/Form/ElementMapper";
import { StepHeader } from "src/components/Form/StepHeader";
import { Suggestion } from "src/components/Form/Suggestion";
import IgnorePreviousAnswerStep from "src/components/FormStep/IgnorePreviousAnswerStep";
import { useSuggestion } from "src/components/FormStep/utils/useSuggestion";
import { isStepComplete } from "src/utils/step";
import { hasInlineSuggestion } from "src/utils/wizard";
import {
  AssistantMultiStepFormAnswer,
  AssistantProcess,
  Device,
  Document,
  DocumentVersion,
  TEMPLATE_TYPE,
} from "../../stores/models";
import {
  AssistantMultiStepFormAnswerUpdate,
  StepValue,
  Suggestion as TSuggestion,
  TemplateElement,
} from "../../types";

type Props = {
  templateId: TEMPLATE_TYPE;
  step: TemplateElement;
  documentVersion?: DocumentVersion;
  nextStep: () => void;
  answer: AssistantMultiStepFormAnswer;
  handleAnswerChange: (
    step: TemplateElement,
    value: AssistantMultiStepFormAnswerUpdate
  ) => void;
  documents: Document[];
  device: Device;
  assistantProcess: AssistantProcess;
  ignorePreviousAnswer?: boolean;
};

export const AssistantMultiStepFormStep = ({
  templateId,
  documentVersion,
  step,
  nextStep,
  answer,
  handleAnswerChange,
  documents,
  device,
  ignorePreviousAnswer,
  assistantProcess,
}: Props) => {
  const {
    suggestion: newSuggestion,
    refreshSuggestion,
    workflowState,
  } = useSuggestion({
    step,
    documentVersion,
    device,
    documents,
    savedSuggestion: answer.suggestion,
    assistantProcess,
    enabled: true,
  });

  const [tableTabIdx, setTableTabIdx] = useState(
    answer.acceptedAnswer === answer.previousAnswer ? 0 : 1
  );

  // Set suggestion on completion
  useEffect(() => {
    if (hasInlineSuggestion(step) && typeof newSuggestion.value === "string") {
      handleAnswerChange(step, {
        suggestion: newSuggestion,
        acceptedAnswer: {
          answer: newSuggestion.value,
          answerFileId: undefined,
          answerItems: undefined,
        },
      });
    }
    if (newSuggestion.completed) {
      handleAnswerChange(step, {
        suggestion: newSuggestion,
        acceptedAnswer: {
          answer: newSuggestion.value,
          answerFileId: undefined,
          answerItems: undefined,
        },
      });
    }
  }, [newSuggestion]);

  const handleKeepPreviousAnswer = () => {
    handleAnswerChange(step, {
      acceptedAnswer: answer.previousAnswer,
    });
    nextStep();
  };

  const handleSaveNewAnswer = (
    templateElement: TemplateElement,
    updates: {
      value?: StepValue;
      suggestion?: TSuggestion;
    },
    goNextStep?: boolean
  ) => {
    if (!updates) {
      return;
    }
    handleAnswerChange(templateElement, {
      acceptedAnswer: updates.value,
      ...(updates.suggestion && updates.suggestion.value
        ? { suggestion: updates.suggestion }
        : {}),
    });
    if (goNextStep) {
      nextStep();
    }
  };

  const handleClearAnswer = (templateElement: TemplateElement) => {
    handleAnswerChange(templateElement, {
      acceptedAnswer: undefined,
      suggestion: undefined,
    });
  };

  // This just makes sure there's an empty previous answer
  const existsPreviousTextAnswer = answer.previousAnswer.answer !== undefined;
  const isReadyForNextStep = useMemo(
    () =>
      answer.acceptedAnswer
        ? isStepComplete(step, answer.acceptedAnswer)
        : false,
    [step, answer.acceptedAnswer]
  );

  //TODO: onreruntransformers

  if (ignorePreviousAnswer || !step.prompt) {
    return (
      <IgnorePreviousAnswerStep
        step={step}
        handleAnswerChange={handleAnswerChange}
        nextStep={nextStep}
        templateId={templateId}
        device={device}
        documents={documents}
        answer={answer}
        assistantProcess={assistantProcess}
        isReadyForNextStep={isReadyForNextStep}
      />
    );
  }

  if (step.element.type === "parsedFileUploadElement") {
    return (
      <div key={step.id} className={`flex flex-1 flex-col gap-y-2`}>
        <StepHeader templateElement={step} />
        <div className="flex w-full flex-row gap-4">
          <Suggestion
            suggestion={newSuggestion}
            step={step}
            onRegenerateSuggestion={() => {
              refreshSuggestion();
            }}
            boxTitle="Suggestion"
            workflowState={workflowState}
          />
          <div className="flex flex-1 flex-col gap-y-4">
            <ElementMapper
              step={step}
              hasInlineSuggestion={hasInlineSuggestion(step)}
              stepValue={answer.acceptedAnswer || {}}
              documentVersion={documentVersion}
              onChange={(el, value) =>
                handleSaveNewAnswer(el, { value }, false)
              }
              templateId={templateId}
              title={"Answer"}
            />
            <div className="self-end">
              <Button
                onClick={() =>
                  handleSaveNewAnswer(
                    step,
                    {
                      value: answer.acceptedAnswer,
                    },
                    true
                  )
                }
                disabled={!isReadyForNextStep}
                variant={"contained"}
                endIcon={<ChevronRight />}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (step.element.type === "table") {
    const handleTableTabChange = (
      _: React.SyntheticEvent,
      newValue: number
    ) => {
      setTableTabIdx(newValue);
    };

    return (
      <div key={step.id} className={`flex flex-1 flex-col gap-y-4`}>
        <StepHeader templateElement={step} />
        <Tabs
          value={tableTabIdx}
          onChange={handleTableTabChange}
          aria-label="tabs"
          className="w-full border-gray-200 border-b-solid border-b-2"
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tab label="Previous Answer" className="normal-case" />
          <Tab label="Suggested Answer" className="normal-case" />
        </Tabs>
        <div className="flex flex-1 flex-col gap-y-4 w-full">
          {existsPreviousTextAnswer ? (
            <>
              {tableTabIdx === 0 ? (
                <>
                  <ElementMapper
                    step={step}
                    hasInlineSuggestion={hasInlineSuggestion(step)}
                    stepValue={answer.previousAnswer}
                    documentVersion={documentVersion}
                    templateId={templateId}
                    title={"Previous Answer"}
                  />
                  <div className="self-end">
                    <Button
                      onClick={handleKeepPreviousAnswer}
                      variant="outlined"
                      endIcon={<ChevronRight />}
                    >
                      Keep Previous Answer
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <ElementMapper
                    step={step}
                    hasInlineSuggestion={hasInlineSuggestion(step)}
                    suggestion={newSuggestion}
                    stepValue={
                      answer.acceptedAnswer &&
                      Object.keys(answer.acceptedAnswer || {}).length > 0
                        ? answer.acceptedAnswer
                        : {
                            answer: answer.suggestion?.value,
                          }
                    }
                    documentVersion={documentVersion}
                    templateId={templateId}
                    title={"Suggested Answer"}
                    onRegenerateSuggestion={() => {
                      console.log(
                        "regenerate suggestion from suggestion component"
                      );
                      handleClearAnswer(step);
                      refreshSuggestion();
                    }}
                    onChange={(el, value) =>
                      handleSaveNewAnswer(
                        el,
                        {
                          value,
                        },
                        false
                      )
                    }
                    onUndo={() => {}} //TODO: Implement undo
                    onRedo={() => {}} //TODO: Implement redo
                  />
                  <div className="self-end">
                    <Button
                      onClick={() => {
                        let newAcceptedAnswer = {};
                        if (answer.acceptedAnswer) {
                          // Editing an edited or previously accepted answer for this q
                          newAcceptedAnswer = answer.acceptedAnswer;
                        } else if (answer.suggestion?.value) {
                          // Making the suggestion the saved answer
                          newAcceptedAnswer = {
                            answer: answer.suggestion?.value,
                          };
                        }
                        handleSaveNewAnswer(step, newAcceptedAnswer, true);
                      }}
                      disabled={!answer.suggestion?.completed}
                      variant={"contained"}
                      endIcon={<ChevronRight />}
                    >
                      Save New Answer
                    </Button>
                  </div>
                </>
              )}
            </>
          ) : (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              No previous answer found
            </Alert>
          )}
        </div>
      </div>
    );
  }

  if (step.element.type === "textField") {
    return (
      <div key={step.id} className={`flex flex-1 flex-col gap-y-2`}>
        <StepHeader templateElement={step} />
        <div className="flex w-full flex-1 flex-row gap-4">
          <div className={`flex flex-1 flex-col gap-y-4`}>
            {existsPreviousTextAnswer ? (
              <>
                <ElementMapper
                  step={step}
                  hasInlineSuggestion={hasInlineSuggestion(step)}
                  stepValue={answer.previousAnswer}
                  documentVersion={documentVersion}
                  templateId={templateId}
                  textFieldReadOnly={true}
                  title={"Previous Answer"}
                />
                <div className="self-end">
                  <Button
                    onClick={handleKeepPreviousAnswer}
                    variant={"outlined"}
                    endIcon={<ChevronRight />}
                  >
                    Keep Previous Answer
                  </Button>
                </div>
              </>
            ) : (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                No previous answer found
              </Alert>
            )}
          </div>
          <Suggestion
            suggestion={
              // Slightly weird, but to make this in line with tables (where user edits only go into acceptedAnswer)
              // we must jam the new acceptedAnswer into a completed suggestion
              // ? {
              //     ...newSuggestion,
              //     value: answer.acceptedAnswer.answer,
              //   }
              answer.suggestion?.value ? answer.suggestion : newSuggestion
            }
            workflowState={workflowState}
            step={step}
            onChange={(value) => {
              handleSaveNewAnswer(step, {
                value: {
                  answer: value,
                },
                suggestion: {
                  ...newSuggestion,
                  value,
                },
              });
            }}
            handleApply={() => {
              if (answer.suggestion?.value) {
                handleSaveNewAnswer(
                  step,
                  {
                    value: {
                      answer: answer.suggestion?.value,
                    },
                  },
                  true
                );
              }
            }}
            onRegenerateSuggestion={() => {
              handleClearAnswer(step);
              refreshSuggestion();
            }}
            boxTitle="Suggested New Answer"
            buttonProps={{
              color: "primary",
              text: "Use New Answer",
            }}
            readOnly={false}
            assistantProcess={assistantProcess}
          />
        </div>
      </div>
    );
  }

  // Single panel (non suggestion) elements
  return (
    <div key={step.id} className={`flex flex-1 flex-col gap-y-2`}>
      <StepHeader templateElement={step} />
      <div className="flex w-full flex-1 flex-row gap-4">
        <div className={`flex flex-1 flex-col gap-y-4`}>
          <ElementMapper
            step={step}
            hasInlineSuggestion={hasInlineSuggestion(step)}
            stepValue={answer.acceptedAnswer || answer.previousAnswer}
            documentVersion={documentVersion}
            templateId={templateId}
            title="Answer"
            onChange={(el, value) => handleSaveNewAnswer(el, { value }, false)}
          />
          <div className="self-end">
            <Button
              disabled={!isReadyForNextStep}
              onClick={nextStep}
              variant="contained"
              endIcon={<ChevronRight />}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
