import { array, boolean, InferType, mixed, object, string } from "yup";
import { DocumentAnswerItemSchema } from "./document";

const StepValueSchema = object({
  answer: string().nullable(),
  answerFileId: string().nullable(),
  answerItems: array().of(DocumentAnswerItemSchema).nullable(),
});

export enum AssistantProcessStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export enum StatusMessageType {
  Error = "error",
  Info = "info",
}

//TODO: align with type in types/index
const SuggestionSchema = object({
  applied: boolean().required(),
  value: string().required().nullable(),
  completed: boolean().required(),
  isStatusMessage: boolean().required(),
  statusMessageType: mixed<StatusMessageType>().oneOf(
    Object.values(StatusMessageType)
  ),
  loading: boolean().required(),
  error: boolean().required(),
});

//TODO: align with type in types/index
const AssistantMultiStepFormAnswerSchema = object({
  dataKeyId: string().required(),
  templateId: string().required(),
  previousAnswer: StepValueSchema.required(),
  acceptedAnswer: StepValueSchema.optional(),
  suggestion: SuggestionSchema.default(undefined).optional(),
});

export type AssistantMultiStepFormAnswer = InferType<
  typeof AssistantMultiStepFormAnswerSchema
>;

//TODO: align with type in types/index
const AssistantMultiStepFormStepSchema = object({
  dataKeyId: string().required(),
  templateId: string().required(),
  ignorePreviousAnswer: boolean().optional(),
});

const AssistantProcessStateSchema = object({
  changeType: string().required(),
  currentPath: string().required(),
  checkboxes: array().of(string()).required(),
  description: string().default("").optional(),
  dataKeysToUpdate: array().of(AssistantMultiStepFormStepSchema),
  changes: array().of(AssistantMultiStepFormAnswerSchema).required(),
});

type AssistantProcessState = InferType<typeof AssistantProcessStateSchema>;

export const AssistantProcessSchema = object({
  id: string().required(),
  state: AssistantProcessStateSchema.required(),
  deviceId: string().required(),
  organizationId: string().required(),
  createdAt: string().required(),
  updatedAt: string().required(),
  createdBy: string().required(),
  status: string().oneOf(Object.values(AssistantProcessStatus)).required(),
});

export interface AssistantProcess
  extends InferType<typeof AssistantProcessSchema> {}

export type PatchAssistantProcessBody = {
  status?: AssistantProcessStatus;
  state?: AssistantProcessState;
};
