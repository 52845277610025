import {
  AssistantMultiStepFormAnswer,
  AssistantProcess,
  Device,
  Document,
  TEMPLATE_TYPE,
} from "@models";
import { Button } from "@mui/material";
import {
  AssistantMultiStepFormAnswerUpdate,
  StepValue,
  TemplateElement,
} from "@types";
import { hasInlineSuggestion } from "@utils";
import { ChevronRight } from "lucide-react";
import { ElementMapper } from "src/components/Form/ElementMapper";
import { StepHeader } from "src/components/Form/StepHeader";
import { Suggestion } from "src/components/Form/Suggestion";
import { useSuggestion } from "src/components/FormStep/utils/useSuggestion";

const IgnorePreviousAnswerStep = ({
  step,
  handleAnswerChange,
  nextStep,
  templateId,
  device,
  documents,
  answer,
  assistantProcess,
  isReadyForNextStep,
}: {
  step: TemplateElement;
  handleAnswerChange: (
    step: TemplateElement,
    value: AssistantMultiStepFormAnswerUpdate
  ) => void;
  nextStep: () => void;
  templateId: TEMPLATE_TYPE;
  device: Device;
  documents: Document[];
  answer: AssistantMultiStepFormAnswer;
  assistantProcess: AssistantProcess;
  isReadyForNextStep: boolean;
}) => {
  const {
    suggestion: newSuggestion,
    refreshSuggestion,
    workflowState,
  } = useSuggestion({
    step,
    device,
    documents,
    savedSuggestion: answer.suggestion,
    assistantProcess,
    enabled: true,
  });

  // TODO: move to parent
  const handleSaveNewAnswer = (
    tempalteEl: TemplateElement,
    value?: StepValue,
    goNextStep?: boolean
  ) => {
    if (!value) {
      return;
    }
    handleAnswerChange(tempalteEl, {
      acceptedAnswer: value,
    });
    if (goNextStep) {
      nextStep();
    }
  };

  const handleClearAnswer = (templateEl: TemplateElement) => {
    handleAnswerChange(templateEl, {
      acceptedAnswer: undefined,
    });
  };

  return (
    // replacing div with slider for now since it caused performance issues
    <div key={step.id} className={`w-full flex flex-1 flex-col gap-y-2`}>
      <StepHeader templateElement={step} />
      <div className="flex w-full flex-1 flex-row items-start gap-4">
        {!hasInlineSuggestion(step) && step.prompt && (
          <Suggestion
            suggestion={newSuggestion}
            workflowState={workflowState}
            step={step}
            handleApply={() => {
              if (answer.suggestion?.value) {
                handleSaveNewAnswer(
                  step,
                  {
                    answer: answer.suggestion?.value,
                  },
                  false
                );
              }
            }}
            onRegenerateSuggestion={() => {
              handleClearAnswer(step);
              refreshSuggestion();
            }}
          />
        )}
        <div
          className={`flex ${
            hasInlineSuggestion(step) ? "w-full" : "w-1/2"
          } h-full flex-1 flex-col gap-y-4`}
        >
          <ElementMapper
            step={step}
            suggestion={newSuggestion}
            onChange={(el, value) => handleSaveNewAnswer(el, value, false)}
            hasInlineSuggestion={hasInlineSuggestion(step)}
            stepValue={answer.acceptedAnswer || {}}
            onRegenerateSuggestion={() => {
              handleClearAnswer(step);
              refreshSuggestion();
            }}
            onReRunTransformer={() => {}} // TODO:
            onRedo={() => {}}
            onUndo={() => {}}
            templateId={templateId}
          />
          <div className="self-end">
            <Button
              onClick={nextStep}
              variant="contained"
              endIcon={<ChevronRight />}
              disabled={!isReadyForNextStep}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IgnorePreviousAnswerStep;
