import {
  AssistantProcess,
  Device,
  Document,
  DocumentVersion,
  StatusMessageType,
} from "@models";
import { Suggestion, TemplateElement, WorkflowState } from "@types";
import { captureSentryException } from "@utils";
import { useEffect, useState } from "react";
import { generateSuggestion } from "src/components/Form/utils";
import { extractAnswerFromString } from "src/components/Form/utils/parsing";
import { activeStreams } from "src/components/Form/utils/sse";

export const defaultSuggestion: Suggestion = {
  applied: false,
  value: null,
  completed: false,
  loading: false,
  error: false,
  isStatusMessage: false,
  statusMessageType: undefined,
};

export const useSuggestion = ({
  step,
  documentVersion,
  device,
  documents,
  savedSuggestion,
  assistantProcess,
  enabled = false,
}: {
  step: TemplateElement;
  documentVersion?: DocumentVersion;
  device: Device;
  documents: Document[];
  savedSuggestion: Suggestion | undefined;
  assistantProcess?: AssistantProcess;
  enabled: boolean;
}) => {
  const [suggestion, setSuggestion] = useState<Suggestion>(
    savedSuggestion ? savedSuggestion : defaultSuggestion
  );

  const [workflowState, setWorkflowState] = useState<WorkflowState>({
    currentNode: undefined,
    isHiddenOutputNode: false,
    isLoading: false,
  });

  const handleGenerateSuggestion = async () => {
    // check if we currently have a stream running and return if so
    if (activeStreams.has(step.id)) {
      return;
    }

    // Set the loading state for the suggestion to true
    setSuggestion({
      ...defaultSuggestion,
      loading: true,
    });

    setWorkflowState({
      currentNode: undefined,
      isHiddenOutputNode: false,
      isLoading: true,
    });

    try {
      // Start fetching the suggestion
      const sseMessageStream = generateSuggestion({
        elementId: step.id,
        device,
        documents,
        documentVersion,
        process: assistantProcess,
      });

      let parsedContent = "";
      let rawContent = "";

      let statusMessage: { status: string; message: string } | undefined =
        undefined;

      let currentNode: string | undefined = undefined;

      for await (const message of sseMessageStream) {
        // console.log("message", message.data.content);
        const content = message.data.content;

        const nodeInfo = message.data.metadata["langgraph_node"];
        const provider = message.data.metadata["ls_provider"];
        const isAnthropic = provider === "anthropic";

        // Only update the current node if we get information about it
        if (nodeInfo) {
          currentNode = nodeInfo;
        }

        // Check if the message is an error
        const isError = message.event === "error";
        if (isError) {
          throw new Error(message.data.content || "Unknown error");
        }

        const isNodeWithHiddenOutput =
          currentNode !== undefined && currentNode !== "apply_changes";
        setWorkflowState((prevState) => ({
          ...prevState,
          currentNode: currentNode,
          isHiddenOutputNode: isNodeWithHiddenOutput,
        }));

        // Skip the rest if we don't have content or if we are in a node with hidden output
        if (!content || isNodeWithHiddenOutput) continue;

        const isStatusMessage =
          content.startsWith("{") && content.endsWith("}");

        // check if the token is a status message object with the structure {status: "info", message: "some message"}
        if (isStatusMessage) {
          try {
            const event = JSON.parse(content);
            if (event.status && event.message) {
              statusMessage = event;
            }
          } catch (error) {
            captureSentryException(error, {
              content,
            });
          }
        } else {
          statusMessage = undefined;
        }

        if (statusMessage) {
          rawContent = statusMessage.message;
          // We need to fully overwrite the suggestion because if previousWasStatusMessage is true
          // the suggestion value previously contains the status message and we don't want to append it
        } else {
          // console.log("content", content);
          rawContent += content;
        }

        if (isAnthropic) {
          const parsedValue = extractAnswerFromString(rawContent);

          parsedContent = parsedValue;
        } else {
          parsedContent = rawContent;
        }

        // console.log("parsedContent", parsedContent);

        setSuggestion((prevSuggestion) => ({
          ...prevSuggestion,
          value: parsedContent,
          loading: true,
          completed: false,
          isStatusMessage: !!statusMessage,
          statusMessageType: statusMessage?.status as StatusMessageType,
        }));
      }

      // Once the generator finishes, set the loading state to false and the completed state to true
      setSuggestion((prevSuggestions) => ({
        ...prevSuggestions,
        completed: true,
        loading: false,
      }));

      setWorkflowState({
        currentNode: undefined,
        isHiddenOutputNode: false,
        isLoading: false,
      });
    } catch (error) {
      // Handle the error here
      captureSentryException(error);

      setSuggestion((prevSuggestion) => ({
        ...prevSuggestion,
        loading: false,
        completed: true,
        error: true,
      }));

      setWorkflowState({
        currentNode: undefined,
        isHiddenOutputNode: false,
        isLoading: false,
      });
    }
  };

  const applySuggestion = () => {
    setSuggestion((prevSuggestion) => ({
      ...prevSuggestion,
      applied: true,
    }));
  };

  const refreshSuggestion = () => {
    setSuggestion(defaultSuggestion);
  };

  useEffect(() => {
    if (
      enabled &&
      !suggestion.loading &&
      !suggestion.completed &&
      step.prompt &&
      !suggestion.error &&
      typeof suggestion.value !== "string"
    ) {
      handleGenerateSuggestion();
    }
  }, [suggestion, step.prompt, enabled]);

  return {
    suggestion,
    refreshSuggestion,
    workflowState,
    applySuggestion,
    handleGenerateSuggestion,
  };
};

export default useSuggestion;
