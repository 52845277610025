import { theme } from "@config";
import { AddCircleOutlineOutlined, ChevronRight } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Button,
  Chip,
  IconButton,
  Typography,
} from "@mui/material";
import { AnswerItem, AnswerItemsElement, StepValue } from "@types";
import { scrollToElement } from "@utils";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AccordionListMapper from "src/components/Form/Elements/AccordionList/AccordionListMapper";
import {
  getAlwaysShownValues,
  getHighestId,
} from "src/components/Form/Elements/AccordionList/utils";
import ListMenu from "./AccordionListMenu";

type Props = {
  element: AnswerItemsElement;
  answerItems: AnswerItem[];
  disabled?: boolean;
  onChange: (value: StepValue) => void;
  // step: TemplateElement;
  // documentVersion: DocumentVersion;
};

export const AccordionListNoAPICalls: React.FC<Props> = ({
  disabled,
  element,
  answerItems,
  // step,
  // documentVersion,
  onChange,
}) => {
  const { orgId = "" } = useParams<{
    orgId: string;
  }>();

  // const saveAnswerMutation = useSaveAnswerMutation();
  // const saveAnswerItemMutation = useSaveAnswerItemMutation();
  // const patchAnswerItemMutation = usePatchAnswerItemMutation();

  const [expandedId, setExpandedId] = useState<string | null>(null);

  // const patchItem = async ({ answer }: { answer: AnswerItem }) => {
  //   const answerId = documentVersion.answers.find(
  //     (a) => a.element === step.id
  //   )?.id;

  //   if (!answerId) {
  //     captureSentryException("Answer ID not found");
  //     return;
  //   }

  //   patchAnswerItemMutation.mutateAsync({
  //     orgId,
  //     versionId: documentVersion.id,
  //     deviceId,
  //     docId,
  //     archived: answer.archived,
  //     data: { fields: answer.fields },
  //     itemId: answer.id,
  //     type: answer.type,
  //     answerId: answerId,
  //     step,
  //     answerItems: answerItems ?? [],
  //     documentVersion,
  //   });
  // };

  // const debouncedSave = useDebounce(1000, (idx: number) => {
  //   // Your request logic here
  //   // patchItem({ answer: answerItems[idx] });
  // });

  const handleChange = ({
    accordionIdx,
    fieldKey,
    value,
  }: {
    accordionIdx: number;
    fieldKey: string;
    value: StepValue | undefined;
  }) => {
    const updatedList = [...answerItems];
    updatedList[accordionIdx] = {
      ...updatedList[accordionIdx],
      fields: {
        ...updatedList[accordionIdx].fields,
        [fieldKey]: value?.answer ?? "",
      },
    };

    onChange({ answerItems: updatedList });

    // const isItemSaved = updatedList[accordionIdx].createdBy !== "";
    // if (isItemSaved) {
    //   debouncedSave(accordionIdx);
    // }
  };

  // const saveItem = async ({ answer }: { answer: AnswerItem }) => {
  //   const versionAnswer = documentVersion.answers.find(
  //     (a) => a.element === step.id
  //   );

  //   if (versionAnswer && answer.type && answer.fields) {
  //     // Optimistic update
  //     const optimisticUpdateState = answerItems.map((item) =>
  //       item.sequentialTypeNumber === answer.sequentialTypeNumber
  //         ? { ...item, createdBy: "temp" }
  //         : item
  //     );
  //     onChange({ answerItems: optimisticUpdateState });

  //     // const savedItem = await saveAnswerItemMutation.mutateAsync({
  //     //   orgId,
  //     //   versionId: documentVersion.id,
  //     //   deviceId,
  //     //   docId,
  //     //   answerId: versionAnswer.id,
  //     //   data: {
  //     //     type: answer.type,
  //     //     fields: answer.fields,
  //     //   },
  //     //   step,
  //     //   documentVersion,
  //     //   answerItems: answerItems,
  //     // });

  //     // Update the state with the saved item
  //     // const updatedState = answerItems.map((item) =>
  //     //   item.sequentialTypeNumber === answer.sequentialTypeNumber
  //     //     ? savedItem
  //     //     : item
  //     // );
  //     // onChange({ answerItems: updatedState });
  //   }
  // };

  const addNewItem = () => {
    // if (answerItems.length === 0) {
    //   saveAnswerMutation.mutate({
    //     orgId,
    //     answer: undefined,
    //     step: step,
    //     documentVersion,
    //     docId,
    //     deviceId,
    //   });
    // }

    const nextId = getHighestId(answerItems) + 1;
    const emptyFields = element.options.fields.reduce((acc, { fieldKey }) => {
      return {
        ...acc,
        [fieldKey]: "",
      };
    }, {});

    const newList = [
      ...answerItems,
      {
        type: element.options.typeId,
        sequentialTypeNumber: nextId,
        id: crypto.randomUUID(),
        fields: emptyFields,
        updatedAt: new Date(),
        answerId: "temp",
        organizationId: orgId,
        createdBy: "temp",
        createdAt: new Date(),
        archived: false,
      },
    ];

    const fieldId = `${element.options.typeId}${nextId}`;

    setExpandedId(fieldId);
    onChange({ answerItems: newList });
    scrollToElement(fieldId);
  };

  return (
    <div key={element.id} className="flex flex-col gap-y-3">
      {answerItems.map((accordion, accordionIdx) => {
        const fieldType = accordion.type;
        const fieldNumber = accordion.sequentialTypeNumber;
        const fieldId = `${fieldType}${fieldNumber}`;
        const isItemSaved = accordion.createdBy !== "";

        if (accordion.archived) {
          return null;
        }

        return (
          <div key={fieldId} id={fieldId} className="flex items-start">
            <Accordion
              expanded={expandedId === fieldId}
              onChange={(_, isExpanded) =>
                setExpandedId(isExpanded && fieldId ? fieldId : null)
              }
              style={{
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <AccordionSummary>
                <div className="flex justify-between items-center w-full">
                  <div>
                    <Typography id="title">
                      #{fieldId} - {String(accordion.fields?.name || "Name")}
                      {!isItemSaved && (
                        <Chip
                          label="draft"
                          color="warning"
                          size="small"
                          className="ml-2"
                        />
                      )}
                    </Typography>
                    <div className="flex flex-wrap gap-3">
                      <Typography
                        color="text.secondary"
                        sx={{
                          fontSize: "12px",
                          display: expandedId === fieldId ? "none" : "block",
                        }}
                      >
                        {getAlwaysShownValues(accordion, element)}
                      </Typography>
                    </div>
                  </div>
                  <div className="flex items-center">
                    {/* {!isItemSaved && (
                      <Button
                        onClick={() =>
                          onChange({ answerItems: [...answerItems, accordion] })
                        }
                        variant="contained"
                        color="primary"
                        disabled={!isItemComplete(accordion, element)}
                        className="mr-2"
                      >
                        Create
                      </Button>
                    )} */}
                    <ListMenu
                      fieldId={fieldId}
                      accordionIdx={accordionIdx}
                      list={answerItems}
                      patchItem={() => {}}
                      onChange={(value) => onChange(value)}
                    />
                    <IconButton>
                      <ChevronRight
                        sx={{
                          transform:
                            expandedId === fieldId ? "rotate(90deg)" : "none",
                          transition: "transform 0.2s",
                        }}
                      />
                    </IconButton>
                  </div>
                </div>
              </AccordionSummary>
              <div className="flex flex-wrap gap-3 w-full p-4 pt-0">
                <AccordionListMapper
                  accordionIdx={accordionIdx}
                  element={element}
                  accordion={answerItems[accordionIdx]}
                  disabled={disabled}
                  onChange={({ fieldKey, value }) =>
                    handleChange({ accordionIdx, fieldKey, value })
                  }
                />
              </div>
            </Accordion>
          </div>
        );
      })}
      <Button onClick={addNewItem}>
        <AddCircleOutlineOutlined className="mr-1" /> Add new item
      </Button>
    </div>
  );
};

export default AccordionListNoAPICalls;
