import { Typography } from "@mui/material";
import { HelpBox } from "src/components/Form/HelpBox";
import { TemplateElement } from "src/types";

export const StepHeader = ({
  templateElement,
}: {
  templateElement: TemplateElement | undefined;
}) => {
  return (
    <div className="flex flex-col gap-y-3">
      <Typography variant="h1">
        {templateElement ? templateElement.element.options.label : "Error"}
      </Typography>
      {(!!templateElement?.element.options.helperText ||
        templateElement?.videoUrl) && (
        <HelpBox templateElement={templateElement} />
      )}
    </div>
  );
};
