import { TemplateElement } from "@types";
import { AssistantMultiStepFormAnswer } from "src/stores/models/assistant-process";
import { WizardTopBar } from "./WizardTopBar";

export const AssistantMultiStepFormTopBar = ({
  activeStep,
  steps,
  activeAnswer,
  nextStep,
  prevStep,
  endButtonOnClick,
}: {
  activeStep: TemplateElement;
  steps: TemplateElement[];
  activeAnswer?: AssistantMultiStepFormAnswer;
  nextStep: () => void;
  prevStep: () => void;
  endButtonOnClick: () => void;
}) => {
  const isLastStep = activeStep.id === steps[steps.length - 1].id;
  const isFirstStep = activeStep.id === steps[0].id;

  return (
    <WizardTopBar
      forwardDisabled={
        isLastStep ||
        !activeAnswer?.acceptedAnswer ||
        Object.keys(activeAnswer?.acceptedAnswer || {}).length === 0
      }
      backDisabled={isFirstStep}
      nextStep={nextStep}
      prevStep={prevStep}
      leftButton={{
        // startIcon: <ExitToApp fontSize="small" />,
        onClick: endButtonOnClick,
        text: "Cancel Assistant",
      }}
    />
  );
};
