import { VideocamRounded } from "@mui/icons-material";
import { Alert, Button } from "@mui/material";
import MarkdownPreview from "@uiw/react-markdown-preview";
import rehypeExternalLinks from "rehype-external-links";
import { TemplateElement } from "../../types";

type Props = { templateElement: TemplateElement };

export const HelpBox = ({ templateElement }: Props) => {
  const { element } = templateElement;
  return (
    <Alert severity="info">
      <div className="flex w-full flex-1 flex-col items-start justify-between gap-y-2">
        {!!element.options.helperText && (
          <MarkdownPreview
            source={element.options.helperText}
            className="bg-transparent text-[#014361] text-base"
            wrapperElement={{ "data-color-mode": "light" }}
            // Open external links in new tab
            rehypePlugins={[() => rehypeExternalLinks({ target: "_blank" })]}
            // Disable links in headings
            rehypeRewrite={(node, _, parent) => {
              if (
                // @ts-ignore
                node.tagName === "a" &&
                parent &&
                // @ts-ignore
                /^h(1|2|3|4|5|6)/.test(parent.tagName)
              ) {
                parent.children = parent.children.slice(1);
              }
            }}
          />
        )}
        {templateElement.videoUrl && (
          <Button
            color="info"
            variant="outlined"
            endIcon={<VideocamRounded />}
            href={templateElement.videoUrl}
            target="_blank"
          >
            Explain this
          </Button>
        )}
      </div>
    </Alert>
  );
};
