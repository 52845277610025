import axios from "axios";
import { ROUTES } from "src/config";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import { fillRouteWithSlugs } from "src/utils/navigation";
import { authHelper } from "../stores/helpers";
import {
  AssistantProcess,
  AssistantProcessStatus,
  BillingInterval,
  Device,
  DocumentVersionApproverStatus,
  PatchAssistantProcessBody,
  Plan,
  TEMPLATE_TYPE,
  User,
  UserUiState,
} from "../stores/models";
import {
  CreateDemoDeviceRequest,
  Fields,
  PatchDocumentVersionData,
} from "../types";
const axiosClient = axios.create({
  baseURL: window._env_.BACKEND_BASE_URL,
});

axiosClient.interceptors.request.use(
  async (axiosConfig) => {
    let token = null;
    token = await authHelper.getAccessTokenSilently()();

    if (token) {
      // @ts-ignore
      axiosConfig.headers.Authorization = `Bearer ${token}`;
    }
    return axiosConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getUser = async () => {
  return axiosClient.get(`user/me`);
};

export const getUserById = async (userId: string) => {
  return axiosClient.get(`user/${userId}`);
};

export const getUserTodos = async (orgId: string, deviceId: string) => {
  return axiosClient.get(
    `organization/${orgId}/user/me/todos?deviceId=${deviceId}`
  );
};

export const getDocumentApprovers = async ({
  documentId,
  versionId,
  deviceId,
  orgId,
}: {
  documentId: string;
  versionId: string;
  deviceId: string;
  orgId: string;
}) => {
  return axiosClient.get(
    `organization/${orgId}/device/${deviceId}/document/${documentId}/version/${versionId}/approver`
  );
};

export const getUsersByOrg = async ({
  orgId,
  skip = 0,
  includeAutoApprovers = false,
}: {
  orgId: string;
  skip?: number;
  includeAutoApprovers?: boolean;
}) => {
  return axiosClient.get(`organization/${orgId}/user`, {
    params: {
      skip,
      ...(includeAutoApprovers && { includeAutoApprovers }),
    },
  });
};

export const patchUserInOrg = async (
  orgId: string,
  id: string,
  data: Partial<User>
) => {
  return axiosClient.patch(`organization/${orgId}/user/${id}`, data);
};

export const updateOrg = async (orgId: string, data: { name: string }) => {
  return axiosClient.patch(`organization/${orgId}`, data);
};

export const updateUserUiState = async (data: Partial<UserUiState>) => {
  return axiosClient.patch("user/me/ui-state", data);
};

export const getDevices = async (orgId: string) => {
  return axiosClient.get(`organization/${orgId}/device`);
};

export const getDevice = async (orgId: string, id: string) => {
  return axiosClient.get(`organization/${orgId}/device/${id}`);
};

export const getAnswerItemsByTypeId = async ({
  orgId,
  type,
}: {
  orgId: string;
  type: string;
}) => {
  return axiosClient.get(`organization/${orgId}/search`, {
    params: { type },
  });
};

export const getAnswerItemsById = async ({
  orgId,
  itemId,
}: {
  orgId: string;
  itemId: string;
}) => {
  return axiosClient.get(`organization/${orgId}/search/${itemId}`);
};

export const postDocumentAnswerItem = async ({
  data,
  orgId,
  deviceId,
  docId,
  versionId,
  answerId,
}: {
  data: {
    type: string;
    fields: Fields;
  };
  orgId: string;
  deviceId: string;
  docId: string;
  versionId: string;
  answerId: string;
}) => {
  return axiosClient.post(
    `organization/${orgId}/device/${deviceId}/document/${docId}/version/${versionId}/answer/${answerId}/item`,
    data
  );
};

export const patchDocumentAnswerItem = async ({
  orgId,
  deviceId,
  docId,
  versionId,
  answerId,
  archived,
  itemId,
  data,
}: {
  orgId: string;
  deviceId: string;
  docId: string;
  versionId: string;
  answerId: string;
  itemId: string;
  archived: boolean;
  data: { fields: Fields };
}) => {
  return axiosClient.patch(
    `organization/${orgId}/device/${deviceId}/document/${docId}/version/${versionId}/answer/${answerId}/item/${itemId}`,
    { ...data, archived }
  );
};

export const postDevice = async (
  orgId: string,
  data: {
    name: string;
    description: string;
  }
) => {
  return axiosClient.post(`organization/${orgId}/device`, data);
};

export const postDemoDevice = async (
  orgId: string,
  data: CreateDemoDeviceRequest
) => {
  return axiosClient.post(`organization/${orgId}/device/demo`, data);
};

export const patchDevice = async (orgId: string, data: Device) => {
  return axiosClient.patch(`organization/${orgId}/device/${data.id}`, {
    ...(data.name && { name: data.name }),
    ...(data.description && { description: data.description }),
  });
};

export const deleteDevice = async (orgId: string, id: string) => {
  return axiosClient.delete(`organization/${orgId}/device/${id}`);
};

export const getRoadmapTasks = async (orgId: string, deviceId: string) => {
  return axiosClient.get(
    `organization/${orgId}/device/${deviceId}/roadmap/tasks`
  );
};

export const patchRoadmapTasks = async (
  orgId: string,
  deviceId: string,
  data: Record<string, boolean>
) => {
  return axiosClient.patch(
    `organization/${orgId}/device/${deviceId}/roadmap/tasks`,
    data
  );
};

export const getDocument = async (
  orgId: string,
  deviceId: string,
  docId: string
) => {
  return axiosClient.get(
    `organization/${orgId}/device/${deviceId}/document/${docId}`
  );
};

export const getDocuments = async (orgId: string, deviceId: string) => {
  return axiosClient.get(`organization/${orgId}/device/${deviceId}/document`);
};

export const createDocument = async (
  orgId: string,
  deviceId: string,
  templateId: TEMPLATE_TYPE
) => {
  return axiosClient.post(`organization/${orgId}/device/${deviceId}/document`, {
    templateId,
  });
};

export const createDocumentVersion = async (
  orgId: string,
  deviceId: string,
  documentId: string,
  file?: File
) => {
  return axiosClient.post(
    `organization/${orgId}/device/${deviceId}/document/${documentId}/version`,
    { file },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

export const uploadFile = async ({
  orgId,
  file,
  deviceId,
}: {
  orgId: string;
  file: File;
  deviceId?: string;
}) => {
  return axiosClient.post(
    `organization/${orgId}/file`,
    { file, ...(deviceId && { deviceId }) },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

export const getFile = async ({
  orgId,
  fileId,
}: {
  orgId: string;
  fileId: string;
}) => {
  return axiosClient.get(`organization/${orgId}/file/${fileId}`, {
    responseType: "blob",
  });
};

export const getAllFilesInOrg = async ({
  orgId,
  deviceId,
}: {
  orgId: string;
  deviceId?: string;
}) => {
  return axiosClient.get(`organization/${orgId}/file`, {
    params: {
      ...(deviceId && { deviceId }),
    },
  });
};

export const deleteFile = async ({
  orgId,
  fileId,
}: {
  orgId: string;
  fileId: string;
}) => {
  return axiosClient.delete(`organization/${orgId}/file/${fileId}`);
};

// {
//   "state": {
//     "changeType": "update",
//     "description": "This is a mock description",
//     "changes": [],
//     "checkboxes": ["checkbox1", "checkbox2"],
//     "currentPath": "1",
//     "dataKeysToUpdate": [
//       {
//         "dataKeyId": "list-of-qualified-suppliers",
//         "templateId": "LIST_OF_QUALIFIED_SUPPLIERS",
//         "ignorePreviousAnswer": true
//       },
//       {
//         "dataKeyId": "indications-for-use",
//         "templateId": "INTENDED_USE"
//       }
//     ]
//   }
// }
export const postAssistantProcess = async ({
  data,
  orgId,
  deviceId,
}: {
  data: PatchAssistantProcessBody;
  orgId: string;
  deviceId: string;
}) => {
  return axiosClient.post(
    `/organization/${orgId}/device/${deviceId}/assistant-process`,
    data
  );
};

// {
//   "state": {
//     "changeType": "update",
//     "description": "This is a mock description",
//     "changes": [],
//     "checkboxes": ["checkbox1", "checkbox2"],
//     "currentPath": "2",
//     "dataKeysToUpdate": [
//       {
//         "dataKeyId": "list-of-qualified-suppliers",
//         "templateId": "LIST_OF_QUALIFIED_SUPPLIERS",
//         "ignorePreviousAnswer": true
//       } // answerItemsElement
//     // {
//     //   "dataKeyId": "software-architecture-diagram",
//     //   "templateId": "SOFTWARE_ARCHITECTURE"
//     // }, // fileUploadElement
//     // {
//     //   "dataKeyId": "pubmed-search-string",
//     //   "templateId": "CLINICAL_EVALUATION_REPORT"
//     // }, // parsedFileUploadElement
//     // {
//     //   "dataKeyId": "role-of-company",
//     //   "templateId": "QUALITY_MANUAL_POLICY_OBJECTIVES"
//     // }, // select
//     // {
//     //   "dataKeyId": "employee-information",
//     //   "templateId": "LIST_OF_EMPLOYEE_TRAINING"
//     // }, // table
//     // {
//     //   "dataKeyId": "indications-for-use",
//     //   "templateId": "INTENDED_USE"
//     // } // textField
//     ]
//   },
//   "status": "IN_PROGRESS"
// }
// export const patchAssistantProcess = async ({
//   data,
//   orgId,
//   deviceId,
//   processId,
// }: {
//   data: AssistantProcess;
//   orgId: string;
//   deviceId: string;
//   processId: string;
// }) => {
//   return axiosClient.patch(
//     `/organization/${orgId}/device/${deviceId}/assistant-process/${processId}`,
//     data
//   );
// };

export const getAssistantProcess = async (
  _orgId: string,
  _deviceId: string
) => {
  const sessionState = JSON.parse(
    sessionStorage.getItem("assistant") ||
      '{ "state": {"changeType": "","currentPath": "", "changes": []}}'
  );
  const mockAssistantProcess: AssistantProcess = {
    ...sessionState,
    id: "1",
    //  state: {
    //   changeType: "update",
    //   description: "This is a mock description",
    //   changes: [],
    //   checkboxes: ["checkbox1", "checkbox2"],
    //   currentPath: "1",
    //   dataKeysToUpdate: [
    //     {
    //       dataKeyId: "list-of-qualified-suppliers",
    //       templateId: TEMPLATE_TYPE.LIST_OF_QUALIFIED_SUPPLIERS,
    //       ignorePreviousAnswer: true,
    //     }, // answerItemsElement
    //     // {
    //     //   dataKeyId: "software-architecture-diagram",
    //     //   templateId: TEMPLATE_TYPE.SOFTWARE_ARCHITECTURE,
    //     // }, // fileUploadElement
    //     // {
    //     //   dataKeyId: "pubmed-search-string",
    //     //   templateId: TEMPLATE_TYPE.CLINICAL_EVALUATION_REPORT,
    //     // }, // parsedFileUploadElement
    //     // {
    //     //   dataKeyId: "user-training",
    //     //   templateId: TEMPLATE_TYPE.USABILITY_EVALUATION_PROTOCOL,
    //     //   // ignorePreviousAnswer: true,
    //     // }, // textField incomplete?
    //     // {
    //     //   dataKeyId: "ticketing-system",
    //     //   templateId: TEMPLATE_TYPE.SOP_SOFTWARE_PROBLEM_RESOLUTION,
    //     // }, // textField no document for it
    //     // {
    //     //   dataKeyId: "role-of-company",
    //     //   templateId: TEMPLATE_TYPE.QUALITY_MANUAL_POLICY_OBJECTIVES,
    //     // }, // select
    //     // {
    //     //   dataKeyId: "employee-information",
    //     //   templateId: TEMPLATE_TYPE.LIST_OF_EMPLOYEE_TRAINING,
    //     // }, // table
    //     {
    //       dataKeyId: "indications-for-use",
    //       templateId: TEMPLATE_TYPE.INTENDED_USE,
    //     }, // textField
    //   ],
    // },
    type: "assistantProcess",
    createdBy: "1",
    organizationId: "1",
    deviceId: "1",
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    status: AssistantProcessStatus.IN_PROGRESS,
  };

  return { data: mockAssistantProcess };
};

export const getAssistantProcesses = async (
  orgId: string,
  deviceId: string,
  status?: AssistantProcessStatus
) => {
  return axiosClient.get(
    `organization/${orgId}/device/${deviceId}/assistant-process`,
    { params: { ...(status && { status }) } }
  );
};

export const patchAssistantProcess = async (
  orgId: string,
  deviceId: string,
  processId: string,
  data: PatchAssistantProcessBody
) => {
  return axiosClient.patch(
    `organization/${orgId}/device/${deviceId}/assistant-process/${processId}`,
    data
  );
};

export const deleteDocumentVersion = async (
  orgId: string,
  deviceId: string,
  docId: string,
  versionId: string
) => {
  return axiosClient.delete(
    `organization/${orgId}/device/${deviceId}/document/${docId}/version/${versionId}`
  );
};

export const patchDocumentVersion = async (
  orgId: string,
  deviceId: string,
  documentId: string,
  versionId: string,
  data: PatchDocumentVersionData
) => {
  return axiosClient.patch(
    `organization/${orgId}/device/${deviceId}/document/${documentId}/version/${versionId}`,
    data
  );
};

export const postDocumentAnswer = async (
  orgId: string,
  deviceId: string,
  documentId: string,
  versionId: string,
  data: {
    answer?: string;
    element: string;
    answerFileId?: string;
  }
) => {
  return axiosClient.patch(
    `organization/${orgId}/device/${deviceId}/document/${documentId}/version/${versionId}/answer`,
    data
  );
};

export const postDocumentAnswerSuggestion = async (
  orgId: string,
  deviceId: string,
  documentId: string,
  versionId: string,
  data: { suggestion: string; element: string }
) => {
  return axiosClient.post(
    `organization/${orgId}/device/${deviceId}/document/${documentId}/version/${versionId}/suggestion`,
    data
  );
};

export const checkout = async (
  orgId: string,
  plan: Plan.PREMIUM | Plan.PREMIUM_PLUS,
  amount: number,
  cycle: BillingInterval
) => {
  return axiosClient.post(`organization/${orgId}/subscription/checkout`, {
    plan,
    amount,
    cycle,
    cancelUrl: window.location.href,
    successUrl: `${window.location.origin}${fillRouteWithSlugs(
      ROUTES.CHECKOUT_SUCCESS,
      {
        [ROUTE_SLUGS.ORG_ID]: orgId,
      }
    )}`,
  });
};

export const postBillingSession = async (orgId: string) => {
  return axiosClient.post(`organization/${orgId}/subscription/billing`, {
    returnUrl: window.location.href,
  });
};

export const postDocumentApprover = async ({
  orgId,
  deviceId,
  documentId,
  versionId,
  data,
}: {
  orgId: string;
  deviceId: string;
  documentId: string;
  versionId: string;
  data: { userId: string };
}) => {
  return axiosClient.post(
    `organization/${orgId}/device/${deviceId}/document/${documentId}/version/${versionId}/approver`,
    data
  );
};

export const patchApproverStatus = async ({
  orgId,
  deviceId,
  documentId,
  versionId,
  status,
}: {
  orgId: string;
  deviceId: string;
  documentId: string;
  versionId: string;
  status: keyof typeof DocumentVersionApproverStatus;
}) => {
  return axiosClient.patch(
    `organization/${orgId}/device/${deviceId}/document/${documentId}/version/${versionId}/approver/me`,
    {
      status,
    }
  );
};

export const deleteDocumentApprover = async (
  orgId: string,
  deviceId: string,
  documentId: string,
  versionId: string,
  approverId: string
) => {
  return axiosClient.delete(
    `organization/${orgId}/device/${deviceId}/document/${documentId}/version/${versionId}/approver/${approverId}`
  );
};

export const postUserInvite = async (
  orgId: string,
  data: {
    inviteeEmail: string;
    inviteeFirstName: string;
    inviteeLastName: string;
  }
) => {
  return axiosClient.post(`organization/${orgId}/user/invite`, data);
};

export const generatePdf = async ({
  orgId,
  deviceId,
  documentId,
  versionId,
  content,
  docName,
}: {
  orgId: string;
  deviceId: string;
  documentId: string;
  versionId: string;
  content: string;
  docName: string;
}) => {
  return axiosClient.post(
    `organization/${orgId}/device/${deviceId}/document/${documentId}/version/${versionId}/pdf`,
    { content: content, docName: docName },
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    }
  );
};
