// If any required field is empty, the create button is disabled
// required fields are name and description for the device and for each component, as well as description for each sub-component

import {
  ComponentConfigurationType,
  ComponentType,
  SubComponentConfigurationType,
  SubComponentType,
} from "@models";
import {
  ComponentState,
  NewDevice,
  NewDeviceUIState,
  SubComponentState,
} from "@types";

// Also at least one component is required
export const isFormInvalid = (newDevice: NewDeviceUIState) =>
  !newDevice.name ||
  !newDevice.description ||
  newDevice.components.some((c) => !c.name || !c.description) ||
  newDevice.components.some((c) =>
    c.subComponents.some((sc) => !sc.description)
  ) ||
  newDevice.components.length === 0;

export const newProductInitialState: NewDeviceUIState = {
  name: "",
  description: "",
  isIVDR: false,
  isFDA: false,
  isMDR: false,
  isEU: false,
  components: [],
};

export const newSoftwareComponentInitialState: Omit<ComponentState, "id"> = {
  type: ComponentType.SOFTWARE,
  name: "",
  description: "",
  configuration: [
    {
      type: ComponentConfigurationType.HAS_AI,
      value: "false",
    },
  ],
  subComponents: [],
  collapsed: false,
};

export const initialSoftwareSubComponentState: Omit<SubComponentState, "id"> = {
  type: SubComponentType.SOFTWARE,
  name: "",
  description: "",
  configuration: [
    {
      type: SubComponentConfigurationType.HAS_AI,
      value: "false",
    },
  ],
};

export const initialElectricalSubComponentState: Omit<SubComponentState, "id"> =
  {
    type: SubComponentType.ELECTRICAL,
    name: "",
    description: "",
    configuration: [
      {
        type: SubComponentConfigurationType.IS_IMPLANTABLE,
        value: "false",
      },
    ],
  };

export const newHardwareComponentInitialState: Omit<ComponentState, "id"> = {
  type: ComponentType.HARDWARE,
  name: "",
  description: "",
  configuration: [
    {
      type: ComponentConfigurationType.IS_IMPLANTABLE,
      value: "false",
    },
    {
      type: ComponentConfigurationType.IS_STERILIZATION_INTENDED,
      value: "false",
    },
    {
      type: ComponentConfigurationType.HAS_HUMAN_CONTACT,
      value: "false",
    },
  ],
  subComponents: [],
  collapsed: false,
};

export const getCleanDevice = (newDevice: NewDeviceUIState): NewDevice => ({
  name: newDevice.name,
  description: newDevice.description,
  isMDR: newDevice.isMDR,
  isFDA: newDevice.isFDA,
  isIVDR: newDevice.isIVDR,
  components: newDevice.components.map(
    ({ id, collapsed, subComponents, ...component }) => ({
      ...component,
      subComponents: subComponents.map(
        ({ id, ...subComponent }) => subComponent
      ),
    })
  ),
});
