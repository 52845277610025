import { usePatchDocumentVersionMutation } from "@hooks";
import {
  Device,
  Document,
  DocumentVersion,
  TEMPLATE_TYPE,
  User,
} from "@models";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  generateDocument,
  generateSignatureAndHistoryTables,
  getDocumentAnswerMap,
  isDocumentCompleted,
  isStaticallyGeneratedDocument,
} from "src/utils";

export const useGenerateDocument = ({
  document,
  user,
  selectedDocVersion,
  templateId,
  selectedDoc,
  documents,
  deviceId,
  device,
}: {
  document: Document | undefined | null;
  user: User | undefined;
  selectedDocVersion: DocumentVersion | undefined;
  templateId: TEMPLATE_TYPE;
  deviceId: string;
  selectedDoc: Document | undefined | null;
  documents: Document[] | undefined;
  device: Device | undefined;
}) => {
  const { orgId = "" } = useParams();
  // If the template id changes reset the selected document version otherwise the old selected document version will be used for the new template
  useEffect(() => {
    setGeneratedDoc(undefined);
  }, [templateId]);

  const patchDocumentMutation = usePatchDocumentVersionMutation();

  const [generatedDoc, setGeneratedDoc] = useState<
    { docVersionId: string; data: string } | undefined
  >(undefined);

  const [isGeneratingDoc, setIsGeneratingDoc] = useState<string | null>(null);
  const [signaturesAndHistory, setSignaturesAndHistory] = useState<
    string | null
  >(null);

  const docIsStatic = isStaticallyGeneratedDocument(templateId);

  const handleGenerateDocument = async (
    user: User,
    device: Device,
    documents: Document[],
    document: Document,
    selectedDocVersion: DocumentVersion
  ) => {
    setIsGeneratingDoc(selectedDocVersion.id);

    const generatedDoc = await generateDocument({
      orgId,
      user,
      device,
      document,
      documentVersion: selectedDocVersion,
      documents,
      type: templateId,
      data: getDocumentAnswerMap(selectedDocVersion),
    });

    setGeneratedDoc({
      docVersionId: selectedDocVersion.id,
      data: generatedDoc,
    });
    setIsGeneratingDoc(null);
  };

  /* Generate the document if the doc version is complete and the user, device, selectedDocVersion and document are available */
  useEffect(() => {
    if (
      user &&
      device &&
      selectedDoc &&
      selectedDocVersion &&
      documents &&
      document &&
      isDocumentCompleted({
        document,
        device,
        documents,
        documentVersion: selectedDocVersion,
      }) &&
      generatedDoc?.docVersionId !== selectedDocVersion.id &&
      isGeneratingDoc !== selectedDocVersion.id &&
      !selectedDocVersion.snapshot
    ) {
      handleGenerateDocument(
        user,
        device,
        documents,
        document,
        selectedDocVersion
      );
    }
  }, [
    user,
    device,
    selectedDocVersion,
    document,
    documents,
    generatedDoc,
    isGeneratingDoc,
  ]);

  /**
   * Generate the signatures and history tables
   */
  useEffect(() => {
    const fetchSignaturesAndHistory = async () => {
      if (!selectedDoc || !selectedDocVersion || !user || !documents || !device)
        return;

      const result = await generateSignatureAndHistoryTables(
        templateId,
        user,
        selectedDoc,
        selectedDocVersion,
        documents,
        orgId,
        device
      );
      setSignaturesAndHistory(result);
    };

    fetchSignaturesAndHistory();
  }, [
    templateId,
    user,
    selectedDoc,
    selectedDocVersion,
    documents,
    orgId,
    device,
  ]);

  /**
   * Set a static document's readyForApproval to true if its the first time the document is opened
   */
  useEffect(() => {
    if (
      document &&
      docIsStatic &&
      document.versions[0]?.readyForApproval !== true
    ) {
      patchDocumentMutation.mutate({
        orgId,
        deviceId: deviceId,
        docId: document.id,
        docVersionId: document.versions[0]?.id,
        data: { readyForApproval: true },
      });
    }
  }, [document, docIsStatic]);

  return {
    generatedDoc,
    isGeneratingDoc,
    setGeneratedDoc,
    signaturesAndHistory,
  };
};
