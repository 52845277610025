import { GroupAddOutlined, HistoryEduOutlined } from "@mui/icons-material";
import { Menu, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_SLUGS, ROUTES } from "src/config/navigation/routes";
import { fillRouteWithSlugs } from "src/utils/navigation";
import { config as TemplateAssistantConfig } from "../../../config/assistant";
import {
  ReducedDocument,
  TEMPLATE_TYPE,
  UserTodos,
} from "../../../stores/models";
import { TodoCard } from "./TodoCard";

type TodosProps = {
  todos: UserTodos["documentsTasks"];
  anchorTodos: HTMLElement | null;
  isOpen: boolean;
  closeTodo: () => void;
  hasOutstandingActions: boolean;
};

export const Todos = ({
  todos,
  anchorTodos,
  isOpen,
  closeTodo,
  hasOutstandingActions,
}: TodosProps) => {
  const [urlParams, setUrlParams] = useState<{
    deviceId: string;
    templateId: string;
  }>({
    deviceId: "",
    templateId: "",
  });
  const { templateId = "", deviceId = "", orgId = "" } = useParams();

  const navigate = useNavigate();
  const navigateToTodo = async ({ todo }: { todo: ReducedDocument }) => {
    navigate(
      fillRouteWithSlugs(ROUTES.QMS_OPEN_DOC, {
        [ROUTE_SLUGS.ORG_ID]: orgId,
        [ROUTE_SLUGS.DEVICE_ID]: todo.deviceId,
        [ROUTE_SLUGS.TEMPLATE_ID]: todo.name,
        [ROUTE_SLUGS.DOC_ID]: todo.id,
      })
    );

    setUrlParams({
      deviceId: todo.deviceId,
      templateId: todo.name,
    });
  };

  useEffect(() => {
    const hasUrlChanged =
      urlParams.deviceId !== deviceId || urlParams.templateId !== templateId;

    if (hasUrlChanged) {
      setUrlParams({
        deviceId,
        templateId,
      });
    }
  }, [templateId, deviceId]);

  const checkIfTodoIsActive = (todo: ReducedDocument) =>
    urlParams.templateId === todo.name && urlParams.deviceId === todo.deviceId;

  return (
    <Menu
      id="todos-menu"
      anchorEl={anchorTodos}
      open={isOpen}
      onClose={closeTodo}
      onClick={closeTodo}
      MenuListProps={{
        "aria-labelledby": "basic-button",
        className: "px-[4px] py-[6px]",
      }}
      disablePortal
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      slotProps={{
        paper: {
          sx: {
            maxHeight: 280,
          },
        },
      }}
    >
      {!hasOutstandingActions && (
        <Typography variant="body1" className="text-wrap py-2 px-4">
          Lean back and relax. Nothing to do here 😎
        </Typography>
      )}
      {todos.toApprove.map((todo: ReducedDocument) => (
        <TodoCard
          closeTodo={closeTodo}
          key={todo.id}
          text={`Approve the ${TemplateAssistantConfig[todo.name as TEMPLATE_TYPE].docName} document.`}
          icon={<HistoryEduOutlined />}
          navigateToTodo={() => navigateToTodo({ todo })}
          isActive={checkIfTodoIsActive(todo)}
        />
      ))}
      {todos.toAssignApprovers.map((todo: ReducedDocument) => (
        <TodoCard
          closeTodo={closeTodo}
          key={todo.id}
          text={`Assign approvers to the ${TemplateAssistantConfig[todo.name as TEMPLATE_TYPE].docName} document.`}
          icon={<GroupAddOutlined />}
          navigateToTodo={() => navigateToTodo({ todo })}
          isActive={checkIfTodoIsActive(todo)}
        />
      ))}
    </Menu>
  );
};
