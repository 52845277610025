import { config } from "@config";
import { Device, Document, TEMPLATE_TYPE } from "@models";
import { config as templateAssistantConfig } from "src/config/assistant";
import { optionsToChangeType } from "src/pages/DocumentUpdate";
import { DocumentDataKey } from "src/types/data-keys";
import { getFilteredElements, isDocumentHidden } from "src/utils/documents";

type UpdatePlan = {
  dataKeyId: DocumentDataKey;
  templateId: TEMPLATE_TYPE;
};

export const changeTypeToTemplates = {
  bugFix: [TEMPLATE_TYPE.BUG_FIX_REPORT],
  patch: [
    TEMPLATE_TYPE.CHANGE_REQUEST,
    TEMPLATE_TYPE.SUBSYSTEM_REQUIREMENTS_LIST,
    TEMPLATE_TYPE.SOFTWARE_ARCHITECTURE_CHECKLIST,
    TEMPLATE_TYPE.SOUP_LIST,
    TEMPLATE_TYPE.SOFTWARE_TEST_PLAN,
    TEMPLATE_TYPE.SOFTWARE_SYSTEM_TEST_REPORT,
  ],
  minor: [
    TEMPLATE_TYPE.CHANGE_REQUEST,
    TEMPLATE_TYPE.SUBSYSTEM_REQUIREMENTS_LIST,
    TEMPLATE_TYPE.SOFTWARE_ARCHITECTURE_CHECKLIST,
    TEMPLATE_TYPE.SOUP_LIST,
    TEMPLATE_TYPE.SOFTWARE_TEST_PLAN,
    TEMPLATE_TYPE.SOFTWARE_SYSTEM_TEST_REPORT,
    TEMPLATE_TYPE.RISK_ASSESSMENT,
    TEMPLATE_TYPE.USABILITY_EVALUATION_PROTOCOL,
    TEMPLATE_TYPE.USABILITY_EVALUATION_REPORT,
  ],
  major: [],
};

export const commonTemplates = [
  TEMPLATE_TYPE.LIST_OF_KNOWN_ANOMALIES,
  TEMPLATE_TYPE.UDI_CREATION,
  TEMPLATE_TYPE.DEVICE_LABEL,
  TEMPLATE_TYPE.LIST_OF_MEDICAL_DEVICES,
  TEMPLATE_TYPE.RELEASE_NOTES,
  TEMPLATE_TYPE.PRODUCT_RELEASE_CHECKLIST,
];

export const getElementIdsFromTemplate = (
  template: TEMPLATE_TYPE
): DocumentDataKey[] => {
  return config[template].elements.map((element) => element.id);
};

export const getAllElementIdsFromTemplates = (
  templateIds: TEMPLATE_TYPE[]
): string[] => {
  return templateIds.reduce((acc: string[], templateId: TEMPLATE_TYPE) => {
    const template = config[templateId];
    if (template) {
      const elementIds = getElementIdsFromTemplate(templateId);
      acc.push(...elementIds);
    }
    return acc;
  }, []);
};

export const getHighestChangeType = (
  selected: string[]
): keyof typeof changeTypeToTemplates => {
  const changeTypes = selected.map((option) => optionsToChangeType[option]);

  if (changeTypes.includes("major")) return "major";
  if (changeTypes.includes("minor")) return "minor";
  if (changeTypes.includes("patch")) return "patch";
  return "bugFix";
};

const getTemplatesFromSelection = (selected: string[]) => {
  // Get highest change type from selection
  const highestChangeType = getHighestChangeType(selected);

  // Only get templates for the highest change type
  const templates = changeTypeToTemplates[highestChangeType] || [];

  // Return highest change type templates plus common templates
  return [...new Set([...templates, ...commonTemplates])];
};

export const getDataKeysToUpdate = (
  selectValue: string[],
  documents: Document[],
  device: Device
) => {
  const templateIds = getTemplatesFromSelection(selectValue);

  const filteredTemplateIds = templateIds.filter((templateId) => {
    const templateConfig = templateAssistantConfig[templateId];

    const document = documents.find((document) => document.name === templateId);

    // Check if the document is visible based on the task visibility condition
    const documentIsHidden = isDocumentHidden(templateId, documents, device);

    if (documentIsHidden) {
      return false;
    }

    // We always include documents in the update plan that are in the templateIds and exist
    if (document) {
      return true;
    }

    // We always include RCDs in the update plan
    if (templateConfig.docType === "RCD") {
      return true;
    }

    return false;
  });

  const dataKeysToUpdate = filteredTemplateIds.reduce<UpdatePlan[]>(
    (acc, templateId) => {
      const template = templateAssistantConfig[templateId];

      const visibleElements = getFilteredElements(
        template.elements,
        device,
        documents
      );

      visibleElements.forEach((element) => {
        acc.push({
          dataKeyId: element.id,
          templateId: template.id,
        });
      });

      return acc;
    },
    []
  );

  return dataKeysToUpdate;
};
