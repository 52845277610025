import {
  useDeleteDocumentVersionMutation,
  useGeneratePdfMutation,
  useGetUser,
} from "@hooks";
import { Document, DocumentVersion } from "@models";
import { MoreVert } from "@mui/icons-material";
import {
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import GenericButton from "src/components/GenericButton/GenericButton";
import {
  captureSentryMessage,
  downloadFile,
  getDocTemplate,
  hasRevisionOneOreMoreApprovals,
  isDocVersionAuthor,
  isDocVersionDraft,
  UPGRADE_PLAN_TOOLTIP,
} from "../../../../../utils";

export const MoreOptions = ({
  onEditRawDocument,
  selectedDoc,
  selectedVersion,
  isDocEnabled,
  generatedDoc,
}: {
  onEditRawDocument: () => void;
  selectedDoc: Document;
  selectedVersion: DocumentVersion;
  isDocEnabled: boolean;
  generatedDoc: string;
}) => {
  const { data: user } = useGetUser();
  const { orgId = "", deviceId = "" } = useParams();
  const generatePdfMutation = useGeneratePdfMutation();
  const deleteDocumentVersionMutation = useDeleteDocumentVersionMutation();

  if (!user) {
    captureSentryMessage("User not found to display DocViewerTopBar", {
      selectedDoc,
      selectedVersion,
    });
    return null;
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const moreOptionsOpen = Boolean(anchorEl);
  const handleClickMoreOptions = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMoreOptions = () => {
    // Don't close menu while loading
    if (!generatePdfMutation.isPending) {
      setAnchorEl(null);
    }
  };

  const handleDownloadPdf = async () => {
    try {
      const docName = getDocTemplate(selectedDoc.name)?.docName || "document";
      const pdfBlob = await generatePdfMutation.mutateAsync({
        orgId,
        deviceId,
        documentId: selectedDoc.id,
        versionId: selectedVersion.id,
        content: generatedDoc,
        docName: docName,
      });

      downloadFile(pdfBlob, `${docName}.pdf`);
      handleCloseMoreOptions();
    } catch (error) {
      captureSentryMessage("Error generating PDF", {
        error,
        selectedDoc,
        selectedVersion,
        generatedDoc,
      });
    }
  };

  return (
    <div>
      <GenericButton
        dataTestId="more-options-button"
        isDisabled={!isDocEnabled}
        ariacontrols={moreOptionsOpen ? "basic-menu" : undefined}
        ariahaspopup={true}
        ariaexpanded={moreOptionsOpen ? true : undefined}
        id="basic-button"
        onClickEvent={handleClickMoreOptions}
        tooltipContent={UPGRADE_PLAN_TOOLTIP}
        hideTooltip={isDocEnabled}
      >
        <MoreVert />
      </GenericButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={moreOptionsOpen}
        onClose={handleCloseMoreOptions}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        disablePortal
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={handleDownloadPdf}
          disabled={!isDocEnabled || generatePdfMutation.isPending}
        >
          <div className="flex items-center gap-4">
            Export as PDF
            {generatePdfMutation.isPending && (
              <CircularProgress size={16} color="inherit" />
            )}
          </div>
        </MenuItem>
        <Divider className="my-2" />
        <Typography color="error" className="px-4">
          Danger Zone
        </Typography>
        <Divider className="my-2" />
        <MenuItem
          disabled={
            !isDocVersionDraft(selectedVersion, selectedDoc.versions) ||
            hasRevisionOneOreMoreApprovals(selectedVersion) ||
            !isDocVersionAuthor(selectedVersion, user)
          }
          onClick={() => {
            if (!generatePdfMutation.isPending) {
              handleCloseMoreOptions();
              onEditRawDocument();
            }
          }}
        >
          Edit raw document
        </MenuItem>
        {isDocVersionDraft(selectedVersion, selectedDoc.versions) && (
          <MenuItem
            disabled={deleteDocumentVersionMutation.isPending}
            onClick={() => {
              handleCloseMoreOptions();
              deleteDocumentVersionMutation.mutate({
                orgId,
                deviceId,
                docId: selectedDoc.id,
                versionId: selectedVersion.id,
              });
            }}
          >
            Delete draft
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default MoreOptions;
